import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { addPrefix } from 'pages/WorkPlan/WorkPlanDelete';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BoxQRScan from 'elements/BoxQRScan';
import AuthContext from 'store/auth-context';
import { Badge } from 'primereact/badge';
import { getBoxIdsFromBatchIds } from 'utils/boxUtils';

export async function fetchPackagesList(
  batchesTemp,
  setPackageList,
  setAction,
  toast,
  status = null
) {
  try {
    const packagesListTemp = await axiosConfig
      .get('/packaging/packages', { params: { status } })
      .then((res) => res.data);
    setPackageList(
      packagesListTemp.map((packageTemp) => ({
        ...packageTemp,
        bestBefore: packageTemp.bestBefore
          ? new Date(packageTemp.bestBefore)
          : null,
        deliveryDate: packageTemp.deliveryDate
          ? new Date(packageTemp.deliveryDate)
          : null,
      }))
    );
    if (Array.isArray(batchesTemp) && batchesTemp.length) {
      const boxes = await getBoxIdsFromBatchIds(batchesTemp, toast);
      boxes.map((box) =>
        setAction({
          actionValue: 'edit',
          actionData: { box },
        })
      );
    }
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Error while fetching package List',
    });
  }
}

function PackagingEdit({
  data,
  display,
  onClose,
  onCompletion,
  readOnly,
  getBoxesFromBatch,
}) {
  const initItem = useMemo(
    () => ({
      packageId: '',
      packageNumber: '',
      workStepIds: [],
      deliveryDate: new Date(),
      bestBefore: new Date(),
      amount: 0,
      status: 'active',
      invoiceNumber: '',
      expectedAmount: 0,
      quality: null,
      comment: '',
      customerId: null,
    }),
    []
  );
  const toast = useContext(ToastContext);
  const [customerList, setcustomerList] = useState([]);
  const [packages, setPackages] = useState([]);
  const [boxes, setBoxes] = useState(data ? data.boxes || [] : []);
  const [packagedCharges, setPackagedCharges] = useState(
    data && data.boxes ? data.boxes.map(({ chargeId }) => chargeId) || [] : []
  );
  const [item, setItem] = useState(data || initItem);
  const [action, setAction] = useState(false);
  const [boxesFromBatch, setBoxesFromBatch] = useState([]);
  const { user } = useContext(AuthContext);

  const calculateExpectedWeight = useCallback(
    (boxesTemp, itemTemp) =>
      setItem({
        ...itemTemp,
        expectedAmount: boxesTemp.length
          ? boxesTemp.reduce(
              (accumulator, currentItem) =>
                accumulator +
                (typeof currentItem.amount === 'number'
                  ? currentItem.amount
                  : 0),
              0
            )
          : 0,
      }),
    []
  );

  const deleteItemTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-sm p-button-outlined "
        style={{
          color:
            (rowData.amount === 'No Sieving!' ||
              rowData.batchId === 'BA-00000') &&
            'red',
        }}
        onClick={() => {
          setBoxes(
            boxes.filter(({ chargeId }) => chargeId !== rowData.chargeId)
          );
          calculateExpectedWeight(
            boxes.filter(({ chargeId }) => chargeId !== rowData.chargeId),
            item
          );
        }}
        disabled={readOnly || packagedCharges.includes(rowData.chargeId)}
      />
    );
  };

  const columnsBoxes = [
    { header: 'Box ID', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Amount', field: 'amount' },
    { body: deleteItemTemplate },
  ];

  // get weights if charge has been sieved else return
  const fetchWeights = useCallback(
    async (boxAndChargeIds, cb) => {
      const BATCHSIZE = 10; // Maximum number of elements per request
      try {
        // check for missing weights
        if (
          Array.isArray(boxAndChargeIds) &&
          boxAndChargeIds.every((row) => row.chargeId) &&
          boxAndChargeIds.some((row) => !row.amount)
        ) {
          const promises = [];
          while (boxAndChargeIds.length > 0) {
            const batch = boxAndChargeIds.splice(0, BATCHSIZE);
            promises.push(
              axiosConfig.get('/packaging/weights', {
                params: {
                  boxAndChargeIds: batch.map(
                    ({ boxId, chargeId, batchId }) => ({
                      boxId,
                      chargeId,
                      batchId,
                    })
                  ),
                },
              })
            );
          }
          Promise.all(promises).then((promisesTemp) => {
            cb(promisesTemp.map((promise) => promise.data).flat());
            calculateExpectedWeight(
              promisesTemp.map((promise) => promise.data).flat(),
              item
            );
          });
        } else if (Array.isArray(boxAndChargeIds))
          calculateExpectedWeight(boxAndChargeIds, item);
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching latest package id',
        });
      }
    },
    [calculateExpectedWeight, item, toast]
  );

  const fetchBoxesForBatchId = useCallback(
    async (batchId) => {
      try {
        return axiosConfig
          .get('/boxLogistics/boxesForBatches', {
            params: { batchIds: [batchId] },
          })
          .then((res) =>
            fetchWeights(res.data, (boxesTemp) => setBoxesFromBatch(boxesTemp))
          );
      } catch (error) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching package boxes',
        });
        return error;
      }
    },
    [fetchWeights, toast]
  );

  const generatePackageId = useCallback(
    async (itemTemp) => {
      try {
        return axiosConfig.get('/packaging/latestId').then((res) =>
          setItem({
            ...itemTemp,
            packageId: addPrefix((res.data || 0) + 1, 'PA', 5),
          })
        );
      } catch (error) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching latest package id',
        });
        return error;
      }
    },
    [toast]
  );

  const fetchcustomerList = useCallback(async () => {
    try {
      return axiosConfig
        .get('/customer/customers')
        .then((res) => setcustomerList(res.data));
    } catch (error) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching customer list',
      });
      return error;
    }
  }, [setcustomerList, toast]);

  const resetForm = () => {
    setItem(initItem);
    fetchcustomerList();
    setPackagedCharges([]);
  };

  useEffect(() => {
    Array.isArray(boxes) &&
      boxes.some(({ amount }) => !amount) &&
      fetchWeights(boxes, (boxesTemp) => setBoxes(boxesTemp));
    fetchcustomerList();
    getBoxesFromBatch && fetchBoxesForBatchId(data.batchId);
    calculateExpectedWeight(boxes, item);
    fetchPackagesList(
      [],
      (packagesTemp) => setPackages(packagesTemp),
      null,
      toast,
      'prepared'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!(data && data.packageId) && !(item && item.packageId))
      generatePackageId(item);
    else if (data && data.packageId && !(item && item.packageId))
      item.packageId = data.packageId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.packageId]);

  const handleFocus = (event) => event.target.select();

  const sendPackage = async (itemTemp) => {
    try {
      await axiosConfig.post('/packaging/create', {
        ...itemTemp,
        email: user.email,
        boxes: [...boxes, ...boxesFromBatch],
      });

      resetForm();
      onCompletion();
      onClose();

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful package creation',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.message,
      });
    }
  };

  const itemTemplate = (option) => (
    <div className="option-item">
      <span>{option?.packageId} </span>
      <Badge
        value={`${option?.boxes?.length} Boxes`}
        className="p-mr-2"
        severity="success"
      />
    </div>
  );

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      {action === 'scanBox' && (
        <BoxQRScan
          onResult={(scanResult) =>
            fetchWeights(scanResult, (boxesTemp) =>
              setBoxes([
                ...(data?.boxes?.length ? data.boxes : []),
                ...boxesTemp,
              ])
            )
          }
          itemListInit={
            (boxes?.length &&
              boxes.filter(({ chargeId }) =>
                data?.boxes
                  ? !data.boxes.some((box) => chargeId === box.chargeId)
                  : true
              )) ||
            []
          }
          onClose={() => {
            setAction('');
          }}
          display={action === 'scanBox'}
          boxesShouldBeActive
          boxesShouldExist
          scanModeList={['series', 'stack', 'batch']}
        />
      )}
      {getBoxesFromBatch && boxesFromBatch.length ? (
        <Fieldset
          legend="Add boxes to existing package"
          style={{ marginBottom: '1rem' }}
        >
          Add {boxesFromBatch.length} box
          {boxesFromBatch.length === 1 ? null : 'es'} to an existing package:{' '}
          <Dropdown
            id="existingPackage"
            options={packages}
            onChange={(e) => {
              const itemTemp = {
                ...(e?.value || {}),
                workStepIds: [
                  ...(e.value?.workStepIds || []),
                  ...(item?.workStepIds || []),
                ],
              };
              setBoxes([
                ...(e.value.boxes?.length ? e.value.boxes : []),
                ...(boxesFromBatch.length ? boxesFromBatch : []),
              ]);
              setPackagedCharges(
                e.value.boxes?.length
                  ? e.value.boxes.map(({ chargeId }) => chargeId)
                  : []
              );
              calculateExpectedWeight(
                [
                  ...(e.value.boxes?.length ? e.value.boxes : []),
                  ...(boxesFromBatch.length ? boxesFromBatch : []),
                ],
                e.value
              );
              setItem(itemTemp);
            }}
            placeholder={
              packages?.find(({ packageId }) => packageId === item?.packageId)
                ? item?.packageId
                : 'Create new Package'
            }
            itemTemplate={itemTemplate}
            disabled={readOnly}
          />
          <Button
            className="p-button-outlined"
            icon="pi pi-times"
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              setBoxes([]);
              calculateExpectedWeight(boxesFromBatch, initItem);
              generatePackageId(initItem);
              setPackagedCharges([]);
            }}
          />
        </Fieldset>
      ) : null}
      <Fieldset
        legend={data ? 'Edit Package' : 'New Package'}
        style={{ marginBottom: '1rem' }}
      >
        <div className="p-fluid formgrid grid">
          <div className="field col-4">
            <label htmlFor="packageId">Package ID</label>
            <InputMask
              id="packageId"
              mask="PA-99999"
              value={item?.packageId}
              type="text"
              placeholder="PA-"
              onChange={(e) => setItem({ ...item, packageId: e.target.value })}
              disabled
            />
          </div>
          <div className="field col-4">
            <label htmlFor="packageNumber">Package Number</label>
            <InputText
              id="packageNumber"
              value={item.packageNumber}
              placeholder="Customer Package Number"
              onChange={(e) =>
                setItem({ ...item, packageNumber: e.target.value })
              }
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="customerName">Customer *</label>
            <Dropdown
              id="supplierName"
              value={
                Array.isArray(customerList) &&
                customerList.find(
                  ({ customerId }) => customerId === item.customerId
                )
              }
              options={customerList}
              onChange={(e) => {
                setItem({ ...item, customerId: e.value.customerId });
              }}
              optionLabel="customerName"
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="customerName">Customer ID *</label>
            <Dropdown
              id="supplierName"
              value={
                Array.isArray(customerList) &&
                customerList.find(
                  ({ customerId }) => customerId === item.customerId
                )
              }
              options={customerList}
              onChange={(e) => {
                setItem({ ...item, customerId: e.value.customerId });
              }}
              optionLabel="customerId"
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="deliveryDate">Delivery Date</label>
            <Calendar
              dateFormat="dd.mm.yy"
              value={item.deliveryDate}
              id="deliveryDate"
              onChange={(e) =>
                setItem({ ...item, deliveryDate: e.target.value })
              }
              onFocus={handleFocus}
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="bestBefore">Best Before</label>
            <Calendar
              dateFormat="dd.mm.yy"
              value={item.bestBefore}
              minDate={new Date()}
              id="bestBefore"
              onChange={(e) =>
                setItem({
                  ...item,
                  bestBefore:
                    e.target.value > new Date() ? e.target.value : new Date(),
                })
              }
              onFocus={handleFocus}
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="amount">Actual Amount</label>
            <div className="p-inputgroup">
              <InputNumber
                value={item.amount}
                id="amount"
                onChange={(e) =>
                  setItem({ ...item, amount: Math.abs(e.value) })
                }
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                maxFractionDigits={3}
                onFocus={handleFocus}
                min={0}
                disabled={readOnly}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>
          <div className="field col-4">
            <label htmlFor="quality">Quality</label>
            <InputNumber
              value={item.quality || null}
              id="quality"
              onChange={(e) =>
                setItem({
                  ...item,
                  quality: e.value,
                })
              }
              onFocus={handleFocus}
              min={1}
              max={10}
              placeholder="1-10"
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="invoiceNumber">Invoice Number</label>
            <InputMask
              id="invoiceNumber"
              mask="IN-99999"
              value={item.invoiceNumber}
              type="text"
              placeholder="IN-"
              onChange={(e) => setItem({ ...item, invoiceNumber: e.value })}
              disabled={readOnly}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="expectedAmount">Expected Amount</label>
            <div className="p-inputgroup">
              <InputNumber
                value={item.expectedAmount}
                id="expectedAmount"
                mode="decimal"
                locale="de-DE"
                minFractionDigits={2}
                onFocus={handleFocus}
                disabled
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>
          <div className="field col-8">
            <label htmlFor="comment">Notes</label>
            <InputText
              value={item.comment}
              id="comment"
              type="text"
              onChange={(e) =>
                setItem({ ...item, comment: e.target.value.substring(0, 50) })
              }
              onFocus={handleFocus}
              disabled={readOnly}
            />
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Boxes" style={{ marginBottom: '1rem' }}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12">
            <DataTable value={boxes.length ? boxes : boxesFromBatch}>
              {columnsBoxes.map(({ header, field, body }) => (
                <Column header={header} field={field} body={body} />
              ))}
            </DataTable>
          </div>
          <Button
            className="p-button-rounded p-button-outlined "
            label="Scan Boxes"
            icon="pi pi-qrcode"
            onClick={() => setAction('scanBox')}
            disabled={readOnly}
          />
        </div>
      </Fieldset>
      <div className="p-fluid formgrid grid">
        {readOnly ? (
          <div className="field col-12">
            <Button
              className="p-button-secondary p-button-rounded mr-2 mb-2"
              label="Close"
              onClick={() => onClose()}
            />
          </div>
        ) : (
          <>
            <div className="field col-4">
              <Button
                className="p-button-secondary p-button-rounded mr-2 mb-2"
                label="Cancel"
                onClick={() => onClose()}
              />
            </div>
            <div className="field col-4">
              <Button
                className="p-button-success p-button-rounded"
                label="Save Package"
                onClick={() =>
                  sendPackage({
                    ...item,
                    status: 'prepared',
                  })
                }
                disabled={
                  readOnly ||
                  !item ||
                  !item?.packageId ||
                  (!boxes.length && !boxesFromBatch.length) ||
                  [...boxes, ...boxesFromBatch].some(
                    ({ amount, batchId }) =>
                      amount === 'No Sieving!' ||
                      !amount ||
                      batchId === 'BA-00000'
                  ) ||
                  !item.customerId
                }
              />
            </div>
            <div className="field col-4">
              <Button
                className="p-button-success p-button-rounded"
                label="Deliver Package"
                onClick={() =>
                  sendPackage({
                    ...item,
                    status: 'delivered',
                  })
                }
                disabled={
                  readOnly ||
                  !item ||
                  !item.packageId ||
                  !item.bestBefore ||
                  !item.deliveryDate ||
                  !item.amount ||
                  !Array.isArray(boxes) ||
                  (!boxes.length && !boxesFromBatch.length) ||
                  [...boxes, ...boxesFromBatch].some(
                    ({ amount, batchId }) =>
                      amount === 'No Sieving!' ||
                      !amount ||
                      batchId === 'BA-00000'
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

PackagingEdit.propTypes = {
  data: PropTypes.shape({
    packageId: PropTypes.string.isRequired,
    workStepIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    deliveryDate: PropTypes.instanceOf(Date),
    bestBefore: PropTypes.instanceOf(Date),
    amount: PropTypes.number,
    status: PropTypes.string,
    invoiceNumber: PropTypes.string,
    quality: PropTypes.number,
    notes: PropTypes.string,
    boxes: PropTypes.arrayOf(
      PropTypes.shape({
        chargeId: PropTypes.string.isRequired,
        boxId: PropTypes.string.isRequired,
        batchid: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      })
    ).isRequired,
    processId: PropTypes.number,
    batchId: PropTypes.string,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  getBoxesFromBatch: PropTypes.bool.isRequired,
};

export default PackagingEdit;
