import { useContext, useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
function WorkPlanAssign({ data, display, onClose, onCompletion }) {
  // WARNING!
  // This currently unused! The intention previously was to assign a batch
  // to a Workplan at any time. This functionality is not available in the
  // frontend at the moment. When reactivating this, it needs to be updated
  // completely and maybe adapted to assign batches rather than charges.

  const [workPlan] = useState(data);
  const [charges, setCharges] = useState(null);
  const [totalRecords, setTotalRecord] = useState(0);
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ChargeID: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    Days: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    StackID: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    BatchID: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    WorkPlanID: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });
  const [selectAll, setSelectAll] = useState(null);
  const [initialSelect, setInitialSelect] = useState(null);
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(false);
  const toast = useContext(ToastContext);

  const columns = [
    { header: 'Charge ID', field: 'ChargeID' },
    { header: 'Day', field: 'Days' },
    { header: 'Stack ID', field: 'StackID' },
    { header: 'Batch ID', field: 'BatchID' },
    { header: 'Work Plan ID', field: 'WorkPlanID' },
  ];

  useEffect(() => {
    getCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerText = () => {
    const text = `Assign Charges to ${workPlan.WorkPlanName}`;
    return <h4>{text}</h4>;
  };

  const filtersMap = {
    filters1: { value: filters1, callback: setFilters1 },
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    const { value } = event.target;
    const filters = { ...filtersMap[filtersKey].value };
    filters.global.value = value;
    filtersMap[filtersKey].callback(filters);
  };

  const renderHeader = () => {
    const filters = filtersMap[`${'filters1'}`].value;
    const value = filters.global ? filters.global.value : '';

    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={value || ''}
          onChange={(e) => onGlobalFilterChange(e, 'filters1')}
          placeholder="Global Search"
        />
      </span>
    );
  };

  const getCharges = async () => {
    try {
      const chargesRaw = await axiosConfig
        .get('/workPlan/charges')
        .then((res) => res.data);
      const chargesTemp = chargesRaw.map((charge, prop) => {
        let WorkPlanID = null;
        if (charge.WorkPlans.length !== 0)
          WorkPlanID = charge.WorkPlans[0].WorkPlanID;
        const dateNow = new Date().getTime();
        const dateStabling = new Date(
          charge.ChargeHistory[0].createdAt
        ).getTime();
        const Days =
          1 + Math.round((dateNow - dateStabling) / (24 * 3600 * 1000));
        return {
          key: prop,
          ChargeID: charge.ChargeID,
          Days,
          StackID: charge.ChargeHistory[0].Box.BoxHistory[0].StackID,
          BatchID:
            charge.ChargeHistory[0].Box.BoxHistory[0].Stack.StackHistory[0]
              .BatchID,
          WorkPlanID,
        };
      });
      if (chargesTemp !== null) {
        const initialList = chargesTemp.filter(
          (o) => o.WorkPlanID === workPlan.WorkPlanID
        );
        setSelected(initialList);
        setInitialSelect(initialList);
      }
      setCharges(chargesTemp);
      if (chargesTemp !== null) setTotalRecord(chargesTemp.length);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching charges',
      });
    }
  };

  const onSelectionChange = (event) => {
    const { value } = event;
    setSelected(value);
    setSelectAll(value.length === totalRecords);
    if (value.length === 0) setSelected(null);
  };

  const onSelectAllChange = (event) => {
    setSelectAll(event.checked);
    if (!selectAll) setSelected(charges);
    else setSelected(null);
  };

  const assignCharges = async (ListOfCharges, ListOfChargeID) => {
    try {
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Assigned Charges',
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
    onCompletion();
    onClose();
  };

  const confirmationDialog = () => {
    const listOfCharges = [];
    const listOfChargeID = [];
    const keepChargeID = [];
    if (selected !== null) {
      // eslint-disable-next-line array-callback-return
      selected.map((o) => {
        listOfCharges.push({
          WorkPlanID: workPlan.WorkPlanID,
          ChargeID: o.ChargeID,
        });
        if (o.WorkPlanID === workPlan.WorkPlanID) keepChargeID.push(o.ChargeID);
        if (o.WorkPlanID !== null && o.WorkPlanID !== workPlan.WorkPlanID)
          listOfChargeID.push(o.ChargeID);
      });
    }
    if (charges !== null) {
      // eslint-disable-next-line array-callback-return
      charges.map((o) => {
        if (
          o.WorkPlanID === workPlan.WorkPlanID &&
          !listOfChargeID.includes(o.ChargeID) &&
          !keepChargeID.includes(o.ChargeID)
        ) {
          listOfChargeID.push(o.ChargeID);
        }
      });
    }
    const numberOfNewChargesTemp =
      listOfCharges.length + listOfChargeID.length === initialSelect.length
        ? 0
        : listOfCharges.length + listOfChargeID.length - initialSelect.length;
    const numberOfNewCharges =
      initialSelect.length === 0
        ? listOfCharges.length
        : numberOfNewChargesTemp;
    return (
      <Dialog
        className="dialog-card"
        showHeader={false}
        style={{ width: '50vw' }}
        modal
        visible={display}
        onHide={onClose}
        emptyMessage="Data Loading"
      >
        <div className="p-fluid formgrid grid">
          <div className=" col-12 flex justify-content-between mb-2">
            <h4>Confirm Assignment</h4>
          </div>
        </div>
        <div className="field col-12">
          <span>
            Are you sure you want to
            {numberOfNewCharges === 0
              ? ''
              : ` assign ${numberOfNewCharges} charges`}
            {numberOfNewCharges === 0 || listOfChargeID.length === 0
              ? ''
              : ` and `}{' '}
            {listOfChargeID.length === 0
              ? ''
              : `unassign ${listOfChargeID.length} charges `}
            to the: work plan:
            {workPlan.WorkPlanName} (work plan ID: {workPlan.WorkPlanID})?
          </span>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col-6">
            <Button
              onClick={() => {
                onClose();
              }}
              label="Cancel"
              className="p-button-rounded p-button-secondary"
            />
          </div>
          <div className="field col-6">
            <Button
              onClick={() => {
                assignCharges(listOfCharges, listOfChargeID);
              }}
              label={`Confirm ${
                numberOfNewCharges === 0 ? 'unassignment' : ` assignment`
              }`}
              className="p-button-rounded p-button-success"
            />
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-8">{headerText()}</div>
        <div className="field col-4">{renderHeader()}</div>
      </div>
      <div className="field col-12">
        <DataTable
          value={charges}
          filters={filters1}
          onFilter={(e) => setFilters1(e.filters)}
          paginator
          rows={10}
          responsiveLayout="scroll"
          size="small"
          // scrollHeight="70vh"
          sortOrder={-1}
          selection={selected}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
        >
          {columns.map(({ field, header }) => (
            <Column field={field} header={header} sortable filter />
          ))}
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        </DataTable>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field col-6">
          <Button
            className="p-button-rounded p-button-secondary"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-rounded p-button-success"
            label="Assign selection"
            onClick={() => setActive(true)}
            disabled={selected === initialSelect}
          />
          {active === true && confirmationDialog()}
        </div>
      </div>
    </Dialog>
  );
}

export default WorkPlanAssign;
