import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function AreaEdit({ data, display, onClose, onCompletion }) {
  const initItem = {
    positionAreaRow: 0,
    positionAreaColumn: 0,
    areaWidth: 0,
    areaLength: 0,
    areaLayoutRows: 0,
    areaLayoutColumns: 0,
  };

  const [inComplete, setInComplete] = useState(true);

  const [item, setItem] = useState(data?.areaId ? data : initItem);

  const toast = useContext(ToastContext);

  useEffect(() => {
    setInComplete(false);
    if (
      !(item.positionAreaRow || item.positionAreaRow === 0) ||
      !(item.positionAreaColumn || item.positionAreaColumn === 0) ||
      !item.areaWidth ||
      !item.areaLength ||
      !item.areaLayoutRows ||
      !item.areaLayoutColumns
    )
      setInComplete(true);
  }, [item]);

  const submitArea = async (area, _new = true) => {
    try {
      if (_new) {
        await axiosConfig.post('/farmsetup/createArea', area);
      } else {
        await axiosConfig.post('/farmsetup/updateArea', area);
      }
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${data?.areaId ? 'editing' : 'creating'} of area!`,
      });
      onCompletion();
      onClose();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data?.areaId ? 'Edit' : 'Create'} Area
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="areaId">Area Id</label>
          <InputText
            value={item.areaId || 'To be created'}
            onChange={(e) => setItem({ ...item, areaId: e.target.value })}
            id="areaId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-12">
          <hr />
        </div>
        <div className="field col-6">
          <label htmlFor="positionAreaRow">Position of area: Row*</label>
          <InputNumber
            value={item.positionAreaRow}
            onChange={(e) => setItem({ ...item, positionAreaRow: e.value })}
            id="positionAreaRow"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="positionAreaColumn">Position of area: Column*</label>
          <InputNumber
            value={item.positionAreaColumn}
            onChange={(e) => setItem({ ...item, positionAreaColumn: e.value })}
            id="positionAreaColumn"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="areaWidth">Area Width*</label>
          <InputNumber
            value={item.areaWidth}
            onChange={(e) => setItem({ ...item, areaWidth: e.value })}
            id="areaWidth"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="areaLength">Area Length*</label>
          <InputNumber
            value={item.areaLength}
            onChange={(e) => setItem({ ...item, areaLength: e.value })}
            id="areaLength"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="areaLayoutRows">Area Layout Rows*</label>
          <InputNumber
            value={item.areaLayoutRows}
            onChange={(e) => setItem({ ...item, areaLayoutRows: e.value })}
            id="areaLayoutRows"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="areaLayoutColumns">Area Layout Columns*</label>
          <InputNumber
            value={item.areaLayoutColumns}
            onChange={(e) => setItem({ ...item, areaLayoutColumns: e.value })}
            id="areaLayoutColumns"
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data?.areaId ? 'Save' : 'Create area'}`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitArea(item, !data?.areaId)}
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

AreaEdit.propTypes = {
  data: PropTypes.objectOf({
    areaId: PropTypes.string,
    areaName: PropTypes.string,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
};

export default AreaEdit;
