const { generateBarcodePNG } = require('elements/QRGeneration');

export default function createDeliveryBill(
  packageId,
  packageNumber,
  bestBefore,
  dateDelivery,
  customerName,
  breed,
  actualWeight,
  address,
  invoiceNumber
) {
  let barcodeDataUrl = '';
  // eslint-disable-next-line no-return-assign
  generateBarcodePNG(packageId, (QRTemp) => (barcodeDataUrl = QRTemp));

  const invoiceHTML = `
  <!DOCTYPE html>
  <html>
    <head>
      <title>Delivery Bill</title>
      <style>
        body {
          font-family: Arial, sans-serif;
        }
        .container {
          border: 1px solid #000;
          padding: 20px;
          width: 50%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        h1 {
          text-align: center;
        }
        .label {
          font-weight: bold;
        }
        #barcode {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .barcode-image {
          display: flex;
          justify-content: center;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <h1>Delivery Bill</h1>
        <div>
          <span class="label">Package Number:</span> <span id="packageNumber">${packageNumber}</span>
        </div>
        <div>
          <span class="label">Best Before:</span> <span id="bestBefore">${bestBefore}</span>
        </div>
        <div>
          <span class="label">Date of Delivery:</span> <span id="dateDelivery">${dateDelivery}</span>
        </div>
        <div>
          <span class="label">Name:</span> <span id="name">${customerName}</span>
        </div>
        <div>
          <span class="label">Breed:</span> <span id="breed">${breed}</span>
        </div>
        <div>
          <span class="label">Actual Weight:</span> <span id="actualWeight">${actualWeight}</span>
        </div>
        <div>
          <span class="label">Address:</span> <span id="address">${address}</span>
        </div>
        <div>
          <span class="label">Invoice Number:</span> <span id="invoiceNumber">${invoiceNumber}</span>
        </div>
        <div id="barcode">
          <div class="barcode-image">
            <img src="${barcodeDataUrl}" alt="Barcode">
          </div>
        </div>
      </div>
    </body>
  </html>
`;

  const invoiceWindow = window.open();
  invoiceWindow.document.open();
  invoiceWindow.document.write(invoiceHTML);
  invoiceWindow.document.close();
}
