import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { filterIdInListNotContainedInOtherList } from 'utils/utils';

async function fetchProductionPositionsForArea(areaId, toast) {
  try {
    return axiosConfig
      .get('/farmsetup/productionPositionsForArea', { params: { areaId } })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: `Error while fetching production positions for ${areaId}`,
    });
    return [];
  }
}

function PositionEdit({ data, display, onClose, onCompletion }) {
  const [inComplete, setInComplete] = useState(true);

  const [availableSpots, setAvailableSpots] = useState([]);

  const [spots, setSpots] = useState([]);

  const [loadingSpots, setLoadingSpots] = useState(false);

  const toast = useContext(ToastContext);

  const columnsSpots = [
    { header: 'Spot ID', field: 'spotId' },
    { header: 'Production Position ID', field: 'productionPositionId' },
    { header: 'Action', field: 'action' },
  ];

  useEffect(() => {
    setInComplete(true);
    if (spots.some((spot) => spot.new)) setInComplete(false);
  }, [spots]);

  useEffect(() => {
    setLoadingSpots(true);
    fetchProductionPositionsForArea(data.areaId, toast).then((positions) => {
      setSpots(positions);
      setAvailableSpots(
        filterIdInListNotContainedInOtherList(
          [...Array(data.areaLayoutRows * data.areaLayoutColumns).keys()].map(
            (_, index) => ({
              spotId: index + 1,
              productionPositionId: 'To be created',
              new: true,
            })
          ),
          positions,
          'spotId'
        )
      );
      setLoadingSpots(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.areaId, data.areaLayoutColumns, data.areaLayoutRows, toast]);

  const submitPositions = async (positions, areaId, _new = true) => {
    try {
      if (_new) {
        await axiosConfig.post('/farmsetup/createPositions', {
          positions,
          areaId,
        });
      } else {
        await axiosConfig.post('/farmsetup/updatePosition', {
          positions,
          areaId,
        });
      }
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${
          data?.areaId ? 'editing' : 'creating'
        } of positions!`,
      });
      onCompletion();
      onClose();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  const addSpot = (rowData) => (
    <Button
      icon="pi pi-plus"
      size="small"
      className="p-button-sm p-button-outlined "
      disabled={loadingSpots}
      onClick={() => {
        setSpots([...spots, rowData].sort((a, b) => a.spotId - b.spotId));
        setAvailableSpots(
          availableSpots.filter(({ spotId }) => spotId !== rowData.spotId)
        );
      }}
    />
  );

  const deleteSpot = (rowData) =>
    rowData.new ? (
      <Button
        icon="pi pi-trash"
        size="small"
        className="p-button-sm p-button-outlined "
        disabled={loadingSpots}
        onClick={() => {
          setAvailableSpots(
            [...availableSpots, rowData].sort((a, b) => a.spotId - b.spotId)
          );
          setSpots(spots.filter(({ spotId }) => spotId !== rowData.spotId));
        }}
      />
    ) : null;

  const positionTable = (positions, loadingPosition, actionBody) => (
    <DataTable
      emptyMessage="No positions found"
      value={positions.length ? positions : []}
      loading={loadingPosition}
      size="small"
    >
      {columnsSpots.map(({ header, field, body }) => (
        <Column
          key={field}
          header={header}
          field={field}
          body={field === 'action' ? actionBody : body}
        />
      ))}
    </DataTable>
  );

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data?.positionId ? 'Edit' : 'Create'} Position Spots for Area ID{' '}
          {data.areaId}
        </h2>
      </div>
      <div className="p-fluid grid">
        <h4>Unused Spots</h4>
        <div className="field col-12">
          {positionTable(availableSpots, loadingSpots, addSpot)}
        </div>
        <h4>Used Spots</h4>
        <div className="field col-12">
          {positionTable(spots, loadingSpots, deleteSpot)}
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data?.positionId ? 'Save' : 'Create position'}`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() =>
              submitPositions(
                spots.filter((spot) => spot.new),
                data.areaId,
                true
              )
            }
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

PositionEdit.propTypes = {
  data: PropTypes.objectOf({
    areaId: PropTypes.string.isRequired,
    areaLayoutRows: PropTypes.number.isRequired,
    areaLayoutColumns: PropTypes.number.isRequired,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
};

export { PositionEdit, fetchProductionPositionsForArea };
