/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import BoxQRScan from 'elements/BoxQRScan';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputNumber } from 'primereact/inputnumber';
import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

import PositionDialog from 'elements/PositionDialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import PackagingEdit from 'pages/Packaging/PackagingEdit';
import AuthContext from 'store/auth-context';

function SievingStablingDialog({ itemData, display, onResult, onClose }) {
  const toast = useContext(ToastContext);
  // STATES
  const [stackList, setStackList] = useState([]);
  const [showDialog, setShowDialog] = useState('');
  const [boxIdNotList, setBoxIdNotList] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [workPlanList, setWorkPlanList] = useState([]);
  const [workPlan, setWorkPlan] = useState([]);
  const [newBatchId, setNewBatchId] = useState(null);
  const [insectWeightPerBox, setInsectWeightPerBox] = useState(0);
  const [smallInsectWeightPerBox, setSmallInsectWeightPerBox] = useState(0);
  const { user } = useContext(AuthContext);
  const [item, setItem] = useState({
    comment: '',
  });

  const fetchWorkPlans = useCallback(async () => {
    try {
      const workPlanTemp = await axiosConfig
        .get('/sieving/workPlansForWorkItemSievingId', {
          params: { batchId: itemData.batchId },
        })
        .then((res) => res.data);
      setWorkPlanList(workPlanTemp);
      setWorkPlan(workPlanTemp[0]);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching work plan List',
      });
    }
  }, [itemData.batchId, toast]);

  useEffect(() => {
    const newBoxIdNotList = [];
    stackList.forEach((box) => {
      newBoxIdNotList.push(box.boxId);
    });
    setBoxIdNotList(newBoxIdNotList);
    fetchWorkPlans();
  }, [fetchWorkPlans, stackList]);

  if (!itemData) return null;

  const headerText = () => {
    const text = 'Stable Boxes From Sieving Results';
    return <h4>{text}</h4>;
  };

  const handleFocus = (event) => event.target.select();

  const commentEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData.comment}
        onChange={(e) => {
          const newStackList = [...stackList];
          const rowIndex = newStackList.findIndex(
            (box) => box.boxId === options.rowData.boxId
          );
          newStackList[rowIndex].comment = e.target.value;
          setStackList(newStackList);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const commentBodyTemplate = (options) => {
    // return check mark if comment is not empty, otherwise return plus sign
    return options.comment !== '' ? (
      <i className="pi pi-check" />
    ) : (
      <Button
        icon="pi pi-plus-circle"
        className=" p-button-sm p-button-outlined"
      />
    );
  };

  const deleteBoxTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() => {
          const newStackList = [
            ...stackList.filter((box) => box.boxId !== rowData.boxId),
          ];

          // update the input weight in each box
          newStackList.map((box) => {
            return {
              ...box,
              inputWeight: itemData.insectWeight / newStackList.length,
            };
          });
          setStackList(newStackList);
        }}
      />
    );
  };

  const remainingWeight = () =>
    itemData.insectWeight - stackList.length * insectWeightPerBox;

  const remainingSmallInsectWeight = () =>
    itemData.smallInsectWeight - stackList.length * smallInsectWeightPerBox;

  const stackTableColumns = [
    { key: 'boxId', header: 'Box ID', field: 'boxId', sortable: true },
    // {
    //   key: 'inputWeight',
    //   header: 'Input Weight',
    //   field: 'inputWeight',
    //   // editor: numberEditor,
    //   body: weightTemplate,
    // },
    // {
    //   key: 'inputSmallInsectWeight',
    //   header: 'Input Small Insect Weight',
    //   field: 'inputSmallInsectWeight',
    //   // editor: numberEditor,
    //   body: smallInsectWeightTemplate,
    // },
    {
      key: 'positionInStack',
      header: 'Position in Stack',
      field: 'positionInStack',
      sortable: true,
    },
    {
      key: 'comment',
      header: 'Comment',
      field: 'comment',
      editor: commentEditor,
      body: commentBodyTemplate,
      center: true,
    },
    { key: 'delete', body: deleteBoxTemplate, center: true, header: 'Delete' },
  ];

  const addToList = async (scanResults) => {
    const newItems = scanResults.map((scanResult) => {
      return {
        boxId: scanResult.boxId,
        comment: '',
        inputWeight: itemData.insectWeight / scanResults.length,
        inputSmallInsectWeight: itemData.smallInsectWeight / scanResults.length,
      };
    });
    setStackList(
      !stackList.length
        ? newItems.map((newItem, idx) => ({
            ...newItem,
            positionInStack: idx + 1,
          }))
        : [
            ...stackList,
            ...newItems.map((newItem, idx) => ({
              ...newItem,
              positionInStack: stackList.length + idx + 1,
            })),
          ]
    );
    return true;
  };

  const submitBatch = async (boxes) => {
    try {
      // also update workitemsieving and set isongoing to false, update box to active again, .........
      await axiosConfig
        .post('/sieving/stable', {
          boxes,
          comment: item.comment,
          workItemSievingId: Number(itemData.workItemSievingId),
          batchId: itemData.outputBatchId,
          oldBatchId: itemData.batchId,
          workPlan,
          email: user.email,
        })
        .then(() => {
          onResult && onResult();
          toast.pushToast({
            severity: ToastSeverity.SUCCESS,
            detail: 'Restabling Stack Submitted!',
          });
        });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  const confirmationDialog = (confirmationTemp) => (
    <ConfirmDialog
      visible={confirmationTemp}
      onHide={() => setConfirmation(false)}
      message="Please confirm the stabling of the stack"
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
      acceptLabel="Complete stabling"
      accept={() => {
        const stackListWithWeights = stackList.map((stack) => ({
          ...stack,
          inputWeight: insectWeightPerBox,
          inputSmallInsectWeight: smallInsectWeightPerBox,
        }));
        switch (confirmationTemp) {
          case 'cache':
            submitBatch(stackListWithWeights).then(() => {
              setStackList([]);
              onClose();
            });
            break;
          case 'position':
            submitBatch(stackListWithWeights).then(() =>
              setShowDialog('productionposition')
            );
            break;
          case 'package':
            submitBatch(stackListWithWeights).then(() =>
              setShowDialog('package')
            );
            break;
          default:
            console.error('no confirmation');
        }
      }}
    />
  );

  const optionTemplate = (option) => (
    <div className="option-item">
      <span>{option?.workPlanId} </span>
      <Badge value={option?.batchId} className="p-mr-2" severity="success" />
    </div>
  );

  const selectedOptionTemplate = (option) => (
    <div className="selected-option">
      <span>{option?.workPlanId} </span>
      <Badge value={option?.batchId} className="p-mr-2" severity="success" />
    </div>
  );

  const checkRemainingWeight = () => {
    return remainingSmallInsectWeight() < 0 || remainingWeight() < 0;
  };

  const isBoxAssigned = (stackListTemp) => {
    return !(stackListTemp && stackListTemp.length);
  };

  const firstPage = () => {
    return (
      <div className="formgrid p-fluid grid  align-items-end">
        <div className="field col-12 mt-2">
          <div
            className="grid grid-nogutter"
            style={{
              alignContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="field col-3 ">
              <label htmlFor="insectWeight">Total Insects Weight</label>
              <div className="p-inputgroup">
                <InputNumber
                  id="insectWeight"
                  value={itemData.insectWeight}
                  disabled
                />
                <span
                  style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                  className="p-inputgroup-addon"
                  title="Weight per sieving"
                >
                  kg
                </span>
              </div>
            </div>
            <div className="field col-1 " />
            <div className="field col-3 ">
              <label htmlFor="smallInsectWeight">
                Total Small Insects Weight
              </label>
              <div className="p-inputgroup">
                <InputNumber
                  id="inputweight"
                  value={itemData.smallInsectWeight}
                  disabled
                />
                <span
                  style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                  className="p-inputgroup-addon"
                  title="Weight per sieving"
                >
                  kg
                </span>
              </div>
            </div>
            <div className="field col-1 " />
            <div className=" field col-3 ">
              <label htmlFor="workplanid">Work Plan & Progess</label>
              <Dropdown
                value={workPlan}
                options={workPlanList}
                onChange={(e) => setWorkPlan(e.target.value)}
                optionLabel="workPlanId"
                placeholder="Select a work plan"
                itemTemplate={optionTemplate}
                valueTemplate={selectedOptionTemplate}
                disabled={workPlanList && workPlanList.length === 1}
              />
            </div>
            <div className="field col-1 " />
            <div className="field col-3 ">
              <label htmlFor="foodweight">Insect Weight per Box</label>
              <div className="p-inputgroup">
                <InputNumber
                  id="insectWeightPerBox"
                  value={insectWeightPerBox}
                  onChange={(e) => setInsectWeightPerBox(e.value)}
                  onFocus={handleFocus}
                  mode="decimal"
                  locale="de-DE"
                  minFractionDigits={1}
                />
                <span
                  style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                  className="p-inputgroup-addon"
                  title="Weight per Box"
                >
                  kg
                </span>
              </div>
            </div>
            <div className="field col-1 " />
            <div className="field col-3 ">
              <label htmlFor="foodweight">Small Insect Weight per Box</label>
              <div className="p-inputgroup">
                <InputNumber
                  id="smallInsectWeightPerBox"
                  value={smallInsectWeightPerBox}
                  onChange={(e) => setSmallInsectWeightPerBox(e.value)}
                  onFocus={handleFocus}
                  mode="decimal"
                  locale="de-DE"
                  minFractionDigits={1}
                />
                <span
                  style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                  className="p-inputgroup-addon"
                  title="Weight per Box"
                >
                  kg
                </span>
              </div>
            </div>
            <div className="field col-1 " />
            <div className=" col-3">
              <Button
                className="p-button-rounded p-button-outlined "
                label="Scan Boxes"
                icon="pi pi-qrcode"
                onClick={() => setShowDialog('box')}
              />
              {showDialog === 'box' && (
                <BoxQRScan
                  onResult={(scanResult) => {
                    addToList(scanResult, itemData.workItemSievingId);
                  }}
                  onClose={() => {
                    setShowDialog('');
                  }}
                  display={Boolean(showDialog)}
                  scanModeList={['series']}
                  // List of every boxId located in each stack
                  boxIdNotList={boxIdNotList}
                  boxesShouldBeActive={false}
                  boxesShouldExist
                />
              )}
            </div>
          </div>
        </div>

        <div className="field col-12">
          <DataTable
            value={stackList}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="boxId"
            sortOrder={-1}
            editMode="cell"
          >
            {stackTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
        <div className="field col-2" />
        <div className=" field col-12">
          <label htmlFor="name1">Comment</label>
          <InputText
            id="name1"
            type="text"
            value={item.comment}
            // add a symbol depending if the comment is empty or not
            onChange={(e) =>
              setItem({
                ...item,
                comment: e.target.value,
              })
            }
          />
        </div>
        <div className="field col-3 ">
          <label htmlFor="foodweight">Remaining Insects Weight</label>
          <div className="p-inputgroup">
            <InputNumber
              id="remainingweight"
              value={remainingWeight()}
              disabled
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1 " />
        <div className="field col-3 ">
          <label htmlFor="foodweight">Remaining Small Insects Weight</label>
          <div className="p-inputgroup">
            <InputNumber
              id="remainingweight"
              value={remainingSmallInsectWeight()}
              disabled
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1 " />
        <div className=" field col-12">
          {checkRemainingWeight() && (
            <span style={{ color: 'orange' }}>
              You can not stable more insects than the available insect weight
            </span>
          )}
        </div>
        <div className=" field col-12">
          {isBoxAssigned(stackList) && (
            <span style={{ color: 'orange' }}>Assign at least one Box</span>
          )}
        </div>
        <div className="field col-4 col-offset-0 mt-2">
          <Button
            label="Place stack later"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => setConfirmation('cache')}
            className="p-button-success p-button-rounded mr-2 mb-2"
            // check if food and foodweight are filled for every box
            disabled={
              !stackList.length ||
              stackList.some((box) => box.inputWeight === 0) ||
              !(remainingWeight() >= 0) ||
              checkRemainingWeight()
            }
          />
        </div>
        <div className="field col-4 col-offset-0 mt-2">
          <Button
            label="Package now"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => setConfirmation('package')}
            className="p-button-success p-button-rounded mr-2 mb-2"
            // check if food and foodweight are filled for every box
            disabled={
              !stackList.length ||
              stackList.some((box) => box.inputWeight === 0) ||
              !(remainingWeight() >= 0) ||
              checkRemainingWeight()
            }
          />
        </div>
        <div className="field col-4 col-offset-0 mt-2">
          <Button
            label="Place stack now"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => setConfirmation('position')}
            className="p-button-success p-button-rounded mr-2 mb-2"
            // check if food and foodweight are filled for every box
            disabled={
              !stackList.length ||
              stackList.some((box) => box.inputWeight === 0) ||
              !(remainingWeight() >= 0) ||
              checkRemainingWeight()
            }
          />
        </div>
      </div>
    );
  };

  // RENDER

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={() => onClose}
    >
      {confirmation && confirmationDialog(confirmation)}
      {showDialog === 'productionposition' && (
        <PositionDialog
          visible={showDialog === 'productionposition'}
          onHide={() => onClose()}
          boxes={stackList.map(({ boxId }) => boxId)}
          stackAndBoxes={stackList.map(({ boxId }) => ({
            stackId: 'ST-00000',
            boxId,
          }))}
          onComplete={() => {
            setStackList([]);
            setNewBatchId();
            onClose();
          }}
          createBoxes
        />
      )}
      {showDialog === 'package' && (
        <PackagingEdit
          data={{ batchId: newBatchId }}
          getBoxesFromBatch
          readOnly={false}
          display={showDialog === 'package'}
          onClose={() => onClose()}
          onCompletion={() => {
            setStackList([]);
            setNewBatchId();
            onClose();
          }}
        />
      )}
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-between mb-2">
          {headerText()}

          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined  "
          />
        </div>
      </div>

      {firstPage()}
    </Dialog>
  );
}

SievingStablingDialog.propTypes = {
  itemData: PropTypes.shape({
    batchId: PropTypes.string.isRequired,
    insectWeight: PropTypes.number.isRequired,
    smallInsectWeight: PropTypes.number.isRequired,
    workItemSievingId: PropTypes.number.isRequired,
    outputBatchId: PropTypes.string.isRequired,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onResult: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SievingStablingDialog;
