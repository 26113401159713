import { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
function InspectionPlanDelete({ planId, visible, onClose, onComplete }) {
  const toast = useContext(ToastContext);

  const deleteInspectionPlan = async (id) => {
    try {
      await axiosConfig.post('/inspection/delete', { id });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Deleted inspection plan!',
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while deleting inspection plan',
      });
    }
    onClose();
    onComplete();
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={visible}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-between mb-2">
          <h4>Confirm Deletion of Inspection Plan</h4>
        </div>
        <div className=" col-12" style={{ marginBottom: '1em' }}>
          <span />
          {`Are you sure to delete the inspection plan?\n`}
        </div>
        <div className=" col-8" />
        <div className=" col-2 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className=" col-2 ">
          <Button
            onClick={() => deleteInspectionPlan(planId)}
            label="Confirm"
            className="p-button-rounded p-button-success"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default InspectionPlanDelete;
