/* eslint-disable react/prop-types */
import { useContext, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function FoodCreateConfirmation({ data, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);
  const [recipeStatus, setRecipeStatus] = useState('active');
  const [recipeName, setRecipeName] = useState(
    `Recipe ${new Date().toLocaleString()}`
  );
  const { ingredientList, item, isNewRecipe } = data;

  const handleFocus = (event) => event.target.select();

  const createRecipe = async (recipeIngredients) => {
    if (isNewRecipe) {
      return axiosConfig
        .post('/foodLogistics/createRecipe', {
          recipeName,
          status: recipeStatus,
          recipeIngredients,
        })
        .then((res) => res.data.recipe.RecipeID);
    }
    return item.recipeId;
  };

  const submitList = async () => {
    try {
      const deliveriesRemainingAmount = [];
      const assignedDeliveries = [];
      const recipeIngredients = [];
      // eslint-disable-next-line array-callback-return
      ingredientList.map((ingredient) => {
        recipeIngredients.push({
          ratio: ingredient.ratio,
          ingredientId: ingredient.ingredientID,
        });
        // eslint-disable-next-line array-callback-return
        ingredient.assignedDeliveries.map((delivery) => {
          assignedDeliveries.push({
            rawFoodId: parseInt(delivery.deliveryID.substring(3), 10),
            assignedAmount: delivery.assignedAmount,
          });
          deliveriesRemainingAmount.push({
            rawFoodId: parseInt(delivery.deliveryID.substring(3), 10),
            remainingAmount: delivery.currentAmount,
          });
        });
      });
      await createRecipe(recipeIngredients).then((recipeId) =>
        axiosConfig.post('/foodLogistics/create', {
          // foodId: parseInt(foodId.substring(3), 10),
          foodName: item.foodName,
          recipeId,
          productionDate: new Date(),
          productionAmount: item.targetWeight,
          bestBefore: item.bestBefore,
          comment: item.comment,
          assignedDeliveries,
          deliveriesRemainingAmount,
        })
      );

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Creation!',
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  const headerText = () => {
    const text = `Confirm Creation of New Food: ${item.foodName}`;
    const recipeHeader = isNewRecipe
      ? ` and New Recipe (ID: ${addPrefix(item.recipeId, 'RE', 5)})`
      : null;
    return (
      <h3>
        {text}
        {recipeHeader}
      </h3>
    );
  };

  const inputRecipeName = () => {
    return (
      <div className="grid">
        <div className="field col-12">
          <label htmlFor="recipeName">Enter the new recipe{`'`}s name:</label>
          <InputText
            value={recipeName}
            id="recipeName"
            type="text"
            onChange={(e) => setRecipeName(e.target.value.substring(0, 50))}
            onFocus={handleFocus}
          />
        </div>
        <div className="field col-12">
          <span>
            Is the new recipe single use? (the recipe will be unlisted): {'  '}
          </span>
          <Checkbox
            checked={recipeStatus === 'hidden'}
            id="recipeStatus"
            value="hidden"
            onChange={(e) =>
              recipeStatus === 'hidden'
                ? setRecipeStatus('active')
                : setRecipeStatus('hidden')
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '70vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className="field col-12 ">{isNewRecipe && inputRecipeName()}</div>
        <div className=" col-4 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className="field col-4 ">
          <Button
            label="Confirm"
            className="p-button-rounded p-button-success"
            onClick={() => submitList()}
            disabled={!recipeName}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default FoodCreateConfirmation;
