import { FaSearch } from 'react-icons/fa';
import { DataTable } from 'primereact/datatable';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';

import InspectionPlanEdit from './InspectionPlanEdit';
import InspectionPlanDelete from './InspectionPlanDelete';

function InspectionPlans() {
  const toast = useContext(ToastContext);
  const [inspectionPlans, setInspectionPlans] = useState(null);
  const [action, setAction] = useState(null);
  const [actionInspectionPlanId, setActionInspectionPlanId] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const tableColumns = [
    { header: 'ID', field: 'InspectionPlanId' },
    { header: 'Name', field: 'InspectionPlanName' },
    { header: 'Description', field: 'InspectionPlanDescription' },
  ];

  const fetchTableData = async () => {
    try {
      const inspectionPlansRaw = await axiosConfig
        .get('/inspection/inspectionPlans')
        .then((res) => res.data);
      const inspectionPlansStructured = [];
      // eslint-disable-next-line no-unused-expressions
      if (Array.isArray(inspectionPlansRaw)) {
        inspectionPlansRaw.map(async (inspectionPlan) => {
          const InspectionPlanId = inspectionPlan.InspectionID;
          const InspectionPlanName = inspectionPlan.InspectionName;
          const InspectionPlanDescription =
            inspectionPlan.InspectionDescription;
          inspectionPlansStructured.push({
            InspectionPlanId,
            InspectionPlanName,
            InspectionPlanDescription,
          });
        });
      }
      setInspectionPlans(inspectionPlansStructured);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  };

  const actionOnTableRow = (rowData) => {
    const actionOptions = [
      { label: 'Delete', value: 'delete' },
      { label: 'Edit', value: 'edit' },
    ];
    return (
      <Dropdown
        value="..."
        options={actionOptions}
        onChange={(e) => {
          setAction(e.value);
          setActionInspectionPlanId(rowData.InspectionPlanId);
        }}
        placeholder="..."
      />
    );
  };

  const dialog = (mode, data) => {
    switch (mode) {
      case 'delete':
        return (
          <InspectionPlanDelete
            planId={actionInspectionPlanId}
            data={null}
            visible={Boolean(true)}
            onClose={() => setAction(null)}
            onComplete={() => setRefreshTrigger(true)}
          />
        );

      case 'edit':
        return (
          <InspectionPlanEdit
            planId={actionInspectionPlanId}
            visible={Boolean(true)}
            onClose={() => setAction(null)}
            onComplete={() => setRefreshTrigger(true)}
          />
        );

      case 'create':
        return (
          <InspectionPlanEdit
            planId={null}
            visible={Boolean(true)}
            onClose={() => setAction(null)}
            onComplete={() => setRefreshTrigger(true)}
          />
        );
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   fetchTableData();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    fetchTableData();
    setRefreshTrigger(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTrigger]);

  return (
    <div className="main-card main-card-content">
      {action != null && dialog(action, actionInspectionPlanId)}
      <div className="formGrid grid">
        <div className="field col-10">
          <h2 className="flex align-items-center">
            <FaSearch className="mr-3" /> Inspection Plans
          </h2>
        </div>
        <div className="p-fluid field col-12">
          <Button
            className="p-button-rounded p-button-success p-button-outlined"
            label="Create new inspection plan"
            onClick={(_) => {
              setAction('create');
            }}
          />
        </div>
        <div className="field col-12">
          <DataTable
            value={inspectionPlans}
            responsiveLayout="scroll"
            dataKey="inspectionPlanId"
            emptyMessage="No inspection plans found"
          >
            {tableColumns.map((column) => {
              const { header, field } = column;
              return (
                <Column
                  key={header}
                  field={field}
                  header={header}
                  sortable
                  style={{ width: 'auto' }}
                />
              );
            })}
            <Column
              header="Action"
              style={{ width: '3em' }}
              body={actionOnTableRow}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default InspectionPlans;
