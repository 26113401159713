/* eslint-disable react/prop-types */
import { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Accordion, AccordionTab } from 'primereact/accordion';
import axiosConfig from 'utils/axiosConfig';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

import { RiInboxArchiveLine } from 'react-icons/ri';

import BoxQRScan from 'elements/BoxQRScan';
import FoodQRScan, {
  checkFood,
  handleExternalFoodScan,
} from 'elements/FoodQRScan';
import EggQRScan, {
  checkEgg,
  handleExternalEggScan,
  handleFocus,
} from 'elements/EggQRScan';

import { Dropdown } from 'primereact/dropdown';
import PositionDialog from 'elements/PositionDialog';
import { Chip } from 'primereact/chip';
import QRCodeTextField from 'elements/QRCodeTextField';
import StablingContext from './StablingContext';

export function addPrefix(id, prefix, numLeadingZeroes) {
  return `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;
}

function StablingCreateStack() {
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const { stablingValues } = useContext(StablingContext);

  // STATES
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDialog, setShowDialog] = useState('');
  const [foodList, setFoodList] = useState([]);
  const [remainingFood, setRemainingFood] = useState(0);
  const [eggList, setEggList] = useState([]);
  const [addOrEditStack] = useState('add');
  const [item, setItem] = useState(
    stablingValues !== null
      ? stablingValues
      : {
          eggId: '',
          eggWeight: 0,
          recipeId: '',
          foodId: '',
          foodWeight: 0,
          comment: '',
        }
  );
  const [stackList, setStackList] = useState([]);
  const [recipeList, setRecipeList] = useState([]);
  const [workPlanList, setWorkPlanList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [boxes, setBoxes] = useState([]);
  // A list of boxIds that are either in the current stackList or in the batchList
  const [boxIdNotList, setBoxIdNotList] = useState([]);
  const [element, setElement] = useState(null);
  const resultFunctionDict = {
    EG: (input) => setItem({ ...item, eggId: input }),
    FD: (scanResult) =>
      setItem({
        ...item,
        foodId: scanResult,
        recipeId: foodList.find(({ foodId }) => foodId === scanResult).recipeId,
      }),
    BX: (scanResult) => setBoxes([...(boxes || []), ...scanResult]),
  };

  const INTERVALLENGTH = 100;
  useEffect(() => {
    const interval = setInterval(() => {
      if (element && document.activeElement.tagName.toUpperCase() !== 'INPUT')
        element.focus();
    }, INTERVALLENGTH);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [element]);

  const numberEditor = (options) => (
    <InputNumber
      value={options.rowData[options.field]}
      onChange={(e) => {
        const newStackList = [...stackList];
        newStackList[
          newStackList.findIndex((box) => box.boxId === options.rowData.boxId)
        ][options.field] = Math.round(e.value * 1000) / 1000;
        setStackList(newStackList);
      }}
      maxFractionDigits={3}
    />
  );

  const dropdownEditorFood = (options) => (
    <Dropdown
      value={options.rowData.foodId}
      id="foodid"
      options={foodList}
      onChange={(e) => {
        const newStackList = [...stackList];
        const index = newStackList.findIndex(
          (box) => box.boxId === options.rowData.boxId
        );
        newStackList[index].foodId = e.value;
        newStackList[index].recipeId = foodList.find(
          ({ foodId }) => foodId === e.value
        ).recipeId;
        setStackList(newStackList);
      }}
      placeholder="Select Food"
      optionLabel="foodId"
      optionValue="foodId"
      valueTemplate={selectedFoodTemplate}
      itemTemplate={foodOptionTemplate}
    />
  );

  const dropdownEditorEgg = (options) => (
    <Dropdown
      value={item?.eggId}
      id="eggid"
      options={
        Array.isArray(eggList)
          ? eggList.filter(({ remainingAmount }) => remainingAmount > 0)
          : []
      }
      onChange={(e) => {
        const newStackList = [...stackList];
        newStackList[
          newStackList.findIndex((box) => box.boxId === options.rowData.boxId)
        ].eggId = e.value;
        setStackList(newStackList);
      }}
      placeholder="Select Egg"
      optionLabel="eggId"
      optionValue="eggId"
      valueTemplate={selectedEggTemplate}
      itemTemplate={eggOptionTemplate}
    />
  );

  const dropdownEditorWorkPlan = (options) => (
    <Dropdown
      value={options.rowData.workPlanId}
      id="workPlanId"
      options={workPlanList}
      onChange={(e) => {
        const newStackList = [...stackList];
        newStackList[
          newStackList.findIndex((box) => box.boxId === options.rowData.boxId)
        ].workPlanId = e.value;
        setStackList(newStackList);
      }}
      placeholder="Select Work Plan"
      optionLabel="WorkPlanID"
      optionValue="WorkPlanID"
    />
  );

  const commentEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData.comment}
        onChange={(e) => {
          const newStackList = [...stackList];
          const rowIndex = newStackList.findIndex(
            (box) => box.boxId === options.rowData.boxId
          );
          newStackList[rowIndex].comment = e.target.value;
          setStackList(newStackList);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const commentStackEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData.comment}
        onChange={(e) => {
          const newBatchList = [...batchList];
          const batchRowIndex = newBatchList.findIndex(
            (stack) => stack.stackList === options.rowData.stackList
          );
          newBatchList[batchRowIndex].comment = e.target.value;
          setBatchList(newBatchList);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const deleteBoxTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() => deleteBox(itemData)}
      />
    );
  };

  const commentBodyTemplate = (options) => {
    // return check mark if comment is not empty, otherwise return plus sign
    return options.comment !== '' ? (
      <i className="pi pi-check" />
    ) : (
      <Button
        icon="pi pi-plus-circle"
        className=" p-button-sm p-button-outlined"
      />
    );
  };

  const stackTableColumns = [
    { key: 'boxId', header: 'Box ID', field: 'boxId', sortable: true },
    {
      key: 'eggId',
      header: 'Egg ID',
      field: 'eggId',
      sortable: true,
      editor: dropdownEditorEgg,
    },
    {
      key: 'eggWeight',
      header: 'Egg Weight in kg',
      field: 'eggWeight',
      editor: numberEditor,
    },
    {
      key: 'recipeId',
      header: 'Recipe ID',
      field: 'recipeId',
      sortable: true,
    },
    {
      key: 'foodId',
      header: 'Food ID',
      field: 'foodId',
      sortable: true,
      editor: dropdownEditorFood,
    },
    {
      key: 'foodWeight',
      header: 'Food Weight in kg',
      field: 'foodWeight',
      editor: numberEditor,
    },
    {
      key: 'positionInStack',
      header: 'Position in Stack',
      field: 'positionInStack',
      sortable: true,
    },
    {
      key: 'workPlanId',
      header: 'Work Plan ID',
      field: 'workPlanId',
      sortable: true,
      editor: dropdownEditorWorkPlan,
    },
    {
      key: 'comment',
      header: 'Comment',
      field: 'comment',
      editor: commentEditor,
      body: commentBodyTemplate,
      center: true,
    },
    { key: 'delete', body: deleteBoxTemplate, center: true, header: 'Delete' },
  ];

  const MASK = 'aa-99999';

  const batchNumBoxesBodyTemplate = (options) => {
    return options.stackList.length;
  };

  const batchStackNumBodyTemplate = (options) => {
    // find the index of this row in the batchList
    const stackIndex = batchList.findIndex((stack) => stack === options);
    return stackIndex + 1;
  };

  const confirmDelete = (options) => {
    confirmDialog({
      message: 'Delete this stack?',
      header: null,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const newBatchList = batchList.filter((stack) => stack !== options);
        setBatchList(newBatchList);
        if (newBatchList.length === 0) {
          setActiveIndex(0);
        }
      },
    });
  };

  const deleteStackTemplate = (options) => (
    <Button
      icon="pi pi-trash"
      className="p-button-sm p-button-outlined "
      onClick={() => confirmDelete(options)}
    />
  );

  const batchTableColumns = [
    {
      key: 'stack',
      header: 'Stack',
      field: 'stackId',
      body: batchStackNumBodyTemplate,
    },
    {
      key: 'numboxes',
      header: '# Boxes',
      field: 'numboxes',
      body: batchNumBoxesBodyTemplate,
    },
    {
      key: 'comment',
      header: 'Comment',
      field: 'comment',
      editor: commentStackEditor,
      body: commentBodyTemplate,
      center: true,
    },
    {
      key: 'delete',
      body: deleteStackTemplate,
      center: true,
      header: 'Delete',
    },
  ];

  const dialogSubmitBatchPlaceNow = (batches) => {
    if (batchList.length) {
      confirmDialog({
        message: 'Stable the boxes and open the positioning dialog',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Complete stabling and position',
        accept: () =>
          submitBatch(
            batches.map((batch) => ({
              ...batch,
              productionPositionId: 'PS-00000',
            }))
          ).then(() => setShowDialog('productionposition')),
      });
    } else {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No stacks Missing!',
      });
    }
  };

  const dialogSubmitBatch = (batches) => {
    if (batchList.length) {
      confirmDialog({
        message: 'Stable boxes and place boxes into storage cache',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Complete stabling',
        accept: () =>
          submitBatch(
            batches.map((batch) => ({
              ...batch,
              productionPositionId: 'PS-00000',
            }))
          ).then(() => completion()),
      });
    } else {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No stacks Missing!',
      });
    }
  };

  const fetchFoodList = async () => {
    try {
      const foodListJSON = await axiosConfig
        .get('/foodLogistics/table')
        .then((res) => res.data);
      setFoodList(foodListJSON);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  const fetchWorkPlans = async () => {
    try {
      const workPlanTemp = await axiosConfig
        .get('/workPlan/workPlans')
        .then((res) => res.data.filter(({ Status }) => Status === 'active'));
      setWorkPlanList(workPlanTemp);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching work plan List',
      });
    }
  };

  const fetchEggList = async () => {
    try {
      const eggListTemp = await axiosConfig
        .get('/eggLogistics/table')
        .then((res) => res.data);
      setEggList(eggListTemp);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching work plan List',
      });
    }
  };

  const fetchRecipeList = async () => {
    try {
      const recipeListJSON = await axiosConfig
        .get('/foodLogistics/recipes')
        .then((res) => res.data);
      if (recipeListJSON !== null) {
        setRecipeList(
          recipeListJSON
            .filter(({ Status }) => Status === 'active' || Status === 'new')
            .map(
              // eslint-disable-next-line array-callback-return
              ({ RecipeID, Name }, key) => ({
                key,
                label: Name,
                value: addPrefix(RecipeID, 'RE', 5),
              })
            )
        );
      }
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recipes List',
      });
    }
  };

  useEffect(() => {
    fetchWorkPlans();
    fetchFoodList();
    fetchEggList();
    fetchRecipeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hook for modifying the boxIdNotList when the stackList or batchList changes
  useEffect(() => {
    Array.isArray(batchList) &&
      Array.isArray(stackList) &&
      setBoxIdNotList(
        [...batchList.map((stack) => stack.stackList).flat(), ...stackList].map(
          ({ boxId }) => boxId
        )
      );
  }, [batchList, stackList]);

  useEffect(() => {
    item?.foodId &&
      Array.isArray(foodList) &&
      setRemainingFood(
        foodList.find(({ foodId }) => foodId === item?.foodId)?.remainingAmount
      );
  }, [foodList, item]);

  const selectedFoodTemplate = (option) => {
    if (option) {
      return <span>{option.foodId}</span>;
    }

    return 'Select';
  };

  const foodOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.foodId}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const eggOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.eggId}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const selectedEggTemplate = (option) => {
    if (option) {
      return <span>{option.eggId}</span>;
    }

    return 'Select';
  };

  const completion = () => {
    setStackList([]);
    setBatchList([]);
    resetForm();
    fetchWorkPlans();
    fetchFoodList();
    fetchEggList();
    setActiveIndex(0);
    navigate('/overview');
  };

  const submitBatch = async (listBatch) => {
    try {
      await axiosConfig.post('/stabling/addBatch', {
        batchList: listBatch,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Batch Submitted!',
      });
      // return to overview page
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.message,
      });
    }
  };

  const addToList = async (scanResults) => {
    const toastMsgList = [
      await checkEgg(item.eggId, true, true),
      await checkFood(item.foodId, [], true, true, item?.recipeId || null),
    ];

    toastMsgList.map((toastMsg) =>
      toastMsg ? toast.pushToast(toastMsg) : null
    );

    if (toastMsgList.some((toastMsg) => toastMsg)) {
      return false;
    }

    const newItems = scanResults.map((scanResult) => {
      return {
        ...item,
        boxId: scanResult.boxId,
      };
    });
    setStackList(
      !stackList.length
        ? newItems.map((newItem, idx) => ({
            ...newItem,
            positionInStack: idx + 1,
          }))
        : [
            ...stackList,
            ...newItems.map((newItem, idx) => ({
              ...newItem,
              positionInStack: stackList.length + idx + 1,
            })),
          ]
    );
    setBoxes([]);
    setActiveIndex(1);
    return true;
  };

  const resetForm = () => {
    setItem(stablingValues);
    setBatchList([]);
  };

  const firstPage = () => {
    return (
      <div className="formgrid p-fluid grid grid-nogutter align-items-end">
        <div className="field col-12">
          You can directly scan the codes for eggs, food and boxes and they will
          be entered automatically.
        </div>
        <div className="col-12 md:col-6 lg:col-4 mt-3 flex grid">
          <div className="field col-12">Work Plan</div>
          <div className="field col-9">
            <Dropdown
              id="workplanid"
              value={item?.workPlanId}
              options={workPlanList}
              onChange={(e) => {
                const attributesString = workPlanList.find(
                  ({ WorkPlanID }) => WorkPlanID === e.target.value
                ).WorkSteps?.length
                  ? workPlanList
                      .find(({ WorkPlanID }) => WorkPlanID === e.target.value)
                      .WorkSteps.find(
                        ({ WorkTaskName }) => WorkTaskName === 'Stabling'
                      )?.Attributes
                  : null;
                const attributesObject = Object.fromEntries(
                  (attributesString ? JSON.parse(attributesString) : []).map(
                    ({ attribute, value }) => [attribute, value]
                  )
                );
                attributesObject.recipeId = attributesObject.recipeId
                  ? addPrefix(attributesObject.recipeId, 'RE', 5)
                  : item.recipeId;
                setItem({
                  ...item,
                  workPlanId: e.target.value,
                  ...attributesObject,
                });
              }}
              placeholder="Select Work Plan"
              optionLabel="WorkPlanName"
              optionValue="WorkPlanID"
              disabled={stackList?.length || batchList?.length}
            />
          </div>
          <div className="field col-3 justify-content-end">
            <Button
              icon="pi pi-times"
              className="p-button-outlined"
              aria-label="Cancel"
              onClick={() => setItem({ ...item, workPlanId: null })}
              disabled={stackList?.length || batchList?.length}
            />
          </div>
        </div>

        <div className="col-12 lg:col-8 mt-3 flex grid">
          <div className="col-6 flex grid">
            <div className="field col-12">Egg ID</div>
            <div className="field col-9">
              <Dropdown
                value={item?.eggId}
                id="eggid"
                options={
                  Array.isArray(eggList)
                    ? eggList.filter(
                        ({ remainingAmount }) => remainingAmount > 0
                      )
                    : []
                }
                onChange={(e) =>
                  handleExternalEggScan(
                    e.target.value,
                    MASK,
                    'EG',
                    toast,
                    (externalScanResult) =>
                      setItem({ ...item, eggId: externalScanResult })
                  )
                }
                placeholder="Select Egg"
                editable
                onFocus={handleFocus}
                optionLabel="eggId"
                optionValue="eggId"
                valueTemplate={selectedEggTemplate}
                itemTemplate={eggOptionTemplate}
              />
            </div>
            <div className="field col-3 justify-content-end">
              <Button
                className=" p-button-outlined"
                onClick={() => setShowDialog('egg')}
                icon="pi pi-qrcode"
              />
              {showDialog === 'egg' && (
                <EggQRScan
                  onResult={(scanResult) =>
                    setItem({ ...item, eggId: scanResult })
                  }
                  onClose={() => {
                    setShowDialog('');
                  }}
                  eggShouldBeActive
                  display={Boolean(showDialog)}
                />
              )}
            </div>
          </div>

          <div className="col-6 flex grid">
            <div className="field col-12">
              Egg Weight (Available:{' '}
              {item?.eggId && Array.isArray(eggList)
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  eggList.find(({ eggId }) => item?.eggId === eggId)
                    ?.remainingAmount * 1000
                : 0}{' '}
              g)
            </div>
            <div className="field col-9 p-inputgroup">
              <InputNumber
                id="eggweight"
                value={(item?.eggWeight || 0) * 1000}
                onChange={(e) =>
                  setItem({
                    ...item,
                    eggWeight: Math.min(
                      Math.round(e.value) / 1000,
                      item?.eggId && Array.isArray(eggList)
                        ? eggList.find(({ eggId }) => item?.eggId === eggId)
                            ?.remainingAmount
                        : 0
                    ),
                  })
                }
                max={
                  item?.eggId && Array.isArray(eggList)
                    ? // eslint-disable-next-line no-unsafe-optional-chaining
                      eggList.find(({ eggId }) => item?.eggId === eggId)
                        ?.remainingAmount * 1000
                    : 0
                }
                onFocus={handleFocus}
                disabled={
                  !item?.eggId || stackList?.length || batchList?.length
                }
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per box"
              >
                g
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-4 mt-3 flex grid">
          <div className="field col-12">Recipe ID</div>
          <div className="field col-9">
            <Dropdown
              value={item?.recipeId}
              id="recipeId"
              options={recipeList}
              onChange={(e) => {
                setItem({ ...item, recipeId: e.target.value, foodId: null });
                setRemainingFood(0);
              }}
              disabled={stackList?.length || batchList?.length}
              placeholder="Select Recipe"
            />
          </div>
          <div className="field col-3 justify-content-center">
            <Button
              className="p-button-outlined"
              icon="pi pi-times"
              aria-label="Cancel"
              onClick={() => setItem({ ...item, recipeId: null })}
              disabled={stackList?.length || batchList?.length}
            />
          </div>
        </div>

        <div className="col-12 lg:col-8 mt-3 flex grid">
          <div className="col-6 flex grid">
            <div className="field col-12">Food ID</div>
            <div className="field col-9">
              <Dropdown
                value={item?.foodId}
                id="foodid"
                options={
                  Array.isArray(foodList)
                    ? foodList.filter(
                        ({ remainingAmount, recipeId }) =>
                          remainingAmount > 0 &&
                          !(item?.recipeId && item.recipeId !== recipeId)
                      )
                    : []
                }
                onChange={(e) =>
                  handleExternalFoodScan(
                    e.target.value,
                    MASK,
                    'FD',
                    toast,
                    (scanResult) => {
                      setRemainingFood(
                        foodList.find(({ foodId }) => foodId === scanResult)
                          ?.remainingAmount
                      );
                      setItem({
                        ...item,
                        foodId: scanResult,
                        recipeId: foodList.find(
                          ({ foodId }) => foodId === scanResult
                        ).recipeId,
                      });
                    },
                    null,
                    item?.recipeId || null
                  )
                }
                placeholder="Select Food"
                editable
                onFocus={handleFocus}
                optionLabel="foodId"
                optionValue="foodId"
                valueTemplate={selectedFoodTemplate}
                itemTemplate={foodOptionTemplate}
              />
            </div>
            <div className="field col-3 justify-content-center">
              <Button
                className=" p-button-outlined"
                onClick={() => setShowDialog('food')}
                icon="pi pi-qrcode"
              />
              {showDialog === 'food' && (
                <FoodQRScan
                  onResult={
                    (scanResult) => setItem({ ...item, foodId: scanResult })
                    // setFoodId(scanResult)
                  }
                  onClose={() => {
                    setShowDialog('');
                  }}
                  display={Boolean(showDialog)}
                  foodShouldBeActive
                  recipeId={item?.recipeId || null}
                />
              )}
            </div>
          </div>
          <div className="col-6 flex grid">
            <div className="field col-12">
              Food Weight ({remainingFood} kg available)
            </div>
            <div className="field col-9 p-inputgroup">
              <InputNumber
                id="foodweight"
                value={item?.foodWeight}
                onChange={(e) =>
                  setItem({
                    ...item,
                    foodWeight: Math.round(e.value * 1000) / 1000,
                  })
                }
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                maxFractionDigits={3}
                max={remainingFood}
                onFocus={handleFocus}
                disabled={
                  !remainingFood || stackList?.length || batchList?.length
                }
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per box"
              >
                kg
              </span>
            </div>
          </div>
        </div>

        <div className="field col-12 mt-3">
          <label htmlFor="name1">Comment</label>
          <InputText
            id="name1"
            type="text"
            value={item?.comment}
            // add a symbol depending if the comment is empty or not
            onChange={(e) =>
              setItem({
                ...item,
                comment: e.target.value,
                commentSymbol: e.target.value ? '✓' : '✗',
              })
            }
          />
        </div>

        <div className="field col-6 lg:col-3 mt-3">
          <Button
            className="p-button-rounded p-button-outlined "
            label="Manually add boxes"
            icon="pi pi-qrcode"
            onClick={() => setShowDialog('box')}
          />
        </div>

        <div className="col-12 mt-3 grid">
          <div className="field col-6 lg:col-9" />
          <div className="field col-6 lg:col-3">
            <Button
              className="p-button-rounded p-button-outlined p-button-success"
              label="Continue"
              onClick={() => addToList(boxes)}
              disabled={!item?.foodId || !item?.foodWeight || !boxes?.length}
            />
          </div>
        </div>

        {boxes?.length ? (
          <div className="col-12">
            {boxes.map((box) => (
              <Chip
                style={{
                  marginRight: '10px',
                  marginTop: '5px',
                }}
                label={box.boxId}
              />
            ))}
          </div>
        ) : null}
        {stackList?.length || batchList?.length ? (
          <div className="field col-11">
            <span style={{ color: 'orange' }}>
              Editing of egg weight, recipe Id, food weight, and work plan
              disabled as boxes are already stabled.
            </span>
          </div>
        ) : null}
        {showDialog === 'box' && (
          <BoxQRScan
            onResult={(scanResult) => {
              setBoxes(scanResult);
            }}
            onClose={() => {
              setShowDialog('');
            }}
            itemListInit={boxes || []}
            display={Boolean(showDialog)}
            boxesShouldBeActive={false}
            boxesShouldExist
            scanModeList={['series']}
            // List of every boxId located in each stack
            boxIdNotList={boxIdNotList}
          />
        )}
      </div>
    );
  };

  const compareFoodAmounts = (stackListTemp, foodListTemp) => {
    // reduce to weights
    const foodWeights = stackListTemp.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.foodId]) {
        accumulator[currentValue.foodId] = currentValue.foodWeight;
      } else {
        accumulator[currentValue.foodId] += currentValue.foodWeight;
      }
      return accumulator;
    }, {});

    return foodListTemp.map(
      ({ foodId, recipeId, remainingAmount, Status }) => ({
        foodId,
        recipeId,
        remainingAmount,
        Status,
        foodWeight: foodWeights[foodId],
        weightEqualOrLess: foodWeights[foodId]
          ? foodWeights[foodId] <= remainingAmount
          : true,
      })
    );
  };

  const compareEggAmounts = (stackListTemp, eggListTemp) => {
    // reduce to weights
    const eggWeights = stackListTemp.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.eggId]) {
        accumulator[currentValue.eggId] = currentValue.eggWeight;
      } else {
        accumulator[currentValue.eggId] += currentValue.eggWeight;
      }
      return accumulator;
    }, {});

    return eggListTemp.map(({ eggId, remainingAmount, Status }) => ({
      eggId,
      remainingAmount,
      Status,
      eggWeight: eggWeights[eggId],
      weightEqualOrLess: eggWeights[eggId]
        ? eggWeights[eggId] <= remainingAmount
        : true,
    }));
  };

  const checkEqualTreatment = (list) => {
    if (!list.length) return true;
    const firstElement = JSON.stringify({
      recipeId: list[0].recipeId,
      eggWeight: list[0].eggWeight,
      foodWeight: list[0].foodWeight,
      workPlanId: list[0].workPlanId,
    });
    return list.some(
      ({ recipeId, eggWeight, foodWeight, workPlanId }) =>
        firstElement !==
        JSON.stringify({ recipeId, eggWeight, foodWeight, workPlanId })
    );
  };

  const deleteBox = async (itemData) => {
    // filter out the item to be deleted from itemlist and set it to the state
    const newItemList = stackList.filter((box) => {
      return box.boxId !== itemData.boxId;
    });
    setStackList(newItemList);
  };

  const addStack = (activeIndexTemp, reset = true) => {
    setFoodList(
      compareFoodAmounts(stackList, foodList).map(
        ({ foodId, recipeId, foodWeight, remainingAmount, Status }) => ({
          foodId,
          recipeId,
          remainingAmount: remainingAmount - (foodWeight || 0),
          Status,
        })
      )
    );
    if (reset) {
      setBatchList([...batchList, { stackList, comment: '' }]);
      setStackList([]);
    }
    setActiveIndex(activeIndexTemp);
  };

  const secondPage = () => {
    return (
      <div className="formgrid grid justify-content-center">
        <div className="field col-12">
          <DataTable
            value={stackList}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="boxId"
            sortOrder={-1}
            editMode="cell"
          >
            {stackTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                // className={column.center ? 'justify-content-center' : ''}
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
        {addOrEditStack === 'add' ? (
          <>
            {checkEqualTreatment(stackList) && (
              <div className="field col-10 col-offset-1">
                <span style={{ color: 'orange' }}>
                  Please make sure all stacks are treated equally.
                </span>
              </div>
            )}
            {compareFoodAmounts(stackList, foodList).some(
              (food) => !food.weightEqualOrLess
            ) && (
              <div className="field col-10 col-offset-1">
                <span style={{ color: 'orange' }}>
                  Please make sure the total food weight assigned to boxes are
                  below the available weight for the food
                  <br />
                  {compareFoodAmounts(stackList, foodList)
                    .filter(({ weightEqualOrLess }) => !weightEqualOrLess)
                    .map(({ foodId, foodWeight, remainingAmount }, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={key}>
                        Food Id: {foodId} has an assigned weight of {foodWeight}{' '}
                        kg with only {remainingAmount} kg available
                      </li>
                    ))}
                </span>
              </div>
            )}
            {compareEggAmounts(stackList, eggList).some(
              (egg) => !egg.weightEqualOrLess
            ) && (
              <div className="field col-10 col-offset-1">
                <span style={{ color: 'orange' }}>
                  Please make sure the total egg weight assigned to boxes are
                  below the available weight for the egg id
                  <br />
                  {compareEggAmounts(stackList, eggList)
                    .filter(({ weightEqualOrLess }) => !weightEqualOrLess)
                    .map(({ eggId, eggWeight, remainingAmount }, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={key}>
                        Egg Id: {eggId} has an assigned weight of {eggWeight} kg{' '}
                        with only {remainingAmount} kg available
                      </li>
                    ))}
                </span>
              </div>
            )}
            <div className="field col-4">
              <Button
                label="Add more boxes"
                onClick={() => addStack(0, false)}
                className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
                disabled={
                  !stackList.length ||
                  compareFoodAmounts(stackList, foodList).some(
                    (food) => !food.weightEqualOrLess
                  ) ||
                  checkEqualTreatment(stackList) ||
                  compareEggAmounts(stackList, eggList).some(
                    (egg) => !egg.weightEqualOrLess
                  )
                }
              />
            </div>
            <div className="field col-4">
              <Button
                label="Create new stack"
                onClick={() => addStack(0)}
                className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
                disabled={
                  !stackList.length ||
                  compareFoodAmounts(stackList, foodList).some(
                    (food) => !food.weightEqualOrLess
                  ) ||
                  checkEqualTreatment(stackList) ||
                  compareEggAmounts(stackList, eggList).some(
                    (egg) => !egg.weightEqualOrLess
                  )
                }
              />
            </div>
            <div className="field col-4">
              <Button
                label="Finish stabling"
                onClick={() => addStack(2)}
                className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
                disabled={
                  !stackList.length ||
                  compareFoodAmounts(stackList, foodList).some(
                    (food) => !food.weightEqualOrLess
                  ) ||
                  checkEqualTreatment(stackList) ||
                  compareEggAmounts(stackList, eggList).some(
                    (egg) => !egg.weightEqualOrLess
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="field col-4 col-offset-2 mt-2">
              <Button
                label="Cancel Edit"
                onClick={() => {
                  setBatchList([...batchList, { stackList, comment: '' }]);
                  setStackList([]);
                  setActiveIndex(0);
                }}
                className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
                disabled={!stackList.length}
              />
            </div>
            <div className="field col-4 col-offset-2 mt-2">
              <Button
                label="Save Edit"
                onClick={() => {
                  setBatchList([...batchList, { stackList, comment: '' }]);
                  setStackList([]);
                  setActiveIndex(2);
                }}
                className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
                disabled={!stackList.length} // rather: any inputs are missing
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const thirdPage = () => {
    // TODO WARNING FOR DIFFERENT BATCH
    return (
      <div className="formgrid grid justify-content-center p-fluid">
        <div className="field col-12">
          <DataTable
            value={batchList}
            showGridlines
            size="small"
            scrollable
            scrollHeight="50vh"
          >
            {batchTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
        <div className="field col-4">
          <Button
            label="Add more stacks"
            onClick={() => setActiveIndex(0)}
            className="p-button-success p-button-rounded p-button-outlined"
          />
        </div>
        <div className="field col-4">
          <Button
            label={`Complete Stabling and place stacks ( ${batchList.length} ) later`}
            onClick={() => dialogSubmitBatch(batchList)}
            className="p-button-success p-button-rounded "
            disabled={!batchList.length}
          />
        </div>
        <div className="field col-4">
          <Button
            label={`Complete Stabling and place stacks ( ${batchList.length} ) now`}
            onClick={() => dialogSubmitBatchPlaceNow(batchList)}
            className="p-button-success p-button-rounded "
            disabled={!batchList.length}
          />
        </div>
      </div>
    );
  };

  // RENDER
  return (
    <div className="main-card main-card-content">
      <ConfirmDialog />
      {showDialog === 'productionposition' && (
        <PositionDialog
          visible={showDialog === 'productionposition'}
          onHide={() => setShowDialog().then(() => completion())}
          boxes={[].concat(
            ...batchList.map((stackListTemp) =>
              [].concat(...stackListTemp.stackList.map(({ boxId }) => boxId))
            )
          )}
          stackAndBoxes={[].concat(
            ...batchList.map((stackListTemp, stackKey) =>
              [].concat(
                ...stackListTemp.stackList.map(({ boxId }) => ({
                  stackId: addPrefix(stackKey, 'ST', 5),
                  boxId,
                }))
              )
            )
          )}
          onComplete={() => completion()}
          createBoxes
        />
      )}
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          <RiInboxArchiveLine className="mr-3" /> Stabling
        </h2>
      </div>
      <Accordion
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <AccordionTab header="Add Stack Information">
          {firstPage()}
        </AccordionTab>
        <AccordionTab header="Stack Overview" disabled={!stackList.length}>
          {secondPage()}
        </AccordionTab>
        <AccordionTab header="Batch Overview" disabled={!batchList.length}>
          {thirdPage()}
        </AccordionTab>
      </Accordion>
      <QRCodeTextField
        MASK={MASK}
        resultFunctionDict={resultFunctionDict}
        boxIdNotList={boxIdNotList}
        boxes={boxes}
        setElement={(inputElement) => setElement(inputElement)}
        boxesShouldBeActive={false}
        boxesShouldExist
      />
    </div>
  );
}
export default StablingCreateStack;
