import { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function RecordWeightsModal({ sievingId, onClose, display, onComplete }) {
  const toast = useContext(ToastContext);

  const [sievingResult, setSievingResult] = useState({});

  const handleFocus = (event) => event.target.select();

  const closeModal = () => {
    onClose();
  };

  const submitResults = async () => {
    try {
      await axiosConfig
        .post('/sieving/saveResultWeights', {
          sievingId,
          sievingResult,
        })
        .then(() => onComplete());
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Saved Sieving Results',
      });
      closeModal();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while saving Sieving Results',
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={closeModal}
    >
      <div className="p-fluid formgrid grid">
        <div className="col-11 flex mb-2">
          <h2>Record weights</h2>
        </div>
        <div className=" col-1 flex justify-content-end mb-2">
          <Button
            onClick={() => closeModal()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined "
          />
        </div>
        <div className="field col-2  ">
          <label htmlFor="insectweight">Insects</label>
          <div className="p-inputgroup">
            <InputNumber
              id="insectweight"
              value={sievingResult.insectWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  insectWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="smallinsectweight">Small Insects</label>
          <div className="p-inputgroup">
            <InputNumber
              id="smallinsectweight"
              value={sievingResult.smallInsectWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  smallInsectWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="puppetsweight">Puppets</label>
          <div className="p-inputgroup">
            <InputNumber
              id="puppetsweight"
              value={sievingResult.puppetsWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  puppetsWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="beetlesweight">Beetles</label>
          <div className="p-inputgroup">
            <InputNumber
              id="beetlesweight"
              value={sievingResult.beetlesWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  beetlesWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="excrementweight">Excrement</label>
          <div className="p-inputgroup">
            <InputNumber
              id="excrementweight"
              value={sievingResult.excrementWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  excrementWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="skinweight">Skin</label>
          <div className="p-inputgroup">
            <InputNumber
              id="skinweight"
              value={sievingResult.skinWeight}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  skinWeight: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-1" />
        <div className="field col-2 ">
          <label htmlFor="residualFood">Residual food</label>
          <div className="p-inputgroup">
            <InputNumber
              id="residualFood"
              value={sievingResult.residualFood}
              onChange={(e) =>
                setSievingResult({
                  ...sievingResult,
                  residualFood: Math.max(e.value, 0),
                })
              }
              min={0}
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <Button
          onClick={() => {
            submitResults();
          }}
          icon="pi pi-check"
          className="p-button-rounded p-button-success  p-button-outlined "
          label="Save"
          disabled={
            !(
              sievingResult.insectWeight ||
              sievingResult.smallInsectWeight ||
              sievingResult.puppetsWeight ||
              sievingResult.beetlesWeight ||
              sievingResult.excrementWeight ||
              sievingResult.skinWeight ||
              sievingResult.residualFood
            )
          }
        />
      </div>
    </Dialog>
  );
}

RecordWeightsModal.propTypes = {
  sievingId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default RecordWeightsModal;
