import axiosConfig from 'utils/axiosConfig';
import { ToastSeverity } from 'utils/toastContextWrapper';

async function getBoxIdsFromCharges(chargeIds, toast) {
  try {
    const boxAndChargeIds = await axiosConfig
      .get('/boxLogistics/boxesForCharges', {
        params: {
          chargeIds,
        },
      })
      .then((res) => res.data);
    return boxAndChargeIds;
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: error.response.status + error.response.statusText,
    });
    return null;
  }
}

async function getBoxIdsFromBatchIds(batchIds, toast) {
  try {
    const boxAndChargeIds = await axiosConfig
      .get('/boxLogistics/boxesForBatches', {
        params: {
          batchIds,
        },
      })
      .then((res) => res.data);
    return boxAndChargeIds;
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: error.response.status + error.response.statusText,
    });
    return null;
  }
}

export { getBoxIdsFromCharges, getBoxIdsFromBatchIds };
