import PropTypes from 'prop-types';
import { useState, useEffect, useContext, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import BoxQRScan from 'elements/BoxQRScan';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

import AuthContext from 'store/auth-context';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
// import { getBoxIdsFromBatchIds } from 'utils/boxUtils';

function Inspection({ task, taskBatches }) {
  const toast = useContext(ToastContext);
  const navigate = useNavigate();

  const [comment, setComment] = useState('');
  const [selectedInspectionPlan, setSelectedInspectionPlan] = useState(null);
  const [inspectionList, setInspectionList] = useState([]);
  const [loadingInspectionList, setLoadingInspectionList] = useState(true);
  const authContext = useContext(AuthContext);
  const [showDialog, setShowDialog] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [batchesTreatedUnequally, setBatchesTreatedUnequally] = useState([]);
  const [inspectionComplete, setInspectionComplete] = useState(false);
  const [columns, setColumns] = useState([
    { header: 'Select a inspection plan' },
  ]);
  const { user } = authContext;

  // const getInspectionProgressForBoxIds = async (boxIds, cb) => {
  //   try {
  //     await axiosConfig
  //       .get('/inspection/inspectionProgressForBoxIds', {
  //         params: { boxIds },
  //       })
  //       .then((res) => cb(res.data));
  //   } catch (error) {
  //     console.error(error);
  //     toast.pushToast({
  //       severity: ToastSeverity.ERROR,
  //       detail: 'Error while fetching inspection progress',
  //     });
  //   }
  // };

  useEffect(() => {
    setBoxes([]);
    (async () => {
      await getInspectionList();

      // get box ids from batch ids from the overview the get progress for box Ids
      // if (taskBatches?.length) {
      //   const boxIdsAndBatchIDs = await getBoxIdsFromBatchIds(
      //     taskBatches,
      //     toast
      //   );
      //   // if (boxIdsAndBatchIDs.length) {
      //   //   getInspectionProgressForBoxIds(
      //   //     boxIdsAndBatchIDs.map(({ boxId }) => boxId),
      //   //     // combine new boxes with batch Id of boxIdsAndBatchIDs
      //   //     (boxesTemp) =>
      //   //       updateInspectionProgress(
      //   //         boxesTemp,
      //   //         boxes && boxes.length ? boxes : [],
      //   //         selectedInspectionPlan,
      //   //         inspectionList
      //   //       )
      //   //   );
      //   // }
      // }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnequelBatches = useCallback(
    (boxesTemp) =>
      setBatchesTreatedUnequally(
        compareInspectionForBatch(
          boxesTemp && boxesTemp.length ? boxesTemp : []
        )
          .filter(({ equalTreatment }) => !equalTreatment)
          .map(({ batchId }) => batchId)
      ),
    []
  );

  const editorTemplate = useCallback(
    (options, inspectionTask) => {
      switch (inspectionTask.inspectionTaskType) {
        case 'Float':
          return (
            <div className="p-inputgroup">
              <InputNumber
                value={options.value}
                onValueChange={(e) => {
                  const boxesTemp = boxes;
                  boxesTemp[options.rowIndex][options.field] = e.value;
                  setBoxes(boxesTemp);
                  getUnequelBatches(boxesTemp);
                  checkForCompletion(
                    boxesTemp,
                    selectedInspectionPlan,
                    setInspectionComplete
                  );
                  options.editorCallback(e.value);
                }}
                locale="de-DE"
                minFractionDigits={1}
                maxFractionDigits={3}
                onFocus={handleFocus}
                mode="decimal"
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
              >
                {inspectionTask.inspectionTaskUnit}
              </span>
            </div>
          );
        case 'Integer':
          return (
            <div className="p-inputgroup">
              <InputNumber
                value={options.value}
                onValueChange={(e) => {
                  const boxesTemp = boxes;
                  boxesTemp[options.rowIndex][options.field] = e.value;
                  setBoxes(boxesTemp);
                  getUnequelBatches(boxesTemp);
                  checkForCompletion(
                    boxesTemp,
                    selectedInspectionPlan,
                    setInspectionComplete
                  );
                  options.editorCallback(e.value);
                }}
                locale="de-DE"
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
              >
                {inspectionTask.inspectionTaskUnit}
              </span>
            </div>
          );
        case 'Bool':
          return (
            <SelectButton
              value={options.value}
              options={['Passed', 'Failed']}
              onChange={(e) => {
                const boxesTemp = boxes;
                boxesTemp[options.rowIndex][options.field] = e.value;
                setBoxes(boxesTemp);
                getUnequelBatches(boxesTemp);
                checkForCompletion(
                  boxesTemp,
                  selectedInspectionPlan,
                  setInspectionComplete
                );
                options.editorCallback(e.value);
              }}
            />
          );
        default:
          return null;
      }
    },
    [boxes, getUnequelBatches, selectedInspectionPlan]
  );

  useEffect(() => {
    getUnequelBatches(boxes);
    checkForCompletion(boxes, selectedInspectionPlan, setInspectionComplete);
  }, [boxes, getUnequelBatches, selectedInspectionPlan]);

  useEffect(() => {
    setColumns(
      selectedInspectionPlan?.inspectionTasks?.map((inspectionTask) => ({
        key: inspectionTask.inspectionInspectionTaskId.toString(),
        field: inspectionTask.inspectionInspectionTaskId.toString(),
        header: inspectionTask.inspectionTaskName,
        body: (rowData) => valuePerBoxTemplate(rowData, inspectionTask),
        editor: (options) => editorTemplate(options, inspectionTask),
      })) || []
    );
  }, [editorTemplate, selectedInspectionPlan?.inspectionTasks]);

  const getInspectionList = async () => {
    try {
      const inspectionListTemp = await axiosConfig
        .get('/inspection/inspections')
        .then((res) =>
          Array.isArray(res.data)
            ? res.data.map((inspect) => ({
                label: inspect.inspectionName,
                value: {
                  ...inspect,
                  inspectionTasks: inspect.inspectionTasks.map(
                    (inspectionTask) => ({
                      ...inspectionTask,
                      value: null,
                    })
                  ),
                },
              }))
            : []
        );
      setInspectionList(inspectionListTemp);
      setLoadingInspectionList(false);

      const inspectionId =
        task?.find((attribute) => attribute.attribute === 'inspectionId')
          ?.value || null;
      if (inspectionId && inspectionListTemp?.length)
        setSelectedInspectionPlan(
          inspectionListTemp?.find(
            ({ value }) => value.inspectionId === inspectionId
          ).value
        );
      return inspectionListTemp;
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching inspection list',
      });
      return null;
    }
  };

  const sendTasks = async (
    boxesTemp,
    selectedInspectionPlanTemp,
    commentTemp,
    userTemp,
    complete
  ) => {
    try {
      await axiosConfig.post('/inspection/saveProgress', {
        boxes: boxesTemp,
        inspectionId: selectedInspectionPlanTemp.inspectionId,
        comment: commentTemp,
        email: userTemp.email,
        workStepId:
          task?.find((attribute) => attribute.attribute === 'workStepId')
            ?.value || null,
        complete,
      });
      setComment('');
      setBoxes([]);
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Completed tasks',
      });
      navigate('/overview');
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  const handleFocus = (event) => event.target.select();

  const valuePerBoxTemplate = (rowData, inspectionTask) => {
    switch (inspectionTask.inspectionTaskType) {
      case 'Float':
        return `${
          rowData[inspectionTask.inspectionInspectionTaskId] ||
          rowData[inspectionTask.inspectionInspectionTaskId] === 0
            ? `${rowData[
                inspectionTask.inspectionInspectionTaskId
              ].toLocaleString('de')} ${inspectionTask.inspectionTaskUnit}`
            : ' '
        }`;
      case 'Integer':
        return `${
          rowData[inspectionTask.inspectionInspectionTaskId] ||
          rowData[inspectionTask.inspectionInspectionTaskId] === 0
            ? `${rowData[inspectionTask.inspectionInspectionTaskId]} ${
                inspectionTask.inspectionTaskUnit
              }`
            : ' '
        }`;
      case 'Bool':
        return rowData[inspectionTask.inspectionInspectionTaskId] || ' ';
      default:
        return null;
    }
  };

  const compareInspectionForBatch = (boxesTemp) => {
    if (!boxesTemp.length) return [];
    // group by batch Id --> see if different from batch
    return boxesTemp.reduce((oldaccumulater, currentElement) => {
      const accumulater = [...oldaccumulater];
      const index = accumulater.findIndex(
        ({ batchId }) => batchId === currentElement.batchId
      );
      // remove keys with null/undefined or that batchId, chargeId, boxId that are not to be compared
      const keys = Object.keys(currentElement)
        .filter(
          (key) =>
            key !== 'batchId' &&
            key !== 'chargeId' &&
            key !== 'boxId' &&
            key !== 'status' &&
            key !== 'undefined' &&
            // check if value of key null or undefined (exception of zero)
            (currentElement[key] || currentElement[key] === 0)
        )
        // sort so all keys are comparable as a string
        .sort();
      if (index === -1)
        accumulater.push({
          batchId: currentElement.batchId,
          equalTreatment: true,
          keys,
        });
      else {
        // compare keys;
        accumulater[index].equalTreatment =
          JSON.stringify(keys) === JSON.stringify(accumulater[index].keys)
            ? accumulater[index].equalTreatment
            : false;
      }
      return accumulater;
    }, []);
  };

  const checkForCompletion = (boxesTemp, inspectionPlanTemp, cb) => {
    if (!boxesTemp.length || !inspectionPlanTemp?.inspectionTasks?.length)
      cb(false);
    // compare the length of the completed/filled out inspection tasks
    // with the length of task in the inspection plan
    const lengthInspectionPlan = inspectionPlanTemp?.inspectionTasks?.length;
    cb(
      !boxesTemp.some(
        (box) =>
          Object.keys(box).filter(
            (key) =>
              key !== 'batchId' &&
              key !== 'chargeId' &&
              key !== 'boxId' &&
              key !== 'status' &&
              key !== 'undefined' &&
              // check if value of key null or undefined (exception of zero)
              (box[key] || box[key] === 0)
          ).length !== lengthInspectionPlan
      )
    );
  };

  // const updateInspectionProgress = async (
  //   newBoxes,
  //   oldBoxes,
  //   inspectionPlanTemp,
  //   inspectionListTemp
  // ) => {
  //   const firstKey =
  //     newBoxes.length &&
  //     newBoxes
  //       .map((box) => Object.keys(box))
  //       .flat()
  //       .filter(
  //         (key) =>
  //           !['boxId', 'chargeId', 'batchId', 'status', 'undefined'].includes(
  //             key
  //           )
  //       )
  //       .at(0);
  //   // find inspection plan with key
  //   const inspectionPlanNew =
  //     inspectionListTemp.length &&
  //     firstKey &&
  //     inspectionListTemp.find(({ value }) =>
  //       value.inspectionTasks.some(
  //         ({ inspectionInspectionTaskId }) =>
  //           inspectionInspectionTaskId.toString() === firstKey
  //       )
  //     ).value;
  //   switch (true) {
  //     // if new boxes have no progress just update the list
  //     case !newBoxes.some(
  //       (box) =>
  //         Object.keys(box).filter(
  //           (key) =>
  //             key !== 'batchId' &&
  //             key !== 'chargeId' &&
  //             key !== 'boxId' &&
  //             key !== 'status' &&
  //             key !== 'undefined' &&
  //             // check if value of key null or undefined (exception of zero)
  //             (box[key] || box[key] === 0)
  //         ).length
  //     ):
  //       setBoxes(
  //         newBoxes.map((box) => ({
  //           ...box,
  //           ...(oldBoxes && oldBoxes.length
  //             ? oldBoxes.find(({ boxId }) => box.boxId === boxId) || {}
  //             : {}),
  //         }))
  //       );
  //       break;
  //     // else if the old boxes have progress the filter the progress keys for current inspectionInspectionTaskId
  //     case oldBoxes &&
  //       oldBoxes.length &&
  //       oldBoxes.some(
  //         (box) =>
  //           Object.keys(box).filter(
  //             (key) =>
  //               key !== 'batchId' &&
  //               key !== 'chargeId' &&
  //               key !== 'boxId' &&
  //               key !== 'status' &&
  //               key !== 'undefined' &&
  //               // check if value of key null or undefined (exception of zero)
  //               (box[key] || box[key] === 0)
  //           ).length
  //       ):
  //       setBoxes(
  //         newBoxes.map((box) => ({
  //           // filter keys that are inspection task or box Id
  //           ...Object.keys(box)
  //             .filter(
  //               (key) =>
  //                 key === 'boxId' ||
  //                 key === 'batchId' ||
  //                 key === 'chargeId' ||
  //                 (inspectionPlanTemp &&
  //                   inspectionPlanTemp.inspectionTasks.some(
  //                     ({ inspectionInspectionTaskId }) =>
  //                       inspectionInspectionTaskId.toString() === key
  //                   ))
  //             )
  //             .reduce(
  //               (cur, key) => Object.assign(cur, { [key]: box[key] }),
  //               {}
  //             ),
  //           ...(oldBoxes && oldBoxes.length
  //             ? oldBoxes.find(({ boxId }) => box.boxId === boxId) || {}
  //             : {}),
  //         }))
  //       );
  //       break;
  //     // else select inspectionPlan of new boxes and add to list
  //     default:
  //       // select inspection
  //       inspectionPlanNew && setSelectedInspectionPlan(inspectionPlanNew);
  //       // filter by key in new inspection
  //       setBoxes(
  //         newBoxes.map((box) =>
  //           // filter keys that are inspection task or box Id
  //           Object.keys(box)
  //             .filter(
  //               (key) =>
  //                 key === 'boxId' ||
  //                 (inspectionPlanNew &&
  //                   inspectionPlanNew.inspectionTasks.some(
  //                     ({ inspectionInspectionTaskId }) =>
  //                       inspectionInspectionTaskId.toString() === key
  //                   ))
  //             )
  //             .reduce((cur, key) => Object.assign(cur, { [key]: box[key] }), {})
  //         )
  //       );
  //   }
  // };

  return (
    <div className="main-card main-card-content ">
      {showDialog === 'box' && (
        <BoxQRScan
          onResult={(scanResult) => {
            // check if there is progress for the new box Ids
            // getInspectionProgressForBoxIds(
            //   scanResult.map(({ boxId }) => boxId),
            //   (boxesTemp) =>
            //     updateInspectionProgress(
            //       boxesTemp && boxesTemp.length
            //         ? boxesTemp.map((box) => ({
            //             ...box,
            //             ...(scanResult.find(
            //               ({ boxId }) => boxId === box.boxId
            //             ) || {}),
            //           }))
            //         : [],
            //       boxes && boxes.length ? boxes : [],
            //       selectedInspectionPlan,
            //       inspectionList && inspectionList.length ? inspectionList : []
            //     )
            // );
            setBoxes(
              scanResult.map((box) => ({
                ...box,
                ...(boxes && boxes.length
                  ? boxes.find(({ boxId }) => box.boxId === boxId) || {}
                  : {}),
              }))
            );
          }}
          onClose={() => {
            setShowDialog(null);
          }}
          display={Boolean(showDialog)}
          boxesShouldBeActive
          boxesShouldExist
          batchesShouldBeComplete
          scanModeList={['series', 'stack', 'batch']}
          itemListInit={boxes && boxes.length ? boxes : []}
        />
      )}
      <div className="p-fluid grid">
        <div className="col-6">
          <div className="mt-2 mb-5">
            <h2>Inspection</h2>
          </div>
        </div>
        <div
          className="col-6 grid grid-nogutter"
          style={{
            display: 'flex',
          }}
        >
          <div className="col-12" style={{ 'margin-right': '10px' }}>
            <Button
              label="Scan Boxes"
              icon="pi pi-qrcode"
              onClick={() => setShowDialog('box')}
              className="p-button-rounded p-button-success p-button-outlined"
            />
          </div>
        </div>
        <div className="col-6">
          <Dropdown
            value={selectedInspectionPlan}
            onChange={(e) => {
              setSelectedInspectionPlan(e.value);
              // reset inspection plan progress
              setBoxes(
                boxes.map(({ boxId, chargeId, batchId }) => ({
                  boxId,
                  chargeId,
                  batchId,
                }))
              );
            }}
            options={inspectionList}
            placeholder="Select Inspection..."
          />
        </div>
        {loadingInspectionList ? (
          <div className="col-1">
            <ProgressSpinner
              style={{ width: '40px', height: '40px' }}
              strokeWidth="5"
            />
          </div>
        ) : null}
        <div className="col-12">
          <DataTable value={boxes}>
            <Column key={0} field="boxId" header="Box ID" />
            {columns?.map(({ key, field, header, body, editor }) => (
              <Column
                key={key}
                field={field}
                header={header}
                body={body}
                editor={editor}
              />
            )) || null}
          </DataTable>
        </div>
        <label htmlFor="comment">Comment</label>
        <div className="field col-12">
          <InputTextarea
            id="comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            autoResize
          />
        </div>
        {batchesTreatedUnequally && batchesTreatedUnequally.length ? (
          <div className="col-12">
            <span style={{ color: 'orange' }}>
              Warning: Not all boxes in the batch
              {batchesTreatedUnequally.length === 1 ? null : 'es'} are treated
              equally!
            </span>
          </div>
        ) : null}
        <div className="field col-6 flex">
          <Button
            className="p-button-rounded p-button-success"
            label="Complete inspection with unfinished tasks"
            disabled={
              !(boxes && boxes.length) ||
              !selectedInspectionPlan ||
              inspectionComplete
            }
            onClick={() =>
              sendTasks(boxes, selectedInspectionPlan, comment, user, true)
            }
          />
        </div>
        <div className="field col-6 flex">
          <Button
            className="p-button-rounded p-button-success"
            label="Complete Inspection"
            disabled={!(boxes && boxes.length) || !inspectionComplete}
            onClick={() =>
              sendTasks(boxes, selectedInspectionPlan, comment, user, true)
            }
          />
        </div>
      </div>
    </div>
  );
}

Inspection.propTypes = {
  task: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  taskBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Inspection;
