import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function IngredientEdit({ data, display, onClose, onCompletion }) {
  const initItem = {
    ingredientId: '',
    ingredientName: '',
  };

  const [inComplete, setInComplete] = useState(true);

  const [item, setItem] = useState(data?.ingredientId ? data : initItem);

  const toast = useContext(ToastContext);

  useEffect(() => {
    setInComplete(false);
    if (!item.ingredientName) setInComplete(true);
  }, [item]);

  const submitIngredient = async (ingredient, _new = true) => {
    try {
      if (_new) {
        await axiosConfig.post('/foodLogistics/createIngredient', ingredient);
      } else {
        await axiosConfig.post('/foodLogistics/updateIngredient', ingredient);
      }
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${
          data?.ingredientId ? 'editing' : 'creating'
        } of ingredient!`,
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data?.ingredientId ? 'Edit' : 'Create'} Ingredient
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="ingredientId">Ingredient Id</label>
          <InputText
            value={item.ingredientId || 'To be created'}
            onChange={(e) => setItem({ ...item, ingredientId: e.target.value })}
            id="ingredientId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-6">
          <label htmlFor="ingredientName">Ingredient Name*</label>
          <InputText
            value={item.ingredientName}
            onChange={(e) =>
              setItem({ ...item, ingredientName: e.target.value })
            }
            id="ingredientName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data?.ingredientId ? 'Save' : 'Create ingredient'}`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitIngredient(item, !data?.ingredientId)}
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

IngredientEdit.propTypes = {
  data: PropTypes.objectOf({
    ingredientId: PropTypes.string,
    ingredientName: PropTypes.string,
    tenantId: PropTypes.string,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
};

export default IngredientEdit;
