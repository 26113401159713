/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
// import downloadPng from 'utils/downloadPng';

import { FaCarrot } from 'react-icons/fa';

import {
  downloadQRCode,
  generateBarcodePNG,
  generateQRCode,
} from 'elements/QRGeneration';
import FoodDisposeRest from './FoodDisposeRest';
import FoodCreate from './FoodCreate';
import FoodListCharges from './FoodListCharges';
import { FoodContext } from './FoodContext';

function FoodMain() {
  const toast = useContext(ToastContext);
  const [scope, setScope] = useState('scopeInStock');
  const [action, setAction] = useState();
  const [foodList, setFoodList] = useState([]);
  const [foodListRaw, setFoodListRaw] = useState([]);
  const [refreshFoodList, setRefreshFoodList] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const { setShow } = useContext(FoodContext);

  const scopeOptions = [
    { label: 'Only show food in stock', value: 'scopeInStock' },
    { label: 'Food exhausted < 30 Days ago', value: 30 },
    { label: 'Food exhausted < 60 Days ago', value: 60 },
    { label: 'Food exhausted < 90 Days ago', value: 90 },
    { label: 'Food exhausted < 180 Days ago', value: 180 },
    { label: 'Food exhausted < 365 Days ago', value: 365 },
  ];

  const currentAmountDecimal = (rowData) => {
    return rowData.currentAmount ? rowData.currentAmount.toFixed(2) : '0.00';
  };

  const amountDecimal = (rowData) => {
    return rowData.amount ? rowData.amount.toFixed(2) : '0.00';
  };

  const bestBeforeTemplate = (rowData) =>
    Math.floor(
      (new Date(
        parseInt(rowData.bestBefore.split('.')[2], 10),
        parseInt(rowData.bestBefore.split('.')[1], 10) - 1,
        parseInt(rowData.bestBefore.split('.')[0], 10)
      ) -
        new Date()) /
        (24 * 3600 * 1000)
    ) >= -1 ? (
      rowData.bestBefore
    ) : (
      <span style={{ color: 'red' }}>{rowData.bestBefore}</span>
    );

  const columns = [
    { header: 'Name', field: 'name' },
    { header: 'Food-Id', field: 'foodID', sortable: true },
    { header: 'Created', field: 'createdAt', sortable: true },
    {
      header: 'Best Before',
      field: 'bestBefore',
      body: bestBeforeTemplate,
      sortable: true,
    },
    {
      header: 'Current Amount [kg]',
      field: 'currentAmount',
      body: currentAmountDecimal,
      sortable: true,
    },
    { header: 'Recipe ID', field: 'recipeID' },
  ];

  const columnsIngredients = [
    { header: 'Delivery ID', field: 'deliveryID' },
    { header: 'Ingredient Type', field: 'ingredientType' },
    { header: 'Supplier', field: 'supplier' },
    { header: 'Amount', field: 'amount', body: amountDecimal },
  ];

  useEffect(() => {
    fetchFoodList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFoodList();
    setRefreshFoodList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFoodList]);

  useEffect(() => {
    const listTemp = [];
    if (scope !== 'scopeInStock') {
      foodListRaw.map((foodRaw) => {
        if (
          foodRaw.currentAmount === 0 &&
          (new Date() - new Date(foodRaw.lastFoodHistoryEntry)) /
            (1000 * 3600 * 24) <
            scope
        ) {
          listTemp.push(foodRaw);
        }
        return null;
      });
    } else {
      foodListRaw.map((foodRaw) =>
        foodRaw.currentAmount !== 0 ? listTemp.push(foodRaw) : null
      );
    }
    setFoodList(listTemp);
  }, [scope, foodListRaw]);

  const fetchFoodList = async () => {
    try {
      const foodListTemp = await axiosConfig
        .get('/foodLogistics/allFood')
        .then((res) => res.data);
      setFoodListRaw(foodListTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  const ingredientTable = (rowData) => {
    if (rowData === null) return <span>no data</span>;
    return (
      <div className="orders-subtable">
        <h6>Ingredients</h6>
        <DataTable value={rowData.ingredients}>
          {columnsIngredients.map(({ header, field, body }) => (
            <Column header={header} field={field} body={body || null} />
          ))}
        </DataTable>
      </div>
    );
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Dispose of the rest', value: 'disposeRest' },
        { label: 'List all served charges', value: 'listCharges' },
        { label: 'Save QR Code', value: 'downloadQr' },
        { label: 'Save Bacode', value: 'downloadBarcode' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'disposeRest':
        return (
          <FoodDisposeRest
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'listCharges':
        return (
          <FoodListCharges
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'create':
        return (
          <FoodCreate
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'downloadQr':
        generateQRCode(action.actionData?.foodID, (QRTemp) => {
          downloadQRCode(QRTemp, action.actionData.foodID);
          setAction(null);
          return null;
        });
        break;
      case 'downloadBarcode':
        generateBarcodePNG(action.actionData?.foodID, (barcode) => {
          downloadQRCode(barcode, action.actionData.foodID);
          setAction(null);
          return null;
        });
        break;
      default:
        return null;
    }
  };

  return (
    <div className="main-card main-card-content">
      {action !== null && actionDialog()}
      <div className="formgrid grid">
        <div className="field col-1 mt-2 mb-5">
          <Button
            className="p-button-rounded p-button-outlined p-button-secondary"
            icon="pi pi-arrow-left"
            onClick={() => setShow(null)}
          />
        </div>
        <div className="field col-11 mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaCarrot className="mr-3" /> Food
          </h2>
        </div>
        <div className="p-fluid field col-7">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create Food"
            onClick={() =>
              setAction({ actionValue: 'create', actionData: null })
            }
          />
        </div>
        <div className="p-fluid field col-5 align-items-right">
          <Dropdown
            value={scope}
            options={scopeOptions}
            onChange={(e) => setScope(e.value)}
          />
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage={`No food items found for the scope of ${scope}`}
            value={foodList}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={ingredientTable}
            dataKey="foodID"
            sortable
          >
            <Column expander style={{ width: '3em' }} />
            {columns.map(({ header, field, body, sortable }) => (
              <Column
                header={header}
                field={field}
                body={body || null}
                sortable={sortable || null}
              />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default FoodMain;
