import { useState, useEffect, useContext } from 'react';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import PropTypes from 'prop-types';
import axiosConfig from 'utils/axiosConfig';
import { CgSmartHomeWashMachine } from 'react-icons/cg';
import BoxQRScan from 'elements/BoxQRScan';
import { Button } from 'primereact/button';
import { CiPlay1 } from 'react-icons/ci';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AuthContext from 'store/auth-context';
import { useNavigate } from 'react-router-dom';

function SievingIndex({ task, taskBatches }) {
  const toast = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showBoxScanDialog, setShowBoxScanDialog] = useState(false);
  const [sievingProcesses, setSievingProcesses] = useState([]);

  async function fetchSievingProcesses() {
    try {
      const fetchedSievingProcesses = await axiosConfig
        .get('/sieving/getRunningSievingProcesses')
        .then((res) => res.data);
      setSievingProcesses(fetchedSievingProcesses);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  }

  async function startNewSievingProcess(batchId) {
    try {
      await axiosConfig
        .post('/sieving/startSievingProcess', {
          batchId,
          workStepId:
            task?.find((attribute) => attribute.attribute === 'workStepId')
              ?.value || null,
          email: user.email,
        })
        .then(() => fetchSievingProcesses());
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error starting new sieving process',
      });
    }
  }

  useEffect(() => {
    fetchSievingProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueButtonTemplate = (itemData) => {
    return (
      <Button
        icon={CiPlay1}
        label="Continue"
        className="  p-button-outlined p-button-success"
        onClick={() => {
          navigate({
            pathname: '/sievingDetails',
            search: `?id=${itemData.WorkItemSievingId}`,
          });
        }}
      />
    );
  };
  const sievingProcessesTableColumns = [
    { header: 'Process ID', field: 'WorkItemSievingId' },
    { header: 'Input Batch', field: 'InputBatch' },
    { header: 'Output Batch', field: 'OutputBatch' },
    { header: 'Comment', field: 'Comment' },
    { header: 'Action', body: continueButtonTemplate },
  ];

  return (
    <div className="main-card main-card-content">
      {showBoxScanDialog && (
        <BoxQRScan
          onResult={(scanResult) =>
            startNewSievingProcess(scanResult[0].batchId)
          }
          onClose={() => {
            setShowBoxScanDialog(false);
          }}
          display={showBoxScanDialog}
          boxesShouldBeActive
          boxesShouldExist
          scanModeList={['batch']}
          batchesShouldBeComplete
          notAllowedBatches={sievingProcesses.map(
            ({ InputBatch }) => InputBatch
          )}
        />
      )}
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          <CgSmartHomeWashMachine className="mr-3" /> Sieving2
        </h2>
        <div className="p-fluid grid">
          <div className="field col-4">
            <Button
              className="p-button-rounded p-button-outlined p-button-success"
              label="Start new sieving process"
              icon="pi pi-qrcode"
              onClick={() => setShowBoxScanDialog(true)}
            />
          </div>
          <div className="field col-12">
            <h5>Active Sieving Processes:</h5>

            <DataTable
              value={sievingProcesses}
              showGridlines
              scrollable
              size="small"
              scrollHeight="50vh"
              sortField="batchId"
              sortOrder={-1}
              editMode="cell"
            >
              {sievingProcessesTableColumns.map((column) => (
                <Column
                  key={column.key}
                  header={column.header ? column.header : ''}
                  field={column.field ? column.field : ''}
                  sortable={'sortable' in column ? column.sortable : false}
                  className="justify-content-center"
                  body={column.body ? column.body : null}
                />
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

SievingIndex.propTypes = {
  taskBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
  task: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};
export default SievingIndex;
