import { useState, useEffect, useContext, useCallback } from 'react';

import axiosConfig from 'utils/axiosConfig';

import { Button } from 'primereact/button';

import { SelectedStackPositionProvider } from 'pages/Stabling/SelectStackPositionContext';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { fetchResultsForMultipleKeys, onRowToggle } from 'utils/utils';
import { MdOutlineHouseSiding } from 'react-icons/md';
import { Dropdown } from 'primereact/dropdown';
import Area from './Area/Area';
import IngredientDelete from './IngredientDelete';
import IngredientEdit from './IngredientEdit';
import AreaEdit from './AreaEdit';
import { PositionEdit, fetchProductionPositionsForArea } from './PositionEdit';

function FarmSetup() {
  const toast = useContext(ToastContext);

  const [areas, setAreas] = useState([]);
  const [productionPositions, setProductionPositions] = useState({});
  const [loadingProductionPositions, setLoadingProductionPositions] = useState(
    []
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const [loadingAreas, setLoadingAreas] = useState([]);
  const [updateAreas, setUpdateAreas] = useState(false);

  const [ingredients, setIngredients] = useState([]);
  const [loadingIngredients, setLoadingIngredients] = useState(false);
  const [updateIngredients, setUpdateIngredients] = useState(false);
  const [action, setAction] = useState();

  const columnsAreas = [
    { header: 'Area ID', field: 'areaId' },
    { header: 'Position Row', field: 'areaPositionRow' },
    { header: 'Position Column', field: 'areaPositionColumn' },
    { header: 'Width', field: 'areaWidth' },
    { header: 'Length', field: 'areaLength' },
    { header: 'Layout Rows', field: 'areaLayoutRows' },
    { header: 'Layout Columns', field: 'areaLayoutColumns' },
  ];

  const columnsIngredients = [
    { header: 'Ingredient ID', field: 'IngredientID' },
    { header: 'Ingredient Name', field: 'Name' },
  ];

  const fetchAreas = useCallback(async () => {
    setLoadingAreas(true);
    try {
      await axiosConfig
        .get('/farmsetup/area')
        .then((res) => setAreas(res.data))
        .then(() => setLoadingAreas(false));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Areas',
      });
    }
  }, [setAreas, setLoadingAreas, toast]);

  useEffect(() => {
    fetchAreas().then(() =>
      fetchResultsForMultipleKeys(
        expandedRows.map(({ areaId }) => areaId),
        productionPositions,
        loadingProductionPositions,
        setLoadingProductionPositions,
        (areaId) => fetchProductionPositionsForArea(areaId, toast),
        setProductionPositions,
        'areaId'
      )
    );
    setUpdateAreas(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAreas]);

  const fetchIngredients = useCallback(async () => {
    setLoadingIngredients(true);
    try {
      await axiosConfig
        .get('/foodLogistics/ingredients', {
          params: { ingredientsOnly: true },
        })
        .then((res) => setIngredients(res.data))
        .then(() => setLoadingIngredients(false));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Ingredients',
      });
    }
  }, [setIngredients, setLoadingIngredients, toast]);

  useEffect(() => {
    fetchIngredients();
    setUpdateIngredients(false);
  }, [fetchIngredients, updateIngredients]);

  const rowExpansionTemplate = (rowData) => {
    return (
      <div
        className="productionSiteGrid"
        style={{
          gridTemplateColumns: `repeat(${1}, 1fr)`,
          gridTemplateRows: `repeat(${1}, 1fr)`,
        }}
      >
        {loadingProductionPositions.includes(rowData.areaId) ? (
          <h5>Loading</h5>
        ) : (
          <SelectedStackPositionProvider>
            <Area
              area={{
                AreaID: rowData.areaId,
                AreaLength: rowData.areaLength,
                AreaWidth: rowData.areaWidth,
                AreaLayoutRows: rowData.areaLayoutRows,
                AreaLayoutColumns: rowData.areaLayoutColumns,
                ProductionPositions: productionPositions[rowData.areaId]?.length
                  ? productionPositions[rowData.areaId].map(
                      ({ spotId, status, productionPositionId }) => ({
                        ProductionPositionID: productionPositionId,
                        SpotID: spotId,
                        Status: status,
                      })
                    )
                  : [],
              }}
              productionSiteLayout={{
                areaRows: 1,
                areaColumns: 1,
              }}
              key={rowData.areaId}
            />
          </SelectedStackPositionProvider>
        )}
      </div>
    );
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Edit', value: 'editIngredient' },
        { label: 'Delete', value: 'deleteIngredient' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const rowActionArea = (rowData) => (
    <Dropdown
      options={[{ label: 'Add Position', value: 'addPositions' }]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'createArea':
        return (
          <AreaEdit
            data={{}}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setUpdateAreas(true)}
          />
        );
      case 'addPositions':
        return (
          <PositionEdit
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setUpdateAreas(true)}
          />
        );
      case 'deleteIngredient':
        return (
          <IngredientDelete
            data={{
              ingredientId: action.actionData.IngredientID,
              ingredientName: action.actionData.Name,
            }}
            display={!!action}
            onClose={() => setAction()}
            onCompletion={() => setUpdateIngredients(true)}
          />
        );
      case 'editIngredient':
        return (
          <IngredientEdit
            data={{
              ingredientId: action.actionData.IngredientID,
              ingredientName: action.actionData.Name,
            }}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setUpdateIngredients(true)}
          />
        );
      case 'createIngredient':
        return (
          <IngredientEdit
            data={{}}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setUpdateIngredients(true)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-card main-card-content ">
      {action && actionDialog()}
      <div className="formGrid grid">
        <div className="field col-12">
          <h2 className="flex align-items-center">
            <MdOutlineHouseSiding className="mr-3" /> Farm Setup
          </h2>
        </div>
        <div className="field col-12">
          <div className="card-content ">
            <div className="formGrid grid">
              <div className="field col-12">
                <h3 className="flex align-items-center">
                  Area and Production Positions
                </h3>
              </div>
              <div className="col-12">
                <DataTable
                  emptyMessage="No areas found"
                  value={areas.length ? areas : []}
                  expandedRows={expandedRows}
                  onRowToggle={(e) =>
                    onRowToggle(
                      expandedRows,
                      e.data,
                      'areaId',
                      loadingProductionPositions,
                      productionPositions,
                      setLoadingProductionPositions,
                      (areaId) =>
                        fetchProductionPositionsForArea(areaId, toast),
                      setProductionPositions,
                      setExpandedRows
                    )
                  }
                  rowExpansionTemplate={rowExpansionTemplate}
                  loading={loadingAreas}
                >
                  <Column expander />
                  {columnsAreas.map(({ header, field, body }) => (
                    <Column
                      key={field}
                      header={header}
                      field={field}
                      body={body}
                    />
                  ))}
                  <Column header="Action" body={rowActionArea} />
                </DataTable>
              </div>
              <div className="p-fluid field col-12">
                <Button
                  label="Add Area"
                  className="p-button-success p-button-rounded"
                  onClick={() =>
                    setAction({
                      actionValue: 'createArea',
                      actionData: {},
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field col-12">
          <div className="card-content ">
            <div className="formGrid grid">
              <div className="field col-12">
                <h3 className="flex align-items-center">Ingredients</h3>
              </div>
              <div className="col-12">
                <DataTable value={ingredients} loading={loadingIngredients}>
                  {columnsIngredients.map(({ header, field, body }) => (
                    <Column
                      key={field}
                      header={header}
                      field={field}
                      body={body}
                    />
                  ))}
                  <Column header="Action" body={rowAction} />
                </DataTable>
              </div>
              <div className="p-fluid field col-12">
                <Button
                  label="Add Ingredient"
                  className="p-button-success p-button-rounded"
                  onClick={() =>
                    setAction({
                      actionValue: 'createIngredient',
                      actionData: {},
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FarmSetup;
