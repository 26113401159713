import axios from 'axios';
import axiosRetry from 'axios-retry';

// Check if the Server Base URL has been set, if not throw an Error

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});
axiosRetry(instance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});
// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common.Authorization = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request are run before the request
instance.interceptors.request.use(
  // Add tenant id from local storage to parameters of get request and data of post
  // these are separated for post since params included in the url are rejected by the backend
  (config) =>
    config.method === 'get'
      ? {
          ...config,
          params: config.params
            ? {
                ...config.params,
                tenantId: JSON.parse(localStorage?.getItem('user'))?.tenantId,
              }
            : { tenantId: JSON.parse(localStorage?.getItem('user'))?.tenantId },
        }
      : {
          ...config,
          data: config.data
            ? {
                ...config.data,
                tenantId: JSON.parse(localStorage?.getItem('user'))?.tenantId,
              }
            : { tenantId: JSON.parse(localStorage?.getItem('user'))?.tenantId },
        },
  (error) => Promise.reject(error)
);

export default instance;
