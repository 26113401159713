/* eslint-disable consistent-return */

import axiosConfig from 'utils/axiosConfig';
import { useState, useEffect, useContext } from 'react';
import { CgSmartHomeWashMachine } from 'react-icons/cg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BoxQRScan from 'elements/BoxQRScan';

import RecordWeightsModal from 'elements/RecordWeightsModal';
import SievingStablingDialog from 'pages/Sieving2/SievingStablingDialog';
import { ConfirmDialog } from 'primereact/confirmdialog';

function SievingDetails() {
  const toast = useContext(ToastContext);

  // const location = useLocation();
  const [queryParameters] = useSearchParams();
  const [processId] = useState(queryParameters.get('id'));
  const [sievingDetails, setSievingDetails] = useState({});
  const [showBoxScanModal, setShowBoxScanModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [comment, setComment] = useState('');
  const [commentChanged, setCommentChanged] = useState(false);
  const [showStablingDialog, setShowStablingDialog] = useState(false);
  const [loadingWeights, setLoadingWeights] = useState(false);
  const [remainingBoxes, setRemainingBoxes] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [weights, setWeights] = useState([
    { key: 'Total recorded' },
    { key: 'Stabled' },
    { key: 'Remaining for Stabling' },
  ]);
  const navigate = useNavigate();

  const saveComment = async () => {
    try {
      await axiosConfig.post('/sieving/saveComment', {
        sievingId: processId,
        comment,
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error saving comment',
      });
    }
  };

  const updateWeights = async () => {
    setLoadingWeights(true);
    return Promise.all([
      getTotalRecordedWeight(),
      getTotalStabledWeight(),
    ]).then(([sievedWeights, stabledWeights]) =>
      calculateRemainingWeight(sievedWeights, stabledWeights)
    );
  };

  const getNumberRemainingBoxes = async (sieving) => {
    try {
      await axiosConfig
        .get('/sieving/numberRemainingBoxes', {
          params: { processId, batchId: sieving.InputBatch },
        })
        .then((res) => setRemainingBoxes(res.data));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error getting number of remaining box',
      });
    }
  };

  const getProcessDetails = async () => {
    try {
      const sievingDetailsTemp = await axiosConfig
        .get('/sieving/getSievingProcessDetails', {
          params: { processId },
        })
        .then((res) => res.data);
      setSievingDetails(sievingDetailsTemp);
      setComment(sievingDetailsTemp.Comment);
      await getNumberRemainingBoxes(sievingDetailsTemp);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching sieving details',
      });
      return null;
    }
  };

  const getTotalRecordedWeight = async () => {
    try {
      const recordedWeights = await axiosConfig
        .get('/sieving/getRecordedWeightsTotal', {
          params: { processId: Number(processId) },
        })
        .then((res) => res.data);
      recordedWeights.key = 'Total recorded';
      return recordedWeights;
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recorded weights',
      });
      return null;
    }
  };

  const getTotalStabledWeight = async () => {
    try {
      const recordedWeights = await axiosConfig
        .get('/sieving/getTotalStabledWeight', {
          params: { processId },
        })
        .then((res) => res.data);
      recordedWeights.key = 'Stabled';
      return recordedWeights;
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching stabled weights',
      });
      return {};
    }
  };

  const numberOrZero = (value) => (typeof value === 'number' ? value : 0);

  const calculateRemainingWeight = (sievedWeights, stabledWeights) => {
    const weightsTemp = ['InsectWeight', 'SmallInsectWeight'].reduce(
      (agg, key) => ({
        ...agg,
        [key]:
          numberOrZero(sievedWeights[key]) - numberOrZero(stabledWeights[key]),
      }),
      {}
    );
    weightsTemp.key = 'Remaining for Stabling';
    setWeights([sievedWeights, stabledWeights, weightsTemp]);
    setLoadingWeights(false);
  };

  const processScannedInputBoxes = async (boxList) => {
    try {
      await axiosConfig.post('/sieving/inputBoxesToSievingProcess', {
        processId: Number(processId),
        boxes: boxList,
      });
      await getNumberRemainingBoxes(sievingDetails);
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successfully entered the selected boxes!',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error entering boxes',
      });
    }
  };

  const amountDecimal = (number) => {
    return number === 0 || !!number ? `${number.toFixed(3)} kg` : null;
  };

  useEffect(() => {
    getProcessDetails();
    updateWeights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = [
    {
      key: 'InsectWeight',
      field: 'InsectWeight',
      header: 'Insects',
      body: (rowData) => amountDecimal(rowData.InsectWeight),
    },
    {
      key: 'SmallInsectWeight',
      field: 'SmallInsectWeight',
      header: 'Small Insects',
      body: (rowData) => amountDecimal(rowData.SmallInsectWeight),
    },
    {
      key: 'PuppetsWeight',
      field: 'PuppetsWeight',
      header: 'Puppets',
      body: (rowData) => amountDecimal(rowData.PuppetsWeight),
    },
    {
      key: 'BeetlesWeight',
      field: 'BeetlesWeight',
      header: 'Beetles',
      body: (rowData) => amountDecimal(rowData.BeetlesWeight),
    },
    {
      key: 'SkinWeight',
      field: 'SkinWeight',
      header: 'Skins',
      body: (rowData) => amountDecimal(rowData.SkinWeight),
    },
    {
      key: 'ExcrementWeight',
      field: 'ExcrementWeight',
      header: 'Excrements',
      body: (rowData) => amountDecimal(rowData.ExcrementWeight),
    },
    {
      key: 'ResidualFood',
      field: 'ResidualFood',
      header: 'Residual Food',
      body: (rowData) => amountDecimal(rowData.ResidualFood),
    },
  ];

  const completeSieving = async () => {
    try {
      await axiosConfig.post('/sieving/complete', { processId });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Completed sieving',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error completing sieving',
      });
    }
  };

  return (
    <div className="main-card main-card-content">
      {showBoxScanModal && (
        <BoxQRScan
          onResult={(scanResult) => processScannedInputBoxes(scanResult)}
          onClose={() => {
            setShowBoxScanModal(false);
          }}
          display={showBoxScanModal}
          boxesShouldBeActive
          boxesShouldExist
          scanModeList={['series', 'stack', 'batch']}
          // List of every boxId located in each stack
          batchesShouldBeComplete={false}
          allowedBatches={[sievingDetails.InputBatch]}
        />
      )}
      {showResultsModal && (
        <RecordWeightsModal
          onComplete={() => updateWeights()}
          onClose={() => {
            setShowResultsModal(false);
          }}
          display={showResultsModal}
          sievingId={processId}
        />
      )}
      {showStablingDialog && (
        <SievingStablingDialog
          itemData={{
            batchId: sievingDetails.InputBatch,
            insectWeight: weights[2]?.InsectWeight || 0,
            smallInsectWeight: weights[2]?.SmallInsectWeight,
            workItemSievingId: Number(processId),
            outputBatchId: sievingDetails.OutputBatch,
          }}
          display={showStablingDialog}
          onResult={() => updateWeights()}
          onClose={() => {
            setShowStablingDialog(false);
          }}
        />
      )}
      {showConfirmDialog && (
        <ConfirmDialog
          header="Complete Sieving"
          icon={
            weights[2]?.SmallInsectWeight > 0 ||
            weights[2]?.InsectWeight > 0 ||
            remainingBoxes > 0
              ? 'pi pi-exclamation-triangle'
              : ''
          }
          visible={showConfirmDialog}
          onHide={() => setShowConfirmDialog(false)}
          message={`Complete the sieving and stabling process?${
            weights[2]?.SmallInsectWeight > 0 || weights[2]?.InsectWeight > 0
              ? ' Warning: Not all remaining insects have been stabled! '
              : ''
          }${
            remainingBoxes > 0
              ? ' Warning: Not all boxes have been sieved! '
              : ''
          }`}
          accept={() =>
            completeSieving().then(() =>
              navigate({
                pathname: '/sievingIndex',
              })
            )
          }
          acceptLabel="Complete Sieving Process"
        />
      )}
      <div className="mt-2 mb-5">
        <div className="p-fluid grid">
          <div className="col-12 field">
            <h2 className="flex align-items-center">
              <CgSmartHomeWashMachine className="mr-3" /> Sieving2
            </h2>
          </div>
        </div>
        <div className="p-fluid grid">
          <div className="col-3 field">
            <p>
              Input Batch: {sievingDetails.InputBatch}
              <br />
              Output Batch: {sievingDetails.OutputBatch}
              <br />
              Sieving Process Id: {processId}
              <br />
              Remaining Boxes: {remainingBoxes || 0}
            </p>

            <Button
              className="p-button-rounded p-button-success"
              label="Input boxes to machine"
              disabled={!remainingBoxes}
              onClick={() => {
                setShowBoxScanModal(true);
              }}
            />
          </div>
          <div className="col-offset-3 col-6 field">
            <p>Comment</p>
            <InputTextarea
              id="comment"
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
                setCommentChanged(true);
              }}
              autoResize
            />
            <div className="col-offset-8 col-4">
              <Button
                className="p-button-rounded p-button-success"
                label="Save comment"
                disabled={!commentChanged}
                onClick={() => {
                  saveComment();
                  setCommentChanged(false);
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <h5>Sieving Results:</h5>
            <div className="col-3">
              <Button
                className="p-button-rounded p-button-success"
                label="Record additional weights"
                onClick={() => {
                  setShowResultsModal(true);
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <DataTable value={weights} loading={loadingWeights}>
              <Column key={0} field="key" header=" " />
              {tableColumns?.map(({ key, field, header, body }) => (
                <Column key={key} field={field} header={header} body={body} />
              )) || null}
            </DataTable>
          </div>
          <div className="col-3">
            <Button
              className="p-button-rounded p-button-success"
              label="Stable insects"
              disabled={loadingWeights}
              onClick={() => setShowStablingDialog(true)}
            />
          </div>

          <div className="col-offset-8 col-4 field">
            <Button
              className="p-button-rounded p-button-success"
              label="Complete Sieving Process"
              onClick={() => setShowConfirmDialog(true)}
              style={{ marginBottom: '1rem' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SievingDetails;
