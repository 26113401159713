/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { createContext, useMemo, useState } from 'react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import axiosConfig from 'utils/axiosConfig';

const AuthContext = createContext({
  user: {},
  login: (token) => {},
  logout: () => {},
});

export function AuthContextProvider(props) {
  // check if there is a user object in local storage
  const localUser = localStorage.getItem('user');
  const [user, setUser] = useState(localUser ? JSON.parse(localUser) : null);

  // eslint-disable-next-line no-shadow
  const login = async (token) => {
    const {
      email,
      picture,
      given_name: givenName,
      family_name: familyName,
    } = jwt_decode(token);

    try {
      // check if the user is authorized, get permissions and roles by calling backend
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const res = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/auth/signin`,
        {
          method: 'POST',
          redirect: 'follow',
          credentials: 'include', // Don't forget to specify this if you need cookies
          headers,
        }
      ).then((r) => r.json());
      const {
        TenantID,
        Role: { Name: role, Permissions },
      } = res;

      const permissions = Permissions.map(
        (permission) => permission.Role_Permission.PermissionID
      );

      setUser({
        tenantId: TenantID,
        email,
        picture,
        givenName,
        familyName,
        permissions,
        role,
      });
      localStorage.setItem(
        'user',
        JSON.stringify({
          email,
          tenantId: TenantID,
          picture,
          givenName,
          familyName,
          permissions,
          role,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const logout = async () => {
    await axiosConfig.get('/auth/signout', {
      withCredentials: true,
    });
    setUser(null);
    localStorage.removeItem('user');
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export default AuthContext;
