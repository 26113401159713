import { useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';
import PropTypes from 'prop-types';

function IngredientDelete({ data, display, onClose, onCompletion }) {
  const [item] = useState(data);

  const toast = useContext(ToastContext);

  const deleteIngredient = async (ingredient) => {
    try {
      await axiosConfig.post('/foodLogistics/deleteIngredient', {
        ingredientId: ingredient.ingredientId,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful deletion of ingredient!`,
      });
      onCompletion();
      onClose();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h4 className="flex align-items-center">
          Confirm deletion of ingredient {item.ingredientName} (ID:{' '}
          {item.ingredientId})
        </h4>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label="Delete Ingredient"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => deleteIngredient(item)}
            className="p-button-success  p-button-rounded"
          />
        </div>
      </div>
    </Dialog>
  );
}

IngredientDelete.propTypes = {
  data: PropTypes.objectOf({
    ingredientId: PropTypes.string,
    ingredientName: PropTypes.string,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
};

export default IngredientDelete;
