import axiosConfig from 'utils/axiosConfig';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useState } from 'react';
import { FaUserTie } from 'react-icons/fa';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { formatDate } from 'pages/Supplier/Supplier';
import CustomerDelete from './CustomerDelete';
import CustomerEdit from './CustomerEdit';

function Customer() {
  const toast = useContext(ToastContext);
  const [customerList, setCustomerList] = useState([]);
  const [customerListRaw, setCustomerListRaw] = useState([]);
  const [action, setAction] = useState();
  const [refreshCustomerList, setRefreshCustomerList] = useState(false);

  const columns = [
    { header: 'Customer Name', field: 'customerName' },
    { header: 'Customer ID', field: 'customerId' },
    {
      header: 'Registered',
      field: 'createdAt',
      body: (rowData) => formatDate(new Date(rowData.createdAt)),
    },
    { header: 'Address', field: 'address' },
    { header: 'Category', field: 'category' },
  ];

  useEffect(() => {
    fetchCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCustomerList();
    setRefreshCustomerList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCustomerList]);

  const fetchCustomerList = async () => {
    try {
      const customersTemp = await axiosConfig
        .get('/customer/customers')
        .then((res) => res.data);
      if (customersTemp) {
        setCustomerListRaw(customersTemp);
        setCustomerList(
          customersTemp.filter((customer) => customer.status === 'active')
        );
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching customer list',
      });
    }
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Details/Edit', value: 'edit' },
        { label: 'Delete', value: 'delete' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'delete':
        return (
          <CustomerDelete
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshCustomerList(true)}
          />
        );
      case 'edit':
        return (
          <CustomerEdit
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshCustomerList(true)}
            List={customerListRaw}
          />
        );
      case 'create':
        return (
          <CustomerEdit
            data={null}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshCustomerList(true)}
            List={customerListRaw}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-card main-card-content">
      {action !== null && actionDialog()}

      <div className="formGrid grid">
        <div className="field col-12">
          <h2 className="flex align-items-center">
            <FaUserTie className="mr-3" /> Customers
          </h2>
        </div>
        <div className="p-fluid field col-12">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create new customer"
            onClick={() => {
              setAction({ actionValue: 'create', actionData: null });
            }}
          />
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage="No customers found"
            value={customerList}
            responsiveLayout="scroll"
            dataKey="customerId"
          >
            {columns.map(({ header, field, body }) => (
              <Column header={header} field={field} body={body} />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default Customer;
