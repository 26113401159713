import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function UserEdit({ data, display, onClose, onCompletion }) {
  const initItem = {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    roleId: null,
    tenantId: '',
  };

  const [inComplete, setInComplete] = useState(true);

  const [loadingRoles, setLoadingRoles] = useState(false);

  const [roles, setRoles] = useState([]);

  const [item, setItem] = useState(data || initItem);

  const toast = useContext(ToastContext);

  useEffect(() => {
    setInComplete(false);
    if (!item.tenantId || !item.roleId || !item.email) setInComplete(true);
  }, [item]);

  const fetchRoles = useCallback(async () => {
    setLoadingRoles(true);
    try {
      await axiosConfig
        .get('/user/roles')
        .then((res) =>
          setRoles(
            res.data.length
              ? res.data.map(({ roleId, name }) => ({
                  value: roleId,
                  label: name,
                }))
              : []
          )
        )
        .then(() => setLoadingRoles(false));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching roles',
      });
    }
  }, [setLoadingRoles, setRoles, toast]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const submitUser = async (user, _new = true) => {
    try {
      if (_new) {
        await axiosConfig.post('/user/create', {
          ...user,
          userId: user.userId,
          tenantIdForUser: user.tenantId,
        });
      } else {
        await axiosConfig.post('/user/update', {
          ...user,
          userId: user.userId,
          tenantIdForUser: user.tenantId,
        });
      }
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${data ? 'editing' : 'creating'} of user!`,
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data ? 'Edit' : 'Create'} User
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="userId">User Id</label>
          <InputText
            value={item.userId || 'To be created'}
            onChange={(e) => setItem({ ...item, userId: e.target.value })}
            id="userId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-6">
          <label htmlFor="role">Role*</label>
          <Dropdown
            value={item.roleId}
            loading={loadingRoles}
            options={roles}
            onChange={(e) => setItem({ ...item, roleId: e.target.value })}
            id="role"
            placeholder="Select Role..."
            type="text"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="tenantId">TenantID*</label>
          <InputText
            value={item.tenantId}
            onChange={(e) =>
              setItem({
                ...item,
                tenantId: e.target.value.substring(0, 50),
              })
            }
            id="tenantId"
            type="text"
          />
        </div>
        <div className="field col-6" />
        <div className="field col-12">
          <hr />
        </div>
        <div className="field col-6">
          <label htmlFor="firstName">First Name</label>
          <InputText
            value={item.firstName}
            onChange={(e) => setItem({ ...item, firstName: e.target.value })}
            id="firstName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="lastName">Last Name</label>
          <InputText
            value={item.lastName}
            onChange={(e) => setItem({ ...item, lastName: e.target.value })}
            id="lastName"
            type="text"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="email">E-Mail* - Needs to be unique</label>
          <InputText
            value={item.email}
            onChange={(e) => setItem({ ...item, email: e.target.value })}
            id="email"
            type="text"
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data ? 'Save' : 'Create user'}`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitUser(item, !data)}
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

UserEdit.propTypes = {
  data: PropTypes.objectOf({
    userId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    roleId: PropTypes.number,
    tenantId: PropTypes.string,
  }).isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompletion: PropTypes.func.isRequired,
};

export default UserEdit;
