/*
Unterseite "Stabling", hier findet das Tracking bei der Einstallung statt
Diese Seite wird gerenderd
*/
import PropTypes from 'prop-types';
import { useState, useEffect, useContext, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { checkInputMissingExceptCommentAndWorkStepId } from 'utils/utils';
import BoxQRScan from 'elements/BoxQRScan';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';

import { RiInboxArchiveLine, RiSaveLine } from 'react-icons/ri';
import { getBoxIdsFromBatchIds } from 'utils/boxUtils';

import { CgSmartHomeWashMachine } from 'react-icons/cg';
import { Chip } from 'primereact/chip';
import AuthContext from 'store/auth-context';
import SievingSaveResultsDialog from './SievingSaveResultsDialog';
import SievingStablingDialog from './SievingStablingDialog';

function Sieving({ task, taskBatches }) {
  const toast = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDialog, setShowDialog] = useState('');
  const [dialogData, setDialogData] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [activeSievingProcessesList, setActiveSievingProcessesList] = useState(
    []
  );
  const [savedSievingProcessesList, setSavedSievingProcessesList] = useState(
    []
  );

  const [machineList, setMachineList] = useState([]);

  const [confirmation, setConfirmation] = useState(false);

  const [item, setItem] = useState({
    machineId: '',
    comment: '',
  });

  const fetchActiveSievingProcesses = useCallback(async () => {
    try {
      const activeSievingProcesses = await axiosConfig
        .get('/sieving/statusTable/active', {
          params: {
            status: 'active',
          },
        })
        .then((res) => res.data);

      setActiveSievingProcessesList(activeSievingProcesses);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  }, [setActiveSievingProcessesList, toast]);

  const fetchSavedSievingProcesses = useCallback(async () => {
    try {
      // data has fairly complicated structure: [{WorkItemSievingProcessIDSievingMachineID, Status, WorkItemSieviingInput1, createdAt}]
      // should be made into multiple backend routes, destructuring in frontend is not nice
      const savedSievingProcesses = await axiosConfig
        .get('/sieving/statusTable/saved', {
          params: {
            status: 'saved',
          },
        })
        .then((res) => res.data);
      // filter for only one element per WorkItemSievingProcessID

      setSavedSievingProcessesList(savedSievingProcesses);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  }, [toast]);

  useEffect(() => {
    const fetchMachineList = async () => {
      try {
        const machineListJSON = await axiosConfig
          .get('/sieving/machines')
          .then((res) => res.data);

        setMachineList(machineListJSON);
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching Food List',
        });
      }
    };

    fetchMachineList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    fetchActiveSievingProcesses();
    fetchSavedSievingProcesses();
  }, [
    activeIndex,
    fetchActiveSievingProcesses,
    fetchSavedSievingProcesses,
    showDialog,
    toast,
  ]);

  useEffect(() => {
    if (taskBatches?.length) {
      (async () => {
        const boxesTemp = await getBoxIdsFromBatchIds(taskBatches, toast);
        setBoxes(boxesTemp);
      })();
    }

    fetchSavedSievingProcesses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTemplate = (itemData) => {
    return (
      <Button
        icon={RiSaveLine}
        className="  p-button-outlined p-button-success"
        onClick={() => {
          setDialogData({ ...itemData, user });
          setShowDialog('save');
        }}
      />
    );
  };

  const stableTemplate = (itemData) => {
    return (
      <Button
        icon={RiInboxArchiveLine}
        className="  p-button-outlined p-button-success"
        onClick={() => {
          setDialogData({ ...itemData, user });
          setShowDialog('stabling');
        }}
      />
    );
  };

  const activeSievingProcessesTableColumns = [
    { header: 'Process ID', field: 'processId' },
    { header: 'Machine', field: 'machineName' },
    { header: 'User', field: 'userName' },
    { header: 'Day', field: 'day' },
    { header: 'Start Time', field: 'time' },
    { header: 'Record Results', body: saveTemplate },
  ];

  const savedSievingProcessesTableColumns = [
    { header: 'Process ID', field: 'processId' },
    { header: 'User', field: 'userName' },
    { header: 'Day', field: 'day' },
    { header: 'Save Time', field: 'time' },
    { header: 'Stable Results', body: stableTemplate },
  ];

  const submitList = async () => {
    try {
      await axiosConfig.post('/sieving/input', {
        boxes,
        sievingMachineId: item.machineId,
        comment: item.comment,
        email: user.email,
        workStepId:
          task?.find((attribute) => attribute.attribute === 'workStepId')
            ?.value || null,
      });

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Sieving Process Start!',
      });

      setBoxes([]);
      setActiveIndex(1);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while starting Sieving Process',
      });
    }
  };

  const selectedMachineTemplate = (option) => {
    if (option) {
      return <span>{option.Name}</span>;
    }

    return 'Select Machine';
  };

  const machineOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.Name}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const confirmationDialog = (confirmationTemp) => (
    <ConfirmDialog
      visible={confirmationTemp}
      onHide={() => setConfirmation(false)}
      message="Start Sieving?"
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
      accept={() => submitList()}
    />
  );

  const firstPage = () => {
    return (
      <div className="formgrid p-fluid grid  align-items-end">
        <div className="field col-12 mt-2">
          <div className="grid grid-nogutter">
            <div className=" col-4 col-offset-4 ">
              <Button
                className="p-button-rounded p-button-outlined "
                label="Scan Boxes"
                icon="pi pi-qrcode"
                onClick={() => setShowDialog('box')}
              />
            </div>
          </div>
        </div>
        {boxes?.length ? (
          <div className="col-12">
            {boxes.map((box) => (
              <Chip
                style={{
                  marginRight: '10px',
                  marginTop: '5px',
                }}
                label={box.boxId}
              />
            ))}
          </div>
        ) : null}
        <div className="field col-3" style={{ marginTop: '1rem' }}>
          <label htmlFor="machineid">Sieving Machine</label>

          <Dropdown
            value={item.machineId}
            id="machineid"
            options={machineList}
            onChange={(e) => setItem({ ...item, machineId: e.target.value })}
            placeholder
            optionLabel="Name"
            optionValue="SievingMachineID"
            valueTemplate={selectedMachineTemplate}
            itemTemplate={machineOptionTemplate}
            optionDisabled={(option) => option.Status !== 'free'}
          />
        </div>
        <div className="field col-1" />
        <div className=" field col-4">
          <label htmlFor="name1">Comment</label>
          <InputText
            id="name1"
            type="text"
            value={item.comment}
            // add a symbol depending if the comment is empty or not
            onChange={(e) =>
              setItem({
                ...item,
                comment: e.target.value,
              })
            }
          />
        </div>

        <div className="field col-4 col-offset-4 mt-2">
          <Button
            label="Start Sieving"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => setConfirmation(true)}
            className="p-button-success  p-button-rounded mr-2 mb-2"
            // check if food and food weight are filled for every box
            disabled={
              boxes.some((box) =>
                checkInputMissingExceptCommentAndWorkStepId(box)
              ) ||
              !boxes.length ||
              !item.machineId
            }
          />
        </div>
      </div>
    );
  };

  const secondPage = () => {
    return (
      <div className="formgrid grid justify-content-center">
        <div className="field col-12">
          <DataTable
            value={activeSievingProcessesList}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="boxId"
            sortOrder={-1}
            editMode="cell"
          >
            {activeSievingProcessesTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                // className={column.center ? 'justify-content-center' : ''}
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
      </div>
    );
  };

  const thirdPage = () => {
    return (
      <div className="formgrid grid justify-content-center">
        <div className="field col-12">
          <DataTable
            value={savedSievingProcessesList}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="boxId"
            sortOrder={-1}
            editMode="cell"
          >
            {savedSievingProcessesTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                // className={column.center ? 'justify-content-center' : ''}
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
      </div>
    );
  };

  // RENDER
  return (
    <div className="main-card main-card-content">
      {confirmation && confirmationDialog(confirmation)}
      {showDialog === 'box' && (
        <BoxQRScan
          itemListInit={boxes}
          onResult={(scanResult) => setBoxes(scanResult)}
          onClose={() => {
            setShowDialog('');
          }}
          display={showDialog === 'box'}
          boxesShouldBeActive
          boxesShouldExist
          scanModeList={['series', 'stack', 'batch']}
          batchesShouldBeComplete
        />
      )}
      {showDialog === 'stabling' && dialogData && (
        <SievingStablingDialog
          itemData={dialogData}
          display={Boolean(showDialog && dialogData)}
          onResult={() => {
            fetchSavedSievingProcesses();
            setActiveIndex(2);
          }}
          onClose={() => {
            setDialogData(null);
            setShowDialog('');
          }}
        />
      )}
      {showDialog === 'save' && dialogData && (
        <SievingSaveResultsDialog
          itemData={dialogData}
          display={Boolean(showDialog && dialogData)}
          onResult={() => {
            setDialogData(null);
            setActiveIndex(2);
          }}
          onClose={() => setShowDialog('')}
        />
      )}
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          <CgSmartHomeWashMachine className="mr-3" /> Sieving
        </h2>
      </div>
      <Accordion
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <AccordionTab header="Start Sieving Process">
          {firstPage()}
        </AccordionTab>
        <AccordionTab
          header="Enter Sieving Results"
          disabled={!activeSievingProcessesList.length}
        >
          {secondPage()}
        </AccordionTab>
        <AccordionTab
          header="Stable Sieving Results"
          disabled={!savedSievingProcessesList.length}
        >
          {thirdPage()}
        </AccordionTab>
      </Accordion>
    </div>
  );
}

Sieving.propTypes = {
  taskBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
  task: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
};

export default Sieving;
