/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';
import { Dropdown } from 'primereact/dropdown';
import { COUNTRYLIST } from 'utils/Constants';

function CustomerEdit({ data, display, onClose, onCompletion, List }) {
  const initItem = {
    customerName: '',
    customerId: null,
    category: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    notes: '',
    createdAt: new Date(),
    status: 'active',
  };

  const [inComplete, setInComplete] = useState(true);

  const [item, setItem] = useState(data || initItem);

  const toast = useContext(ToastContext);

  useEffect(() => {
    setInComplete(false);
    if (!item.customerName || !item.lastName || !item.email || !item.city)
      setInComplete(true);
  }, [item]);

  const submitCustomer = async (customer) => {
    try {
      axiosConfig.post('/customer/customer', {
        ...customer,
        customerId: customer.customerId
          ? parseInt(customer.customerId.substring(3), 10)
          : null,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${data ? 'editing' : 'creating'} of customer!`,
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data ? 'Edit' : 'Create'} Customer
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="customerName">Customer Name*</label>
          <InputText
            value={item.customerName}
            onChange={(e) =>
              setItem({
                ...item,
                customerName: e.target.value.substring(0, 50),
              })
            }
            id="customerName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="customerId">Customer Id</label>
          <InputText
            value={item.customerId || 'To be created'}
            onChange={(e) => setItem({ ...item, customerId: e.target.value })}
            id="customerId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-6" />
        <div className="field col-12">
          <hr />
        </div>
        <div className="field col-6">
          <label htmlFor="firstName">First Name</label>
          <InputText
            value={item.firstName}
            onChange={(e) => setItem({ ...item, firstName: e.target.value })}
            id="firstName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="lastName">Last Name*</label>
          <InputText
            value={item.lastName}
            onChange={(e) => setItem({ ...item, lastName: e.target.value })}
            id="lastName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="email">E-Mail*</label>
          <InputText
            value={item.email}
            onChange={(e) => setItem({ ...item, email: e.target.value })}
            id="email"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="email">Telephone</label>
          <InputText
            value={item.telephone}
            onChange={(e) => setItem({ ...item, telephone: e.target.value })}
            id="telephone"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="streetName">Street Name</label>
          <InputText
            value={item.streetName}
            onChange={(e) => setItem({ ...item, streetName: e.target.value })}
            id="streetName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="streetNumber">Number</label>
          <InputText
            value={item.streetNumber}
            onChange={(e) => setItem({ ...item, streetNumber: e.target.value })}
            id="streetNumber"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="postalCode">Postal Code</label>
          <InputText
            value={item.postalCode}
            onChange={(e) => setItem({ ...item, postalCode: e.target.value })}
            id="postalCode"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="city">City*</label>
          <InputText
            value={item.city}
            onChange={(e) => setItem({ ...item, city: e.target.value })}
            id="city"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="country">Country</label>
          <Dropdown
            value={item.country}
            options={COUNTRYLIST}
            onChange={(e) => setItem({ ...item, country: e.target.value })}
            id="country"
            optionLabel="label"
            filter
            showClear
            filterBy="label"
            placeholder="Select a Country"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="notes">Notes</label>
          <InputTextarea
            rows={5}
            value={item.notes}
            onChange={(e) =>
              setItem({
                ...item,
                notes: e.target.value.substring(0, 500),
              })
            }
            autoResize
            id="notes"
            type="text"
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data ? 'Edit' : 'Create'} customer`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitCustomer(item)}
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default CustomerEdit;
