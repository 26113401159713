/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';

import { Calendar } from 'primereact/calendar';
import { checkInputMissingExceptCommentAndWorkStepId } from 'utils/utils';
import {
  DownloadQrCodeTemplate,
  DownloadBarcodeTemplate,
} from 'elements/QRGeneration';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { formatDate } from 'pages/Supplier/Supplier';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function EggLogistics() {
  const toast = useContext(ToastContext);

  const [itemList, setItemList] = useState(null);

  const [visible, setVisible] = useState(false);

  const [loadingEggInfo, setLoadingEggInfo] = useState(false);

  const [eggInformation, setEggInformation] = useState([]);
  const [supplierList, setSupplierList] = useState(true);
  const [item, setItem] = useState({
    productionDate: new Date(),
    deliveryDate: new Date(),
    deliveryAmount: 0,
    bestBefore: new Date(),
    comment: '',
    supplierId: '',
  });

  const fetchTableData = async () => {
    try {
      const tableDataJSON = await axiosConfig.get('/eggLogistics/table');
      setItemList(tableDataJSON.data);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  };

  const fetchSupplierList = async () => {
    try {
      const suppliersTemp = await axiosConfig
        .get('/supplier/suppliers', {
          params: {
            category: 'Egg',
          },
        })
        .then((res) => res.data);
      if (Array.isArray(suppliersTemp)) {
        const suppliers = suppliersTemp.filter(
          (supplier) => supplier.status === 'active'
        );
        setSupplierList(
          suppliers.map(({ supplierId, supplierName }) => ({
            label: supplierName,
            value: supplierId,
          }))
        );
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching suppliers List',
      });
    }
  };
  useEffect(() => {
    fetchTableData();
    fetchSupplierList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = (event) => event.target.select();

  // can be made into one-liner!
  const resetForm = () => {
    setItem({
      ...item,
      productionDate: new Date(),
      deliveryDate: new Date(),
      bestBefore: new Date(),
      deliveryAmount: 0,
      comment: '',
      supplierId: '',
    });
  };

  const sendEgg = async () => {
    try {
      await axiosConfig.post('/eggLogistics/create', {
        ...item,
      });

      resetForm();
      fetchTableData();

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Egg delivery created',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`charge-badge charge-badge-${rowData.Status}`}>
        {rowData.Status}
      </span>
    );
  };

  const fetchEggInformation = async (eggId) => {
    setLoadingEggInfo(eggId);
    return axiosConfig
      .get(`/eggLogistics/eggInformation`, { params: { eggId } })
      .then((res) => {
        setLoadingEggInfo(false);
        setEggInformation({
          eggId,
          array: [
            {
              label: 'Best Before',
              value: formatDate(new Date(res.data.bestBefore)),
            },
            {
              label: 'Supplier',
              value: res.data.supplierId
                ? addPrefix(res.data.supplierId, 'SP', 5)
                : '',
            },
            {
              label: 'Delivery Date',
              value: formatDate(new Date(res.data.deliveryDate)),
            },
            {
              label: 'Production Date',
              value: formatDate(new Date(res.data.productionDate)),
            },
            {
              label: 'Comment',
              value: res.data.comment,
            },
          ],
        });
      });
  };

  const eggInformationDialog = () => (
    <Dialog
      header={`Egg Information for ${eggInformation.eggId}`}
      visible={visible === 'eggInformation' && !loadingEggInfo}
      onHide={() => setVisible(false)}
    >
      <DataTable value={!loadingEggInfo ? eggInformation.array : []}>
        <Column key="label" field="label" />
        <Column key="value" field="value" />
      </DataTable>
    </Dialog>
  );

  const showEggInformation = (eggId) => {
    return (
      <Button
        icon="pi pi-ellipsis-v"
        className="p-button-text mr-2 "
        text
        loading={loadingEggInfo === eggId}
        onClick={() =>
          fetchEggInformation(eggId).then(setVisible('eggInformation'))
        }
      />
    );
  };

  return (
    <div className="main-card main-card-content ">
      {visible === 'eggInformation' && eggInformationDialog()}
      <div className="mt-2 mb-5">
        <h2>Egg Logistics</h2>
      </div>
      <div className="grid ">
        <div className="col-12">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Create new Egg Delivery</h3>
            </div>
            <div className="grid">
              <div className="col-12">
                <div className="formgrid grid align-items-end">
                  <div className="field  col-6">
                    <label htmlFor="productionDate">Egg Id</label>
                    <InputMask
                      mask="EG-99999"
                      // value={item?.eggId}
                      type="text"
                      placeholder="EG-XXXXX"
                      onChange={(e) => setItem({ ...item })}
                      keyfilter="pint"
                      // disabled
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="deliverydate">Delivery Date</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.deliveryDate}
                      onChange={(e) =>
                        setItem({ ...item, deliveryDate: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>

                  <div className="field col-6">
                    <label htmlFor="supplierName">Supplier Name</label>
                    <Dropdown
                      id="supplierName"
                      value={item.supplierId}
                      options={
                        Array.isArray(supplierList) ? supplierList : null
                      }
                      onChange={(e) => {
                        setItem({ ...item, supplierId: e.value });
                      }}
                    />
                  </div>

                  <div className="field col-6">
                    <label htmlFor="productionDate">Production Date</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.productionDate}
                      onChange={(e) =>
                        setItem({ ...item, productionDate: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="bestBefore">Best Before</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.bestBefore}
                      onChange={(e) =>
                        setItem({ ...item, bestBefore: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="deliveryamount">Delivery Amount</label>
                    <div className="p-inputgroup">
                      <InputNumber
                        value={item.deliveryAmount}
                        id="deliveryamount"
                        type="text"
                        onChange={(e) =>
                          setItem({ ...item, deliveryAmount: e.value })
                        }
                        mode="decimal"
                        locale="de-DE"
                        minFractionDigits={1}
                        onFocus={handleFocus}
                      />
                      <span
                        style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                        className="p-inputgroup-addon"
                        title="Weight"
                      >
                        kg
                      </span>
                    </div>
                  </div>
                  <div className="field col-12">
                    <label htmlFor="comment">Comment</label>
                    <InputTextarea
                      autoResize
                      value={item.comment}
                      id="comment"
                      type="text"
                      onChange={(e) =>
                        setItem({ ...item, comment: e.target.value })
                      }
                      col={12}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <Button
                  className="p-button-success p-button-rounded"
                  label="Create egg delivery"
                  onClick={() => sendEgg().then(() => resetForm())}
                  disabled={checkInputMissingExceptCommentAndWorkStepId(item)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Egg Overview</h3>
            </div>

            <DataTable
              value={itemList}
              showGridlines
              scrollable
              scrollHeight="50vh"
            >
              <Column field="eggId" header="Egg ID" sortable />
              <Column
                field="remainingAmount"
                header="Remaining Amount [kg]"
                style={{ maxWidth: '12rem' }}
                sortable
              />
              <Column
                field="Status"
                header="Status"
                body={statusBodyTemplate}
                sortable
              />
              <Column
                header="QR"
                body={(rowData) => DownloadQrCodeTemplate(rowData.eggId)}
              />
              <Column
                header="Barcode"
                body={(rowData) => DownloadBarcodeTemplate(rowData.eggId)}
              />
              <Column
                header="Info"
                body={(rowData) => showEggInformation(rowData.eggId)}
              />
            </DataTable>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default EggLogistics;
