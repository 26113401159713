/* eslint-disable react/prop-types */
/*
Unterseite "Stabling", hier findet das Tracking bei der Einstallung statt
Diese Seite wird gerenderd
*/

import axiosConfig from 'utils/axiosConfig';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Button } from 'primereact/button';
import PositionDialog from 'elements/PositionDialog';
import PackagingEdit from 'pages/Packaging/PackagingEdit';
import { Dropdown } from 'primereact/dropdown';
import { formatDate } from 'pages/Supplier/Supplier';

function Overview({ setTask, setTaskBatches }) {
  const toast = useContext(ToastContext);
  // STATES workStepBatches
  const [showDialog, setShowDialog] = useState('');
  const [selectedStacks, setSelectedStacks] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [workStepBatches, setWorkStepBatches] = useState([]);
  // defined one by one to prevent overwriting each other when loading
  const [workStepBatchesInspection, setWorkStepBatchesInspection] = useState(
    []
  );
  const [workStepBatchesFeeding, setWorkStepBatchesFeeding] = useState([]);
  const [workStepBatchesSieving, setWorkStepBatchesSieving] = useState([]);
  const [workStepBatchesPositioning, setWorkStepBatchesPositioning] = useState(
    []
  );
  const [workStepBatchesPackaging, setWorkStepBatchesPackaging] = useState([]);
  // define each of loading variables as one else they overwrite each other
  const [loadingInspectionWorksteps, setLoadingInspectionWorksteps] =
    useState(false);
  const [loadingFeedingWorksteps, setLoadingFeedingWorksteps] = useState(false);
  const [loadingSievingWorksteps, setLoadingSievingWorksteps] = useState(false);
  const [loadingPositioningWorksteps, setLoadingPositioningWorksteps] =
    useState(false);
  const [loadingPackagingWorksteps, setLoadingPackagingWorksteps] =
    useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRowsWorkItems, setExpandedRowsWorkItems] = useState(null);
  const [loadingActiveBatches, setLoadingActiveBatches] = useState(true);
  const [activeBatches, setActiveBatches] = useState([]);
  const [workStepsDaysAhead, setWorkStepsDaysAhead] = useState(0);

  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const dateTemplate = (rowData) =>
    rowData?.dueDate ? formatDate(new Date(rowData.dueDate)) : null;

  const workItemsTabs = [
    {
      tabBody: 'All Tasks',
      tabField: 'All Tasks',
      tabData: [
        ...workStepBatchesInspection,
        ...workStepBatchesFeeding,
        ...workStepBatchesSieving,
        ...workStepBatchesPackaging,
        ...workStepBatchesPositioning,
      ],
      tabLoading:
        loadingInspectionWorksteps ||
        loadingFeedingWorksteps ||
        loadingSievingWorksteps ||
        loadingPositioningWorksteps ||
        loadingPackagingWorksteps,
    },
    {
      tabBody: 'Inspection',
      tabField: 'Inspection',
      tabData: workStepBatchesInspection,
      tabCb: setWorkStepBatchesInspection,
      tabLoading: loadingInspectionWorksteps,
      tabLoadingCb: setLoadingInspectionWorksteps,
    },
    {
      tabBody: 'Feeding',
      tabField: 'Dry Feeding',
      tabData: workStepBatchesFeeding,
      tabCb: setWorkStepBatchesFeeding,
      tabLoading: loadingFeedingWorksteps,
      tabLoadingCb: setLoadingFeedingWorksteps,
    },
    {
      tabBody: 'Sieving',
      tabField: 'Sieving',
      tabData: workStepBatchesSieving,
      tabCb: setWorkStepBatchesSieving,
      tabLoading: loadingSievingWorksteps,
      tabLoadingCb: setLoadingSievingWorksteps,
    },
    {
      tabBody: 'Positioning',
      tabField: 'Positioning',
      tabData: workStepBatchesPositioning,
      tabCb: setWorkStepBatchesPositioning,
      tabLoading: loadingPositioningWorksteps,
      tabLoadingCb: setLoadingPositioningWorksteps,
    },
    {
      tabBody: 'Packaging',
      tabField: 'Packaging',
      tabData: workStepBatchesPackaging,
      tabCb: setWorkStepBatchesPackaging,
      tabLoading: loadingPackagingWorksteps,
      tabLoadingCb: setLoadingPackagingWorksteps,
    },
  ];

  const activeBatchesColumn = [
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Number of Boxes', field: 'numberOfBoxes' },
    { header: 'Day Since Stabling', field: 'daySinceStabling' },
  ];

  const workStepsColumns = [
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Work Plan ID', field: 'workPlanId' },
    { header: 'Due Date ', field: 'dueDate', body: dateTemplate },
  ];

  const daysAheadOptions = [
    { option: 'Today Work Tasks', value: 0 },
    { option: '5 Days Ahead', value: 5 },
    { option: '7 Days Ahead', value: 7 },
  ];

  const getWorkStepsType = useCallback(
    async (daysAhead, type, cb, loadingCb) => {
      try {
        loadingCb(true);
        return axiosConfig
          .get('/overview/workSteps', {
            params: { daysAhead: daysAhead || 0, types: type },
          })
          .then((workStepsTypes) => {
            cb(workStepsTypes.data);
            loadingCb(false);
          });
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: `Error while fetching work steps for ${type}`,
        });
        return null;
      }
    },
    [toast]
  );

  const getWorkSteps = useCallback(async () => {
    setWorkStepBatches([]);
    await Promise.all(
      workItemsTabs
        .filter(({ tabField }) => tabField !== 'All Tasks')
        .map(({ tabField, tabCb, tabLoadingCb }) =>
          getWorkStepsType(0, tabField, (data) => tabCb(data), tabLoadingCb)
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkStepsType]);

  const getActiveBatches = async () => {
    try {
      setLoadingActiveBatches(true);
      const batches = await axiosConfig
        .get('/overview/activeBatches')
        .then(({ data }) => data);
      setActiveBatches(batches);
      setLoadingActiveBatches(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTask([]);
    setTaskBatches([]);
    getWorkSteps();
    getActiveBatches();
  }, [getWorkSteps, setTask, setTaskBatches]);

  const navigateTo = (rowData) => {
    const attribute = rowData.batchId
      ? [
          ...(rowData?.attributes?.length ? rowData.attributes : []),
          {
            attribute: 'workStepId',
            name: 'Work Step ID',
            value: rowData.workStepId,
          },
        ]
      : rowData.charges[0].attributes;
    switch (rowData?.workTaskName) {
      case 'Inspection':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/inspection');
        break;
      case 'Dry Feeding':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/feeding');
        break;
      case 'Sieving':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/sieving');
        break;
      case 'Positioning':
        setSelectedStacks(rowData?.stacks);
        setShowDialog('positionDialog');
        break;
      case 'Packaging':
        setSelectedBatch(rowData?.batchId);
        setShowDialog('packagingDialog');
        break;
      default:
        navigate('/overview');
    }
  };

  const navigateButtonTemplate = (rowData) => {
    return (
      <Button
        className="p-button-secondary p-button-outlined flex"
        label={`${rowData?.workTaskName} →`}
        onClick={() => navigateTo(rowData)}
      />
    );
  };

  const stackTableTemplate = (rowData) => {
    const lines = [];
    lines.push(`Number of Boxes: ${rowData?.boxesFromBatch}`);
    if (rowData?.attributes) {
      rowData.attributes.map((attribute) =>
        lines.push(`${attribute.name}: ${attribute.value}`)
      );
    }
    lines.push(
      `Stacks: ${(rowData?.stacks?.length ? rowData.stacks : []).join(', ')}`
    );
    return lines.map((line) => <div>{line}</div>);
  };

  const workItemOverview = (tab, tabBody, tabData, tabLoading) => {
    if (!workStepBatches) return null;
    return (
      <div>
        <DataTable
          value={tabData}
          expandedRows={expandedRowsWorkItems}
          onRowToggle={(e) => setExpandedRowsWorkItems(e.data)}
          rowExpansionTemplate={stackTableTemplate}
          responsiveLayout="scroll"
          sortOrder={-1}
          emptyMessage={`No work items for work item: ${tabBody}`}
          paginator
          rows={5}
          loading={tabLoading}
        >
          <Column expander />
          {workStepsColumns.map(({ header, field, body }) => (
            <Column header={header} field={field} body={body} sortable filter />
          ))}
          {tab === 'All Tasks' ? (
            <Column
              header="Work Task Name"
              field="workTaskName"
              body={navigateButtonTemplate}
            />
          ) : (
            <Column
              header=""
              field="workTaskName"
              body={navigateButtonTemplate}
            />
          )}
        </DataTable>
      </div>
    );
  };

  return (
    <div className="main-card main-card-content">
      {showDialog === 'positionDialog' && (
        <PositionDialog
          visible={showDialog === 'positionDialog'}
          onHide={() => {
            setShowDialog('');
            setSelectedStacks([]);
          }}
          stacks={selectedStacks}
          onComplete={() => {
            setSelectedStacks([]);
          }}
        />
      )}
      {showDialog === 'packagingDialog' && selectedBatch && (
        <PackagingEdit
          data={{
            batchId: selectedBatch,
            workStepIds: [
              workStepBatches.find(({ batchId }) => batchId === selectedBatch)
                ?.workStepId,
            ],
          }}
          getBoxesFromBatch
          readOnly={false}
          display={showDialog === 'packagingDialog'}
          onClose={() => {
            setSelectedBatch([]);
            setShowDialog('');
          }}
          onCompletion={() => {
            setSelectedBatch([]);
            setShowDialog('');
          }}
        />
      )}
      <div className="mt-2 mb-5">
        <h2>Overview</h2>
      </div>

      <div className="col-12 card-content p-fluid">
        <div className="grid mt-2 mb-5">
          <div className="col-9">
            <h5>Upcoming Work Items</h5>
          </div>
          <div className="col-3">
            <Dropdown
              value={workStepsDaysAhead}
              options={daysAheadOptions}
              optionLabel="option"
              onChange={(e) => {
                setWorkStepsDaysAhead(e.value);
                getWorkSteps(e.value);
              }}
            />
          </div>
        </div>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          {workItemsTabs.map(({ tabBody, tabField, tabData, tabLoading }) => {
            return (
              <TabPanel
                header={`${tabBody} (${
                  (tabData?.length && tabData.length) || 0
                })`}
                headerTemplate={null}
              >
                {workItemOverview(tabField, tabBody, tabData, tabLoading)}
              </TabPanel>
            );
          })}
        </TabView>
      </div>

      <div className="col-12 card-content p-fluid">
        <div className="mt-2 mb-5">
          <h5>Active Batches</h5>
        </div>
        <DataTable
          value={activeBatches}
          loading={loadingActiveBatches}
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          rowExpansionTemplate={(rowData) => {
            if (rowData?.stacks.length > 0) {
              const lines = rowData.stacks.split('; ');
              return lines.map((line) => <div>{line}</div>);
            }
            return null;
          }}
          dataKey="batchId"
          paginator
          rows={10}
        >
          <Column expander style={{ width: '3em' }} />
          {activeBatchesColumn.map((column) => {
            const { header, field } = column;
            return (
              <Column key={header} field={field} header={header} sortable />
            );
          })}
        </DataTable>
      </div>

      {/* <div className="col-12 card-content p-fluid">
        <div className="mt-2 mb-5">
          <h5>Farm</h5>
        </div>
        <div className="grid">
          <div className="col-8 card-content">
            {Object.keys(farmSetup).length ? (
              <SelectedStackPositionProvider>
                <AreaGrid farmSetup={farmSetup} />
              </SelectedStackPositionProvider>
            ) : (
              <div>
                <h6>No Farm Setup found</h6>
              </div>
            )}
          </div>
          <div className="col-1" />
          <div className="col-3 card-content">
            <div className="grid justify-content-center">
              <div className="col-12">
                <h5>Legend</h5>
              </div>
              <div className="col-2">
                <span
                  className="stack-badge stack-badge-active"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </span>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Fully occupied position</p>
              </div>
              <div className="col-2">
                <p
                  className="stack-badge stack-badge-occupied"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </p>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Partly occupied position</p>
              </div>
              <div className="col-2">
                <p
                  className="stack-badge stack-badge-empty"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </p>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Empty position</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Overview;
