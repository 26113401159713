const checkInputMissingExceptCommentAndWorkStepId = (item) => {
  const { comment, workStepId, ...reducedItem } = item;
  return Object.values(reducedItem).some(
    (field) => field === '' || field === null
  );
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

async function fetchResultsForMultipleKeys(
  keysToLoad,
  previousResults,
  previousLoadingKeys,
  cbSetLoadingKeys,
  cbFetchKey,
  cbSetResults,
  idString
) {
  cbSetLoadingKeys(keysToLoad);
  return Promise.all(
    keysToLoad.map((key) =>
      cbFetchKey(key).then((resultsForKey) => ({
        [key]: resultsForKey,
      }))
    )
  )
    .then((resultAndKeyObjects) =>
      resultAndKeyObjects.reduce((agg, curr) => ({ ...agg, ...curr }), {})
    )
    .then((resultAndKeys) => {
      // make sure previous loaded results are not deleted,
      // but new results overwrite old ones.
      cbSetResults({ ...previousResults, ...resultAndKeys });
      // remove successfully loaded users from list
      // if an error/ timeout was received it will be kept in loading state
      cbSetLoadingKeys(
        filterIdInListNotContainedInOtherList(
          previousLoadingKeys.map((key) => ({
            [idString]: key,
          })),
          Object.keys(resultAndKeys).map((key) => ({
            [idString]: key,
          })),
          idString
        ).map((objects) => objects[idString])
      );
    });
}

function filterIdInListNotContainedInOtherList(list1, list2, idString) {
  return list1.filter(
    (row1) => !list2.filter((row2) => row1[idString] === row2[idString]).length
  );
}

function parseNewlyCollapsedRows(oldExpandedRows, newExpandedRows, idString) {
  return filterIdInListNotContainedInOtherList(
    oldExpandedRows,
    newExpandedRows,
    idString
  );
}

function parseNewlyExpandedRows(oldExpandedRows, newExpandedRows, idString) {
  return filterIdInListNotContainedInOtherList(
    newExpandedRows,
    oldExpandedRows,
    idString
  );
}

function getKeyToLoad(newlyExpandedRows, newlyCollapsedRows, rowsLoading, key) {
  return [
    ...newlyExpandedRows,
    ...filterIdInListNotContainedInOtherList(
      rowsLoading,
      newlyCollapsedRows,
      key
    ),
  ].map((object) => object[key]);
}

async function onRowToggle(
  previousExpandedRows,
  newExpandedRows,
  idString,
  previousLoadingKeys,
  previousResults,
  cbSetLoadingKeys,
  cbFetchKey,
  cbSetResults,
  cbSetRowExpansion
) {
  // key for which to load Results in parallel while making sure
  // new row expansions don't overwrite previous load request
  // and ignoring load request of collapsed rows, and already loaded
  // and expanded row
  const keysToLoad = getKeyToLoad(
    parseNewlyExpandedRows(previousExpandedRows, newExpandedRows, idString),
    parseNewlyCollapsedRows(previousExpandedRows, newExpandedRows, idString),
    previousLoadingKeys.map((key) => ({ [idString]: key })),
    idString
  );
  cbSetRowExpansion(newExpandedRows);
  // fetch users in parallel for the tenant Ids
  await fetchResultsForMultipleKeys(
    keysToLoad,
    previousResults,
    previousLoadingKeys,
    cbSetLoadingKeys,
    cbFetchKey,
    cbSetResults,
    idString
  );
}

export {
  checkInputMissingExceptCommentAndWorkStepId,
  getCookie,
  fetchResultsForMultipleKeys,
  onRowToggle,
  filterIdInListNotContainedInOtherList,
};
