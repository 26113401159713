/* eslint-disable react/prop-types */
import { useState, useContext, useEffect, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import QrReader from 'react-qr-reader';

import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { InputMask } from 'primereact/inputmask';
import { getBoxIdsFromCharges } from 'utils/boxUtils';

export async function checkBoxes({
  boxIdList,
  boxesShouldExist = true,
  boxesShouldBeActive = false,
}) {
  const boxes = await axiosConfig
    .get(`/boxLogistics/checkList`, {
      params: { boxIdList },
    })
    .then((res) => res.data);
  // Return a toast if the box does not exist
  if (boxes.length !== boxIdList.length && boxesShouldExist) {
    return [
      {
        severity: ToastSeverity.ERROR,
        detail: `Box or Boxes do not exist!`,
      },
    ];
  }

  const toasts = [];
  boxes.forEach((box, idx) => {
    switch (true) {
      case box && !boxesShouldExist:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} already exists!`,
        };
        break;

      case box.Status === 'active' && !boxesShouldBeActive:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} is already active!`,
        };
        break;

      case (box.Status === 'inactive' || box.Status === 'new') &&
        boxesShouldBeActive:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} is ${box.Status} and has no Stack!`,
        };
        break;

      default:
        return false;
    }
    return false;
  });
  if (toasts && toasts.length !== 0) {
    return toasts;
  }
  return false;
}

export async function checkForAllowedBatches(boxes, allowedBatches) {
  const toasts = [];

  boxes.forEach((box, idx) => {
    if (!allowedBatches.includes(box.batchId)) {
      toasts[idx] = {
        severity: ToastSeverity.ERROR,
        detail: `${box.boxId} is in Batch ${box.batchId} and not in ${allowedBatches}`,
      };
    }
  });

  if (toasts && toasts.length !== 0) {
    return toasts;
  }
  return false;
}

export async function checkForNotAllowedBatches(boxes, notAllowedBatches) {
  const toasts = [];

  boxes.forEach((box, idx) => {
    if (notAllowedBatches.includes(box.batchId)) {
      toasts[idx] = {
        severity: ToastSeverity.ERROR,
        detail: `${box.boxId} is in Batch ${box.batchId} which has already been scanned or blocked`,
      };
    }
  });

  if (toasts && toasts.length !== 0) {
    return toasts;
  }
  return false;
}

// check Incomplete Batches
// takes in list of boxes [{boxIds,....}] and callback function that return list of incomplete batchIds
export async function checkIncompleteBatch(boxes, cb, toast) {
  if (!boxes || !boxes.length) cb([]);
  else
    try {
      const inCompleteBatchIds = await axiosConfig
        .get('/boxLogistics/checkIncompleteBatch', {
          params: {
            boxIds: boxes.map(({ boxId }) => boxId),
          },
        })
        .then((res) => res.data);
      cb(inCompleteBatchIds);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
}

export async function handleExternalBoxScan(
  scanResult,
  mask,
  itemList,
  toast,
  cb,
  boxIdNotList = [],
  scanMode = 'Box',
  boxesShouldExist = true,
  boxesShouldBeActive = true,
  allowedBatches = [],
  notAllowedBatches = []
) {
  if (
    scanResult === null ||
    scanResult.replace(/_/g, '').length !== mask.length
  ) {
    // toast.pushToast({
    //   severity: ToastSeverity.ERROR,
    //   detail: 'No valid QR Code found!',
    // });
    return false;
  }

  const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

  if (resultItemPrefix !== 'BX' || !resultItemIdValue) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'QR Code not valid for a box!',
    });
    return false;
  }

  // Check if box is already in list
  const boxAlreadyInList = itemList.some((item) =>
    Object.values(item).includes(scanResult)
  );
  if (boxAlreadyInList) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Box is already in List!',
    });
    return false;
  }

  // Check if box is already in current stack list or one of the other stacks or batch
  const boxAlreadyInStackList = boxIdNotList.includes(scanResult);
  if (boxAlreadyInStackList) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Box is already in one of the Stack Lists!',
    });
    return false;
  }

  // Check if box exists and is active
  const toastMsgs = await checkBoxes({
    boxIdList: [{ boxId: scanResult }],
    boxesShouldExist,
    boxesShouldBeActive,
    boxIdNotList,
  });

  let boxIdList = [];
  // If scanMode = 'stack' or 'batch', get all boxes in current stack or batch
  // get charge id for the box
  if (
    !toastMsgs &&
    boxesShouldBeActive &&
    (scanMode === 'stack' || scanMode === 'batch')
  ) {
    boxIdList = await axiosConfig
      .get(`/boxLogistics/${scanMode}`, {
        params: {
          boxId: scanResult,
        },
      })
      .then((res) => res.data);
  } else if (!toastMsgs && boxesShouldBeActive) {
    boxIdList = await axiosConfig
      .get('/boxLogistics/chargesForBoxes', {
        params: {
          boxIds: [scanResult],
        },
      })
      .then((res) => res.data);
  } else if (!toastMsgs) {
    boxIdList = [{ boxId: scanResult }];
  } else {
    toastMsgs.forEach((msg) => toast.pushToast(msg));
    return false;
  }

  // Check batch Id if it is restricted
  if (allowedBatches.length) {
    const toastMsgsBatch = await checkForAllowedBatches(
      boxIdList,
      allowedBatches
    );

    if (toastMsgsBatch) {
      toastMsgsBatch.forEach((msg) => toast.pushToast(msg));
      return false;
    }
  }

  // Check batch Id if it is restricted
  if (notAllowedBatches.length) {
    const toastMsgsBatch = await checkForNotAllowedBatches(
      boxIdList,
      notAllowedBatches
    );

    if (toastMsgsBatch) {
      toastMsgsBatch.forEach((msg) => toast.pushToast(msg));
      return false;
    }
  }

  // Check if all boxes in boxIdList are not already in list, stack list or other stack list

  // Add boxes to list
  cb([
    ...itemList,
    ...boxIdList.filter(
      ({ boxId }) => !itemList.some((box) => box.boxId === boxId)
    ),
  ]);

  return true;
}

/* 
OUTPUT: 
  onResult: List of boxIds
  onClose: Closes the dialog

*/
function BoxQRScan({
  onResult,
  onClose,
  display,
  boxesShouldBeActive = false,
  itemListInit = [],
  boxesShouldExist = true,
  boxIdNotList = [], // List of boxIds that should not be in the list
  scanModeList = ['series', 'stack', 'batch'], // List of scan modes to use
  batchesShouldBeComplete = false,
  resetOnClose = false,
  allowedBatches = [], // empty means all batches are allowed
  notAllowedBatches = [],
}) {
  // DEV_Environment to be replaced with env
  const DEV_ENVIRONMENT = true;
  const MASK = 'aa-99999';

  // CONTEXTS
  const toast = useContext(ToastContext);

  // STATES
  const [boxList, setBoxList] = useState([]);
  const [triggerPressed, setTriggerPressed] = useState(false);
  const [itemList, setItemList] = useState(itemListInit);
  const [scanMode, setScanMode] = useState(scanModeList[0]);
  const [incompleteBatchIds, setIncompleteBatchIds] = useState([]);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement && !inputElement.props) {
      inputElement.focus();
      inputElement.select();
    }
  }, []);

  const handleFocus = (event) => event.target.select();

  const selectButtonOptions = scanModeList.map((mode) => ({
    name: mode[0].toUpperCase() + mode.substring(1),
    value: mode,
  }));

  useEffect(() => {
    DEV_ENVIRONMENT && getBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // get charge id for box ids
    const boxesWithoutChargeId = itemList
      .filter((item) => (!item.chargeId || !item.batchId) && item.boxId)
      .map((item) => item.boxId);

    if (boxesWithoutChargeId.length && boxesShouldBeActive) {
      try {
        axiosConfig
          .get('/boxLogistics/chargesForBoxes', {
            params: {
              boxIds: boxesWithoutChargeId,
            },
          })
          .then((res) => {
            setItemList([
              ...new Map(
                [...itemList, ...res.data].map((item) => [item.boxId, item])
              ).values(),
            ]);
          });
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: error.response.status + error.response.statusText,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const chargesWithoutBoxes = itemList
      .filter((item) => item.chargeId && !item.boxId)
      .map((item) => item.chargeId);

    if (chargesWithoutBoxes.length) {
      const fetchMissingBoxIds = async (chargeIds) => {
        const res = await getBoxIdsFromCharges(chargeIds, toast);

        setItemList([
          ...new Map(
            [...itemList, ...res].map((item) => [item.chargeId, item])
          ).values(),
        ]);
      };

      fetchMissingBoxIds(chargesWithoutBoxes);
    }

    if (chargesWithoutBoxes.length === 0 && scanMode !== 'batch') {
      batchesShouldBeComplete &&
        boxesShouldBeActive &&
        checkIncompleteBatch(
          itemList,
          (batchIds) => setIncompleteBatchIds(batchIds),
          toast
        );
    }
  }, [boxesShouldBeActive, batchesShouldBeComplete, itemList, toast, scanMode]);

  function close() {
    if (resetOnClose) {
      setItemList([]);
    }
    onClose();
  }

  const getBoxes = async () => {
    try {
      return axiosConfig
        .get('/boxLogistics/table')
        .then((res) =>
          setBoxList(
            res.data.filter(({ boxId }) => !boxIdNotList.includes(boxId))
          )
        );
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const boxOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.boxId}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const boxValueTemplate = (option) => {
    if (option) {
      return <span>{option.boxId}; </span>;
    }
    return '<dev> Select boxes...';
  };

  const onHandleQrCodeFromPhoto = async (scanResult) => {
    if (triggerPressed) {
      setTriggerPressed(false);
      handleExternalBoxScan(
        scanResult,
        MASK,
        itemList,
        toast,
        (list) => setItemList(list),
        boxIdNotList,
        scanMode,
        boxesShouldExist,
        boxesShouldBeActive,
        allowedBatches,
        notAllowedBatches
      );
    }
  };

  const deleteItem = async (itemData) => {
    // filter out the item to be deleted from itemlist and set it to the state
    const newItemList = itemList.filter((item) => {
      return item.boxId !== itemData.boxId;
    });
    setItemList(newItemList);
  };

  const deleteItemTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-sm p-button-outlined "
        onClick={() => deleteItem(itemData)}
      />
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-end mb-2">
          <Button
            onClick={() => close()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined "
          />
        </div>
        <div className="col-5">
          <div className="grid grid-no-gutters">
            <div className="col-8 col-offset-1 mb-2">
              <SelectButton
                value={scanMode}
                options={selectButtonOptions}
                onChange={(e) => {
                  setScanMode(e.value);
                  setItemList([]);
                }}
                optionLabel="name"
              />
            </div>
            <div className="col-10 ">
              <QrReader delay={300} onScan={onHandleQrCodeFromPhoto} />
              <InputMask
                id="boxId"
                name="boxIdInputMask"
                ref={callbackRef}
                onFocus={handleFocus}
                mask={MASK}
                placeholder="Scan Box Barcode or QR Code"
                autoClear
                onComplete={(e) =>
                  handleExternalBoxScan(
                    e.value,
                    MASK,
                    itemList,
                    toast,
                    (list) => setItemList(list),
                    boxIdNotList,
                    scanMode,
                    boxesShouldExist,
                    boxesShouldBeActive,
                    allowedBatches,
                    notAllowedBatches
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="col-5 col-offset-1">
          <DataTable
            value={itemList.filter(
              ({ boxId }) => !boxIdNotList.includes(boxId)
            )}
            size="small"
            showGridlines
            scrollable
            scrollHeight="351px"
          >
            <Column field="boxId" header="Box ID" sortable />
            <Column field="chargeId" header="Charge ID" sortable />
            <Column field="batchId" header="Batch ID" sortable />
            <Column
              body={deleteItemTemplate}
              className="justify-content-center"
            />
          </DataTable>
          <div className="col-12">
            {DEV_ENVIRONMENT && (
              <MultiSelect
                value={
                  Array.isArray(itemList) &&
                  itemList.map((box) =>
                    boxList.find(({ boxId }) => boxId === box.boxId)
                  )
                }
                onChange={(e) => setItemList(Array.isArray(e.value) && e.value)}
                options={boxList.filter((box) =>
                  boxesShouldBeActive
                    ? box.Status === 'active'
                    : box.Status !== 'active'
                )}
                itemTemplate={boxOptionTemplate}
                selectedItemTemplate={boxValueTemplate}
              />
            )}
          </div>
        </div>
        <div className=" col-12 flex justify-content-between mt-2">
          <Button
            onClick={() => setTriggerPressed(true)}
            icon="pi pi-camera"
            className="p-button-rounded p-button-outlined"
          />
          {batchesShouldBeComplete && incompleteBatchIds.length ? (
            <span style={{ color: 'orange' }}>
              The following incomplete batch
              {incompleteBatchIds.length === 1 ? null : 'es'} will be split on
              work item: {incompleteBatchIds.join(', ')}
            </span>
          ) : null}
          {allowedBatches.length &&
          itemList.some(({ batchId }) => !allowedBatches.includes(batchId)) ? (
            <span style={{ color: 'orange' }}>
              The batch
              {allowedBatches.length === 1 ? null : 'es'} are not included in
              the allowed batch list: {allowedBatches.join(', ')}
            </span>
          ) : null}
          {notAllowedBatches.length &&
          itemList.some(({ batchId }) =>
            notAllowedBatches.includes(batchId)
          ) ? (
            <span style={{ color: 'orange' }}>
              The batch
              {notAllowedBatches.length === 1 ? null : 'es'} are in the list of
              not allowed batches: {notAllowedBatches.join(', ')}
            </span>
          ) : null}
          <Button
            onClick={() => {
              onResult(
                itemList.filter(({ boxId }) => !boxIdNotList.includes(boxId))
              );
              close();
            }}
            icon="pi pi-check"
            className="p-button-rounded p-button-success  p-button-outlined "
            disabled={
              !itemList.length ||
              (allowedBatches.length &&
                itemList.some(
                  ({ batchId }) => !allowedBatches.includes(batchId)
                )) ||
              (notAllowedBatches.length &&
                itemList.some(({ batchId }) =>
                  notAllowedBatches.includes(batchId)
                ))
            }
          />
        </div>
      </div>
    </Dialog>
  );
}

export default BoxQRScan;
