/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { FaCrosshairs } from 'react-icons/fa';
import PositionDialog from 'elements/PositionDialog';
import BoxQRScan from 'elements/BoxQRScan';

async function fetchNumberOfPositioningTasks(toast) {
  try {
    return axiosConfig
      .get('/boxLogistics/numberOfCachedStacks')
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Error while fetching Number of Positioning List',
    });
  }
}

async function fetchPositioningTasks(toast) {
  try {
    return axiosConfig
      .get('/boxLogistics/cachedStacks')
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Error while fetching Positioning Tasks',
    });
  }
}

function Positioning() {
  const toast = useContext(ToastContext);
  const [showPositioningDialog, setShowPositioningDialog] = useState(false);
  const [showStackScanDialog, setShowStackScanDialog] = useState(false);
  const [stacksForPositioning, setStacksForPositioning] = useState([]);
  const [selectedStacks, setSelectedStacks] = useState([]);

  const positionButton = (rowData) => (
    <div className="p-fluid">
      <Button
        className="p-button-success p-button-rounded p-button-outlined"
        label="Place now"
        onClick={() => {
          setSelectedStacks([rowData.stackId]);
        }}
      />
    </div>
  );

  async function setSelectedStackByBox(boxId) {
    try {
      return axiosConfig
        .get('/boxLogistics/stackForBox', {
          params: {
            boxId,
          },
        })
        .then((res) => setSelectedStacks([res.data.stackId]));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching stack for box',
      });
    }
  }

  const columns = [
    { header: 'Stack ID', field: 'stackId' },
    { header: 'Last Action', field: 'action' },
    { header: 'Inserted', field: 'createdAt' },
    {
      body: (rowData) => positionButton(rowData),
      style: { width: '5rm' },
    },
  ];

  async function refreshPositioningTasks() {
    fetchPositioningTasks(toast).then((stacks) =>
      setStacksForPositioning(stacks)
    );
  }

  useEffect(() => {
    refreshPositioningTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStacks?.length > 0) {
      setShowPositioningDialog(true);
    }
  }, [selectedStacks]);

  return (
    <div className="main-card main-card-content">
      <PositionDialog
        visible={showPositioningDialog}
        onHide={() => {
          setShowPositioningDialog(false);
          setSelectedStacks([]);
        }}
        stacks={selectedStacks}
        onComplete={() => {
          setSelectedStacks([]);
          refreshPositioningTasks();
        }}
      />
      <BoxQRScan
        onClose={() => setShowStackScanDialog(false)}
        onResult={(scanResult) => setSelectedStackByBox(scanResult[0].boxId)}
        itemListInit={[]}
        display={showStackScanDialog}
        scanModeList={['stack']}
        boxIdNotList={[]}
        batchesShouldBeComplete={false}
        boxesShouldBeActive
        boxesShouldExist
        resetOnClose
      />
      <div className="formgrid grid">
        <div className="field col-8 mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaCrosshairs className="mr-3" /> Positioning
          </h2>
        </div>
        <div className="col-4" />
        <div className="col-8" />
        <div className="field col-4">
          <div className="p-fluid">
            <Button
              onClick={() => {
                setShowStackScanDialog(true);
              }}
              label="Scan Stack to position"
              className="p-button-success p-button-rounded p-button-outlined"
            />
          </div>
        </div>
        <div className="field col-12 mt-2 mb-5">
          <DataTable
            emptyMessage="No cached boxes found"
            value={stacksForPositioning}
            responsiveLayout="scroll"
            dataKey="stackId"
          >
            {columns.map(({ header, field, body, style }, key) => (
              <Column
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                header={header}
                field={field}
                body={body}
                style={style}
              />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export { Positioning, fetchPositioningTasks, fetchNumberOfPositioningTasks };
