import { formatDate } from 'pages/Supplier/Supplier';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { FaCog } from 'react-icons/fa';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { fetchResultsForMultipleKeys, onRowToggle } from 'utils/utils';
import UserEdit from './UserEdit';

function UserSetup() {
  const [expandedRows, setExpandedRows] = useState([]);
  const [refreshTenantList, setRefreshTenantList] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [users, setUsers] = useState({});
  const [loadingTenants, setLoadingTenants] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState([]);
  const [action, setAction] = useState();
  const toast = useContext(ToastContext);

  useEffect(() => {
    fetchTenantList().then(() =>
      fetchResultsForMultipleKeys(
        expandedRows.map(({ tenantId }) => tenantId),
        users,
        loadingUsers,
        setLoadingUsers,
        fetchUsers,
        setUsers,
        'tenantId'
      )
    );
    setRefreshTenantList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTenantList]);

  const columnsTenant = [
    { header: 'Tenant ID', field: 'tenantId' },
    { header: 'Number of Users', field: 'numberOfUsers' },
  ];
  const columnsUsers = [
    { header: 'User ID', field: 'userId' },
    {
      header: 'First Name',
      field: 'firstName',
    },
    {
      header: 'Last Name',
      field: 'lastName',
    },
    {
      header: 'E-Mail',
      field: 'email',
    },
    {
      header: 'Created At',
      field: 'createdAt',
      body: (rowData) => formatDate(new Date(rowData.createdAt)),
    },
    { header: 'Role', field: 'role' },
  ];

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Edit', value: 'edit' },
        // { label: 'Delete', value: 'delete' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const rowExpansionTemplate = (rowData) => (
    <DataTable
      value={users[rowData.tenantId]}
      loading={loadingUsers.includes(rowData.tenantId)}
    >
      {columnsUsers.map(({ header, field, body }) => (
        <Column key={field} header={header} field={field} body={body} />
      ))}
      <Column header="Action" body={rowAction} />
    </DataTable>
  );

  const fetchTenantList = async () => {
    try {
      setLoadingTenants(true);
      await axiosConfig
        .get('/user/tenants')
        .then((res) => setTenants(res.data))
        .then(() => setLoadingTenants(false));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching users',
      });
    }
  };

  const fetchUsers = async (tenantId) => {
    try {
      return axiosConfig
        .get('/user/usersForTenant', { params: { tenantIdForUsers: tenantId } })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: `Error while fetching users for ${tenantId}`,
      });
      return [];
    }
  };

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'delete':
        return null;
      case 'edit':
        return (
          <UserEdit
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshTenantList(true)}
          />
        );
      case 'create':
        return (
          <UserEdit
            data={null}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshTenantList(true)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-card main-card-content">
      {action && actionDialog()}
      <div className="formGrid grid">
        <div className="field col-12">
          <h2 className="flex align-items-center">
            <FaCog className="mr-3" /> Tenant and User Setup
          </h2>
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage="No tenants found"
            value={tenants.length ? tenants : []}
            expandedRows={expandedRows}
            onRowToggle={(e) =>
              onRowToggle(
                expandedRows,
                e.data,
                'tenantId',
                loadingUsers,
                users,
                setLoadingUsers,
                fetchUsers,
                setUsers,
                setExpandedRows
              )
            }
            rowExpansionTemplate={rowExpansionTemplate}
            loading={loadingTenants}
          >
            <Column expander />
            {columnsTenant.map(({ header, field, body }) => (
              <Column key={field} header={header} field={field} body={body} />
            ))}
          </DataTable>
        </div>
        <div className="p-fluid field col-12">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create new user"
            onClick={() => {
              setAction({ actionValue: 'create', actionData: null });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserSetup;
