import { useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import version from '../version.json';

async function getReleaseVersion() {
  let releaseVersion = {};
  try {
    releaseVersion = {
      ...releaseVersion,
      frontEnd: { name: version.version },
    };
  } catch (error) {
    console.error(error.message);
    releaseVersion = {
      ...releaseVersion,
      frontEnd: { name: '-' },
    };
  }
  try {
    const { backEnd } = await axiosConfig
      .get('/version/releaseVersion')
      .then((res) => res.data);
    releaseVersion = { ...releaseVersion, backEnd };
  } catch (error) {
    console.error(error.message);
    releaseVersion = {
      ...releaseVersion,
      backEnd: { name: '-' },
    };
  }
  return releaseVersion;
}

function Footer() {
  const [releaseVersion, setReleaseVersion] = useState([]);

  useEffect(() => {
    (async () => {
      await getReleaseVersion().then((versionData) =>
        setReleaseVersion(versionData)
      );
    })();
  }, []);

  return (
    <div className="layout-footer">
      Backend release: {releaseVersion?.backEnd?.name}; Frontend release:{' '}
      {releaseVersion?.frontEnd?.name}
    </div>
  );
}

export default Footer;
