/* eslint-disable react/prop-types */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { useCallback, useContext, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dropdown } from 'primereact/dropdown';
import AuthContext from 'store/auth-context';
import { checkProductionPosition } from './ProductionPositionRetriever';
// import { useState } from 'react';

export async function retrievePositionForBoxIds({
  boxIds,
  productionPositionShouldExist = true,
}) {
  try {
    // get all position id accossiatied with for boxids format: [{boxId, positionId}]
    const positions = await axiosConfig
      .get(`/productionposition/positionsForBoxIds`, {
        params: {
          boxIds,
        },
      })
      .then((res) => res.data);

    const toasts = [];
    const positionsAndBoxes = [];

    // eslint-disable-next-line array-callback-return
    positions.map((position) => {
      switch (true) {
        case (!position || !position.positionId) &&
          productionPositionShouldExist:
          toasts.push({
            severity: ToastSeverity.ERROR,
            detail: 'Production Position does not exist!',
          });
          break;
        case position.positionId && !productionPositionShouldExist:
          toasts.push({
            severity: ToastSeverity.ERROR,
            detail: 'Production Position already exists!',
          });
          break;
        case position.positionId && productionPositionShouldExist:
          positionsAndBoxes.push(position);
          break;
        default:
          toasts.push({
            severity: ToastSeverity.ERROR,
            detail:
              'Unexpected Error while retrieving production position for boxId',
          });
      }
    });
    return { toasts, positionsAndBoxes };
  } catch (error) {
    console.error(error);
    return {
      toasts: [
        {
          severity: ToastSeverity.ERROR,
          detail: `Error retrieving production position: ${error.message}`,
        },
      ],
      positionsAndBoxes: [],
    };
  }
}

// eslint-disable-next-line react/prop-types, prettier/prettier
export default function PositionDialog({
  visible,
  onHide,
  boxes,
  stacks,
  onComplete,
  stackAndBoxes,
  createBoxes,
}) {
  const { user } = useContext(AuthContext);
  const toast = useContext(ToastContext);
  const [items, setItems] = useState([]);
  const [expandedStacks, setExpandedStacks] = useState();
  const [scanMode] = useState('position');
  const [triggerPressed, setTriggerPressed] = useState(false);
  const [selectedStackForPositioning, setSelectedStackForPositioning] =
    useState([]);
  const [selectedBoxesForPositioning, setSelectedBoxesForPositioning] =
    useState([]);
  const [selectedSpot, setSelectedSpot] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [positionList, setPositionList] = useState([]);
  const [QRCodeElement, setQRCodeElement] = useState(null);

  const MASK = 'aa-999999';

  const callbackRef = useCallback((inputElement) => {
    if (inputElement && !inputElement.props) {
      setQRCodeElement(inputElement);
      inputElement.focus();
      inputElement.select();
    }
  }, []);

  const INTERVALLENGTH = 100;
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        QRCodeElement &&
        document.activeElement.tagName.toUpperCase() !== 'INPUT'
      )
        QRCodeElement.focus();
    }, INTERVALLENGTH);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [QRCodeElement]);

  const handleFocus = (event) => event.target.select();

  const handleStackSelection = (e) => {
    // find difference between previously selected stack
    // and select / deselect relevant boxes
    const selectionRemoved = [].concat(
      ...[]
        .concat(
          ...selectedStackForPositioning.filter(
            (o) => e.value.indexOf(o) === -1
          )
        )
        .map((selectedStack) => selectedStack?.boxesInStack)
    );
    const selectionAdded = [].concat(
      ...[]
        .concat(
          ...e.value.filter(
            (o) => selectedStackForPositioning.indexOf(o) === -1
          )
        )
        .map((selectedStack) => selectedStack?.boxesInStack)
    );
    setSelectedStackForPositioning(e.value);
    setSelectedBoxesForPositioning(
      [
        ...[...selectedBoxesForPositioning].filter(
          (box) =>
            !selectionRemoved.includes(box) && !selectionAdded.includes(box)
        ),
      ].concat(...selectionAdded)
    );
  };

  const sendPosition = async (itemList) => {
    try {
      const boxesAndPositions = [];
      itemList.map(({ boxesInStack }) =>
        boxesAndPositions.push(
          ...boxesInStack.map((boxAndPosition) => ({
            boxId: boxAndPosition.boxId,
            positionId: boxAndPosition.positionId
              ? boxAndPosition.positionId
              : null,
          }))
        )
      );
      const positioned = await axiosConfig
        .post('/boxLogistics/position', {
          boxesAndPositions,
          email: user.email,
        })
        .then(() =>
          toast.pushToast({
            severity: ToastSeverity.SUCCESS,
            detail: 'Position success',
          })
        )
        .then(() => onComplete && onComplete());
      return positioned;
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Positioning error',
      });
      return error;
    }
  };

  const positionEditor = (options) => (
    <InputMask
      mask="PS-99999?9"
      value={options.value}
      type="text"
      placeholder="PS-"
      onChange={(e) => options.editorCallback(e.value)}
      keyfilter="pint"
      onFocus={handleFocus}
    />
  );

  const onBoxPositionEditComplete = (e) => {
    const { rowData, newValue, field } = e;
    rowData[field] = newValue;
  };

  const onStackPositionEditComplete = (e) => {
    const { rowData, newValue, field } = e;
    rowData.boxesInStack.map(
      // eslint-disable-next-line no-return-assign
      (box, boxProp) =>
        (rowData.boxesInStack[boxProp] = { ...box, positionId: newValue })
    );
    rowData[field] = newValue;
  };

  const boxTable = (rowData) => (
    <div className="grid">
      <div className="col-2" />
      <div className="col-10">
        <DataTable
          value={rowData.boxesInStack}
          editMode="cell"
          size="small"
          selectionMode="multiple"
          dataKey="boxId"
          metaKeySelection={false}
          selection={selectedBoxesForPositioning}
          onSelectionChange={(e) => handleBoxSelection(e)}
        >
          <Column header="Box ID" field="boxId" />
          <Column
            header="Position ID"
            field="positionId"
            editor={(options) => positionEditor(options)}
            onCellEditComplete={onBoxPositionEditComplete}
          />
        </DataTable>
      </div>
    </div>
  );

  const handleScanBox = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }
    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (resultItemPrefix !== 'BX' || !resultItemIdValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }
      await retrievePositionForBoxIds({
        boxIds: scanResult,
        productionPositionShouldExist: true,
      }).then(({ toasts, positionsAndBoxes }) => {
        if (!Array.isArray(toasts) || toasts.length !== 0) {
          toasts.map((toastTemp) => toast.pushToast(toastTemp));
        } else if (
          !Array.isArray(positionsAndBoxes) ||
          !positionsAndBoxes.length
        ) {
          toast.pushToast({
            severity: ToastSeverity.ERROR,
            detail: 'No position retrieved!',
          });
        } else {
          handlePositionChange(positionsAndBoxes[0].positionId);
          setSelectedBoxesForPositioning([]);
          setSelectedStackForPositioning([]);
        }
      });

      return true;
    }

    return false;
  };

  const handleScanPosition = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }

    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (resultItemPrefix !== 'PS' || !resultItemIdValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }

      // Check if production position exist and are active
      const toastMsg = await checkProductionPosition({
        positionId: scanResult,
        productionPositionShouldExist: true,
        productionPositionShouldBeActive: true,
      });
      // push toast message if there are any
      if (toastMsg) {
        toast.pushToast(toastMsg);
        return false;
      }

      handlePositionChange(scanResult);
      setSelectedBoxesForPositioning([]);
      setSelectedStackForPositioning([]);

      return true;
    }

    return false;
  };

  // handle external scans
  const handleExternalScanBox = async (scanResult, mask) => {
    // Check if box is already in list
    if (scanResult === null) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No valid QR Code found!',
      });
    }

    if (scanResult !== null) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (
        (resultItemPrefix !== 'BX' && resultItemPrefix !== 'PS') ||
        !resultItemIdValue
      ) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }
      if (resultItemPrefix === 'BX') {
        await retrievePositionForBoxIds({
          boxIds: [scanResult],
          productionPositionShouldExist: true,
        }).then(({ toasts, positionsAndBoxes }) => {
          if (!Array.isArray(toasts) || toasts.length !== 0) {
            toasts.map((toastTemp) => toast.pushToast(toastTemp));
          } else if (
            !Array.isArray(positionsAndBoxes) ||
            !positionsAndBoxes.length
          ) {
            toast.pushToast({
              severity: ToastSeverity.ERROR,
              detail: 'No position retrieved!',
            });
          } else {
            handlePositionChange(positionsAndBoxes[0].positionId);
            setSelectedBoxesForPositioning([]);
            setSelectedStackForPositioning([]);
          }
        });
      } else if (resultItemPrefix === 'PS') {
        const toastMsg = await checkProductionPosition({
          positionId: scanResult,
          productionPositionShouldExist: true,
          productionPositionShouldBeActive: true,
        });
        // push toast message if there are any
        if (toastMsg) {
          toast.pushToast(toastMsg);
          return false;
        }
        handlePositionChange(scanResult);
        setSelectedBoxesForPositioning([]);
        setSelectedStackForPositioning([]);
      }

      return true;
    }
    return false;
  };

  const handleBoxSelection = (e) => {
    // select/deselect stacks if all/(not all) of the boxes are highlight
    setSelectedBoxesForPositioning(e.value);
    let selectionStackTemp = [...selectedStackForPositioning];
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      if (
        item.boxesInStack
          .map(({ boxId }) => boxId)
          .filter((box) => e.value.map(({ boxId }) => boxId).includes(box))
          .length === item.boxesInStack.length
      ) {
        selectionStackTemp = [
          ...selectionStackTemp.filter(
            (stack) => stack.stackId !== item.stackId
          ),
          item,
        ];
      } else {
        selectionStackTemp = selectionStackTemp.filter(
          (stack) => stack.stackId !== item.stackId
        );
      }
    });
    setSelectedStackForPositioning(selectionStackTemp);
  };

  const handlePositionChange = (positionId) => {
    // change position of all selected boxes and stacks
    const itemsTemp = items;
    // eslint-disable-next-line array-callback-return
    itemsTemp.map((item, stackIdx) => {
      if (
        selectedStackForPositioning.some(
          ({ stackId }) => stackId === item.stackId
        )
      ) {
        itemsTemp[stackIdx] = {
          ...item,
          positionId,
          boxesInStack: item.boxesInStack?.map((box) => ({
            ...box,
            positionId,
          })),
        };
      }
      // eslint-disable-next-line array-callback-return
      item.boxesInStack?.map((box, boxIdx) => {
        if (
          selectedBoxesForPositioning.some(({ boxId }) => boxId === box.boxId)
        ) {
          itemsTemp[stackIdx].boxesInStack[boxIdx] = {
            ...box,
            positionId,
          };
        }
      });
    });
  };

  useEffect(() => {
    // get boxes of stacks
    // get stacks of boxes
    // assign  boxes to stacks remove duplicates
    // get boxes organized in stacks: stackId, boxId --> [{stackId, boxes: [boxId]}]
    // eslint-disable-next-line consistent-return
    const getOrganizedStacks = async ({ boxIds, stackIds }) => {
      try {
        if (!Array.isArray(stackAndBoxes)) {
          await axiosConfig
            .get('/boxLogistics/organizedStacks', {
              params: { boxIds, stackIds },
            })
            .then((res) => res.data)
            .then((organizedStacks) => {
              setItems(organizedStacks);
              handleStackSelection({ value: [organizedStacks[0]] });
            });
        } else {
          const organizedStacks = [];
          // eslint-disable-next-line react/prop-types, array-callback-return
          stackAndBoxes.map(({ stackId, boxId }) => {
            const index = organizedStacks.findIndex(
              (stack) => stack.stackId === stackId
            );
            if (index === -1) {
              organizedStacks.push({ stackId, boxesInStack: [{ boxId }] });
            } else {
              organizedStacks[index] = {
                ...organizedStacks[index],
                boxesInStack: [
                  ...organizedStacks[index].boxesInStack,
                  { boxId },
                ],
              };
            }
          });
          setItems(organizedStacks);
          handleStackSelection({ value: [organizedStacks[0]] });
        }
      } catch (error) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching stacks and boxes',
        });
        console.error(error.message);
      }
    };

    const getPositionList = async () => {
      try {
        return axiosConfig
          .get('/productionPosition/positionList')
          .then((res) => res.data)
          .then((positions) => setPositionList(positions));
      } catch (error) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching positions',
        });
        console.error(error.message);
        return null;
      }
    };

    if ((boxes && boxes.length > 0) || (stacks && stacks.length > 0)) {
      getPositionList();
      getOrganizedStacks({ boxIds: boxes, stackIds: stacks });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxes, stackAndBoxes, stacks]);

  return (
    <Dialog
      header="Position Boxes and Stacks"
      visible={visible}
      onHide={onHide}
      style={{ width: '80vw' }}
    >
      <div className="p-fluid grid">
        <div className="col-4">
          <div className="grid grid-no-gutters">
            <div className="col-8">
              {!selectedBoxesForPositioning?.length && (
                <span style={{ color: 'orange' }}>
                  Please select a box or stack to scan a position
                </span>
              )}
            </div>
            <div className="col-10">
              {selectedBoxesForPositioning?.length ? (
                <div className="grid">
                  <div className="col-6">
                    <Dropdown
                      options={positionList}
                      optionLabel="areaId"
                      id="areaId"
                      value={selectedArea}
                      onChange={(e) => setSelectedArea(e.value)}
                      placeholder="AR-"
                    />
                  </div>
                  <div className="col-6">
                    <Dropdown
                      disabled={!selectedArea}
                      options={selectedArea?.spots}
                      optionLabel="spotId"
                      id="areaId"
                      value={selectedSpot}
                      onChange={(e) => {
                        setSelectedSpot(e.value);
                        handlePositionChange(e.value.positionId);
                      }}
                      placeholder="PS-"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-10 ">
              <QrReader
                delay={300}
                onScan={scanMode === 'box' ? handleScanBox : handleScanPosition}
              />
              <InputMask
                id="positionId"
                name="positionIdInputMask"
                ref={callbackRef}
                onFocus={handleFocus}
                mask={MASK}
                placeholder={
                  scanMode === 'box'
                    ? 'Scan Box Barcode or QR Code'
                    : 'Scan Position Barcode or QR Code'
                }
                onComplete={(e) => handleExternalScanBox(e.value, MASK)}
              />
            </div>
            <div className="col-8 col-offset-4 mb-2">
              <Button
                onClick={() => setTriggerPressed(true)}
                icon="pi pi-camera"
                className="p-button-rounded "
              />
            </div>
          </div>
        </div>
        <div className="col-8">
          <DataTable
            value={items}
            expandedRows={expandedStacks}
            onRowToggle={(e) => setExpandedStacks(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={boxTable}
            dataKey="stackId"
            editMode="cell"
            selectionMode="multiple"
            metaKeySelection={false}
            selection={selectedStackForPositioning}
            onSelectionChange={(e) => handleStackSelection(e)}
          >
            <Column expander style={{ width: '3em' }} />
            <Column
              header={createBoxes ? 'Temporary Stack ID' : 'Stack ID'}
              field="stackId"
            />
            <Column
              header="Position ID"
              field="positionId"
              editor={(options) => positionEditor(options)}
              onCellEditComplete={onStackPositionEditComplete}
            />
          </DataTable>
        </div>
        <div className="col-6">
          <Button
            className="p-button-rounded p-button-secondary p-button-outlined"
            onClick={() => onHide()}
            label="Cancel Positioning"
          />
        </div>
        <div className="col-6">
          <Button
            className="p-button-rounded p-button-success"
            onClick={() => sendPosition(items).then(() => onHide())}
            label="Position"
          />
        </div>
      </div>
    </Dialog>
  );
}
