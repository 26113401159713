import axiosConfig from 'utils/axiosConfig';
import { useState, useEffect } from 'react';
import { BsStack } from 'react-icons/bs';
import BoxQRScan from 'elements/BoxQRScan';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputMask } from 'primereact/inputmask';

function AnalyzeStack() {
  const [showStackScanDialog, setShowStackScanDialog] = useState(false);
  const [stackData, setStackData] = useState([]);
  const [stackId, setStackId] = useState('');

  const tableColumns = [
    { header: 'Box', field: 'boxId' },
    { header: 'Charge', field: 'chargeId' },
  ];

  useEffect(() => {
    if (stackData.length > 0 && stackId.length < 8) {
      const firstboxId = stackData[0].boxId;

      axiosConfig
        .get('/boxLogistics/stackForBox', {
          params: { boxId: firstboxId },
        })
        .then((res) => setStackId(res.data.stackId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackData]);

  const fetchDataForStackId = (stackIdStr) => {
    axiosConfig
      .get('/boxLogistics/boxesForStack', {
        params: { stackId: stackIdStr },
      })
      .then((res) => setStackData(res.data));
    setStackId(stackIdStr);
  };

  return (
    <div className="main-card main-card-content">
      <BoxQRScan
        onClose={() => setShowStackScanDialog(false)}
        onResult={(scanResult) => {
          setStackData(scanResult);
          setStackId(''); // Clear as stackId is not known here
        }}
        itemListInit={[]}
        display={showStackScanDialog}
        scanModeList={['stack']}
        boxIdNotList={[]}
        batchesShouldBeComplete={false}
        boxesShouldBeActive
        boxesShouldExist
        resetOnClose
      />
      <div className="formGrid grid">
        <div className="field col-12">
          <h2 className="flex align-items-center">
            <BsStack className="mr-3" /> Stack Analysis
          </h2>
        </div>
        <div className="field col-4">
          <div className="p-fluid">
            <Button
              onClick={() => {
                setShowStackScanDialog(true);
              }}
              label="Scan Box to select Stack"
              className="p-button-success p-button-rounded p-button-outlined"
            />
          </div>
        </div>
        <div className="field col-2 flex flex-wrap align-items-center justify-content-center vertical-align-middle">
          or enter Stack-Id:
        </div>
        <div className="field col-4 ">
          <InputMask
            id="stackIdInput"
            name="stackIdInput"
            mask="aa-99999"
            placeholder="Enter Stack Id"
            autoClear
            onComplete={(e) => {
              fetchDataForStackId(e.value);
            }}
          />
        </div>
        <div className="col-8" />
        <div className="field col-12">
          <p>Stack: {stackId}</p>
        </div>
        <div className="field col-12">
          <DataTable
            value={stackData}
            responsiveLayout="scroll"
            dataKey="boxId"
            emptyMessage="Select an existing Stack"
            sortField="boxId"
            sortOrder={1}
          >
            {tableColumns.map((column) => {
              const { header, field } = column;
              return (
                <Column
                  key={header}
                  field={field}
                  header={header}
                  sortable
                  style={{ width: 'auto' }}
                />
              );
            })}
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default AnalyzeStack;
