import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
function InspectionPlanEdit({ planId, visible, onClose, onComplete }) {
  const toast = useContext(ToastContext);

  const [inspectionPlan, setInspectionPlan] = useState({
    id: planId,
    name: '',
    description: '',
    tasks: [],
  });

  const blankTask = {
    key: null,
    name: '',
    type: null,
    unit: '',
    note: '',
  };

  const fetchInspectionPlan = async () => {
    if (inspectionPlan?.id) {
      try {
        const params = { id: inspectionPlan.id };
        const inspectionPlanRaw = await axiosConfig
          .get('/inspection/inspectionPlanDetails', { params })
          .then((res) => res.data);
        if (inspectionPlanRaw != null) {
          let tasks = [];
          if (Array.isArray(inspectionPlanRaw.InspectionTasks)) {
            tasks = inspectionPlanRaw.InspectionTasks.map((task) => ({
              key: task.InspectionTaskID,
              name: task.InspectionTaskName || '',
              note: task.InspectionTaskNote || '',
              unit: task.Unit || '',
              type: task.Type || 'Bool',
            }));
          }
          setInspectionPlan({
            id: inspectionPlanRaw.InspectionID,
            name: inspectionPlanRaw.InspectionName || '',
            description: inspectionPlanRaw.InspectionDescription || '',
            tasks,
          });
        }
      } catch (error) {
        console.log(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching Data',
        });
      }
    }
  };

  useEffect(() => {
    fetchInspectionPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createInspectionPlan = async () => {
    try {
      await axiosConfig.post('/inspection/create', inspectionPlan).then(() => {
        toast.pushToast({
          severity: ToastSeverity.SUCCESS,
          detail: 'Saved inspection plan',
        });
      });
      onComplete();
      onClose();
    } catch (err) {
      console.error(err);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: err.response.status + err.response.statusText,
      });
    }
  };

  const deleteTaskTemplate = (rowData) => (
    <Button
      className="p-button-outlined"
      icon="pi pi-trash"
      onClick={() =>
        setInspectionPlan({
          ...inspectionPlan,
          tasks: inspectionPlan.tasks.filter((row) => row !== rowData),
        })
      }
    />
  );

  const typeEditorNew = (cell) => {
    return (
      <Dropdown
        value={inspectionPlan.tasks[cell.rowIndex][cell.field]}
        placeholder="Select a type"
        options={['Bool', 'Float', 'Integer']}
        onChange={(e) => {
          const updatedTasks = inspectionPlan.tasks;
          updatedTasks[cell.rowIndex][cell.field] = e.value;
          setInspectionPlan({
            ...inspectionPlan,
            tasks: updatedTasks,
          });
        }}
      />
    );
  };

  const onEditorValueChange = (e) => {
    e.rowData[e.field] = e.newValue;
  };

  const textEditor = (options) => (
    <InputText
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />
  );

  const onRowReorder = (event) => {
    setInspectionPlan({ ...inspectionPlan, tasks: event.value });
  };

  const columns = [
    { key: '1', rowReorder: true, style: { width: '3em' }, field: 'reorder' },
    { key: '2', header: 'Prompt', field: 'name', editor: textEditor },
    { key: '3', header: 'Type', field: 'type', editor: typeEditorNew },
    { key: '4', header: 'Unit', field: 'unit', editor: textEditor },
    { key: '5', header: 'Notes', field: 'note', editor: textEditor },
    { key: '6', body: deleteTaskTemplate, field: 'delete' },
  ];

  return (
    <div>
      <Dialog
        visible={visible}
        modal
        onHide={onClose}
        closable={false}
        style={{ width: '80vw' }}
      >
        <div className="formgrid grid p-fluid align-items-end">
          <div className="field col-12">
            <h2>Create Inspection Plan</h2>
          </div>
          <div className="field col-6">
            <label htmlFor="name">Name:</label>
            <InputText
              id="name"
              value={inspectionPlan?.name}
              onChange={(e) =>
                setInspectionPlan({ ...inspectionPlan, name: e.target.value })
              }
            />
          </div>
          <div className="field col-6">
            <label htmlFor="description">Description:</label>
            <InputText
              id="description"
              value={inspectionPlan?.description}
              onChange={(e) =>
                setInspectionPlan({
                  ...inspectionPlan,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="field col-12">
            <DataTable
              value={inspectionPlan?.tasks}
              editMode="cell"
              reorderableRows
              onRowReorder={onRowReorder}
            >
              {columns.map(
                (
                  { key, header, field, body, editor, rowReorder, style },
                  index
                ) => (
                  <Column
                    key={key}
                    header={header}
                    field={field}
                    body={body}
                    editor={editor}
                    onCellEditComplete={onEditorValueChange}
                    rowReorder={rowReorder}
                    style={style}
                  />
                )
              )}
            </DataTable>
          </div>
          <div className="field col-12" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-success p-button-outlined"
              label="Add task"
              onClick={() => {
                setInspectionPlan({
                  ...inspectionPlan,
                  tasks: [
                    ...(inspectionPlan?.tasks?.length
                      ? inspectionPlan.tasks
                      : []),
                    blankTask,
                  ],
                });
              }}
              style={{
                flex: 1,
              }}
            />
          </div>
          <div className="field col-6" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-secondary p-button-outlined"
              label="Cancel"
              onClick={() => onClose(true)}
              style={{
                flex: 1,
              }}
            />
          </div>
          <div className="field col-6" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-success"
              label="Save Inspection Plan"
              onClick={() => createInspectionPlan()}
              style={{
                flex: 1,
              }}
              disabled={
                !inspectionPlan.name ||
                !inspectionPlan.tasks?.length ||
                (Array.isArray(inspectionPlan.tasks) &&
                  inspectionPlan.tasks.some((task) => !task.name || !task.type))
                // ToDo: fix this check, as it does not update when entering text
              }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default InspectionPlanEdit;
